import { chunk, flatten, findLastIndex } from 'lodash';

import { HistoricalPoint } from 'app/types/interface';

export const identity = function <T>(x?: T): T | undefined {
  return x;
};

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const delayedPromise = function (delay: number): Promise<void> {
  return new Promise(resolve => setTimeout(() => resolve(), delay));
};

/**
 * Given a list of items and an async function, split the list of items into chunks
 * that then run asynchronously in groups. Good for when an API has a rate limit of
 * something like "5 requests per second".
 */
export const chunkDelay = function <Item>(
  items: Array<Item>,
  chunkCount = 10,
  timingOffset = 1000,
  jitterThreshold = 500
) {
  return function <Return>(asyncFunction: (item: Item) => Promise<Return>): Promise<Array<Return>> {
    return Promise.all(
      flatten(
        chunk(items, chunkCount).map((chunkOfItems, i) =>
          chunkOfItems.map(async item => {
            const jitter = process.env.NODE_ENV === 'test' ? 0 : Math.random() * jitterThreshold;
            await delayedPromise(i * timingOffset + jitter);
            return asyncFunction(item);
          })
        )
      )
    );
  };
};

export const reallignTime = (times: Array<number>, data: Array<HistoricalPoint>): Array<HistoricalPoint> => {
  const dataByTime = Object.fromEntries(data.map(point => [point.time, point]));
  const lastIndex = data.length - 1;

  return times.map(time => {
    if (time < data[0].time) {
      return {
        time,
        price: {
          value: data[0].price.value,
        },
        volume: {
          total: data[0].volume.total,
        },
      };
    } else if (time > data[lastIndex].time) {
      return {
        time,
        price: {
          value: data[lastIndex].price.value,
        },
        volume: {
          total: data[lastIndex].volume.total,
        },
      };
    } else if (time in dataByTime) {
      return dataByTime[time];
    } else {
      const lowerBoundIndex = findLastIndex(data, point => time > point.time);
      const p0 = data[lowerBoundIndex];
      const p1 = data[lowerBoundIndex + 1];
      const changeInTime = p1.time - p0.time;
      const priceSlope = (p1.price.value - p0.price.value) / changeInTime;
      const volumeSlope = (p1.volume.total - p0.volume.total) / changeInTime;
      const dt = time - p0.time;

      return {
        time,
        price: {
          value: p0.price.value + priceSlope * dt,
        },
        volume: {
          total: p0.volume.total + volumeSlope * dt,
        },
      };
    }
  });
};
