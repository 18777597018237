import * as React from 'react';
import styled from 'styled-components';

import { Field, Errors, SingleParentState, Input } from 'app/types/typeform';
import { LabeledInput } from 'app/components/typeform/inputs/labeled_input';
import { InputField } from 'app/components/typeform/inputs/input_field';
import { validateRequiredNonEmpty, compose } from 'app/components/typeform/validators';
import { SimpleFormEvent } from 'types/globals';

const Wrapper = styled.div``;

export type Props = {
  errors: Errors;
  updateErrors: (errors: Errors) => void;
  parentState: SingleParentState;
  updateInputState: (state: SingleParentState) => void;
  input: Input;
  fields: Array<Field>;
  setLoading?: (isLoading: boolean) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
};

const onChange = (fieldId: string, props: Props) => (event: SimpleFormEvent) => {
  // react-autosuggest sends a click event with undefined value before the event with the value
  const updateState = { [fieldId]: event.currentTarget.value || '' };
  const newState = { ...props.parentState, ...updateState };

  if (props.setLoading) props.setLoading(true);
  const errorsPromise = compose(props.input.validator, validateRequiredNonEmpty(props.fields))(newState);
  errorsPromise
    .then(props.updateErrors)
    .catch(() => {})
    .then(() => {
      if (props.setLoading) props.setLoading(false);
    });

  props.updateInputState(newState);
};

const SingleInput = (props: Props) => {
  const inputs = props.fields.map((field, index) => {
    const requiredStar = field.required ? '*' : '';

    return (
      <LabeledInput key={`${props.input.id}-${field.id}`}>
        {props.fields.length > 1 && (
          <label htmlFor={field.id}>
            {field.title || field.id}
            {requiredStar}
          </label>
        )}
        <InputField
          id={field.id}
          errors={props.errors}
          parentState={props.parentState}
          onChange={onChange(field.id, props)}
          input={props.input}
          placeholder={field.placeholder}
          onKeyUp={props.onKeyUp}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          shouldFocus={index === 0}
          autocomplete={field.autocomplete}
          allowedValues={field.allowedValues}
        />
      </LabeledInput>
    );
  });

  return <Wrapper>{inputs}</Wrapper>;
};

export { SingleInput };
