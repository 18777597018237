import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { configureStore, history } from 'app/store/modules';
import { SettingsContainer } from 'app/pages/settings';
import { DashboardContainer } from 'app/pages/dashboard';
import { OrdersContainer } from 'app/pages/orders';
import NotFoundComponent from 'app/pages/notFound';
import { OnboardingContainer } from 'app/pages/onboarding';
import DefaultLayout from 'app/layouts/default';
import { HomeContainer } from 'app/pages/home';
import { LoginContainer } from 'app/pages/login';

import 'react-select/dist/react-select.css';
import 'react-vis/dist/style.css';
import 'app/assets/css/reset.css';
import 'app/assets/css/index.css';

const Main = () => (
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/" component={HomeContainer} />
        <DefaultLayout path="/dashboard" component={DashboardContainer} />
        <DefaultLayout path="/settings" component={SettingsContainer} />
        <DefaultLayout path="/orders" component={OrdersContainer} />
        <DefaultLayout path="/onboarding" component={OnboardingContainer} />
        <DefaultLayout path="/login" component={LoginContainer} />
        <DefaultLayout path="/register" component={LoginContainer} />
        <DefaultLayout path="/forgot_password" component={LoginContainer} />
        <DefaultLayout path="*" component={NotFoundComponent} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

render(Main(), document.getElementById('app'));

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}
