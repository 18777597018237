import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { flatMap } from 'lodash';
import queryString from 'query-string';
import { push } from 'connected-react-router';

import colors from 'app/styles/colors';
import t from 'app/utils/i18n';
import { JsonError } from 'app/types/auth';
import * as auth from 'app/store/modules/auth/actions';
import { GradientBorderButton } from 'app/components/gradient_border_button';
import { Input } from 'app/pages/login/input';
import { WhiteFormPage } from 'app/layouts/white_form_page';
import { State as GlobalState } from 'app/store/types';

const Spacer = styled.span`
  margin-right: 15px;
  color: ${colors.ui.grey6};
  font-size: 14px;
`;

const SideSpacer = styled.div`
  width: 300px;
`;

const Terms = styled.div`
  font-size: 12px;
  color: ${colors.ui.grey7};
  line-height: 1.75;
`;

type FormType = 'register' | 'login' | 'forgotPassword' | 'forgotPasswordRequest' | 'changePassword';

export type Props = {
  queryParams: { [key: string]: string | number | undefined };
  loading: boolean;
  onRegister: (email: string, password: string, confirmation: string) => void;
  onLogin: (email: string, password: string) => void;
  onForgotPasswordRequest: (email: string) => void;
  onForgotPassword: (userId: number, token: string, goodUntil: number, password: string, confirmation: string) => void;
  errorMessage: string | null;
  errorPayload: Array<JsonError>;
  initialFormType: FormType;
};

type State = {
  email: string;
  password: string;
  passwordConfirmation: string;
  formType: FormType;
};

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      formType: props.initialFormType,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.passwordError = this.passwordError.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.setFormType = this.setFormType.bind(this);
  }

  handleChange(key: keyof State) {
    return ({ currentTarget }: React.FormEvent<HTMLInputElement>) =>
      this.setState({
        [key]: currentTarget.value,
      } as Pick<State, typeof key>);
  }

  setFormType(formType: FormType) {
    this.setState({ formType });
  }

  isDisabled() {
    const { formType, password, passwordConfirmation, email } = this.state;
    const matchError = (formType === 'register' || formType === 'forgotPassword') && password !== passwordConfirmation;

    let fieldsEmpty = false;
    switch (this.state.formType) {
      case 'login':
        fieldsEmpty = password === '' || email === '';
        break;
      case 'register':
        fieldsEmpty = password === '' || email === '' || passwordConfirmation === '';
        break;
      case 'forgotPasswordRequest':
        fieldsEmpty = email === '';
        break;
      case 'forgotPassword':
        fieldsEmpty = password === '' || passwordConfirmation === '';
        break;
      default:
        break;
    }

    return this.props.loading || matchError || fieldsEmpty || this.props.loading;
  }

  passwordError() {
    const { formType, password, passwordConfirmation } = this.state;
    const matchError = (formType === 'register' || formType === 'forgotPassword') && password !== passwordConfirmation;
    const serverError =
      flatMap(this.props.errorPayload, jsonError => Object.keys(jsonError)).indexOf('obj.password') > -1;

    if (serverError) return this.props.errorMessage;
    else if (matchError) return "passwords don't match";
    else return null;
  }

  emailError() {
    return flatMap(this.props.errorPayload, jsonError => Object.keys(jsonError)).indexOf('obj.email') > -1;
  }

  errorMessage() {
    return this.passwordError() || this.props.errorMessage;
  }

  submitForm(event: React.SyntheticEvent | undefined) {
    if (event) event.preventDefault();
    analytics.track('ux.submit.login', { formType: this.state.formType });

    switch (this.state.formType) {
      case 'register':
        this.props.onRegister(this.state.email, this.state.password, this.state.passwordConfirmation);
        break;
      case 'login':
        this.props.onLogin(this.state.email, this.state.password);
        break;
      case 'forgotPasswordRequest':
        this.props.onForgotPasswordRequest(this.state.email);
        break;
      case 'forgotPassword':
        this.props.onForgotPassword(
          parseInt((this.props.queryParams.userId || '0').toString(), 10),
          (this.props.queryParams.token || '').toString(),
          parseInt((this.props.queryParams.goodUntil || '0').toString(), 10),
          this.state.password,
          this.state.passwordConfirmation
        );
        break;
      default:
        break;
    }
  }

  i18nKey(key: string) {
    return t(`auth.${this.state.formType}.${key}`);
  }

  renderInputs() {
    const email = (
      <Input
        key="email"
        placeholder={t('auth.placeholders.email')}
        id="email"
        value={this.state.email}
        onChange={this.handleChange('email')}
        error={this.emailError()}
      />
    );
    const password = (
      <Input
        key="password"
        placeholder={t('auth.placeholders.password')}
        id="password"
        type="password"
        value={this.state.password}
        onChange={this.handleChange('password')}
        error={this.passwordError()}
      />
    );
    const passwordConfirmation = (
      <Input
        key="passwordConfirmation"
        placeholder={t('auth.placeholders.passwordConfirmation')}
        id="passwordConfirmation"
        type="password"
        value={this.state.passwordConfirmation}
        onChange={this.handleChange('passwordConfirmation')}
        error={this.passwordError()}
      />
    );
    const token = <Input key="token" id="token" value={this.props.queryParams.token} disabled />;

    const inputs: Array<JSX.Element> = [];
    switch (this.state.formType) {
      case 'login':
        inputs.push(email, password);
        break;
      case 'register':
        inputs.push(email, password, passwordConfirmation);
        break;
      case 'forgotPasswordRequest':
        inputs.push(email);
        break;
      case 'forgotPassword':
        inputs.push(token, password, passwordConfirmation);
        break;
      case 'changePassword':
        inputs.push(password, passwordConfirmation);
        break;
      default:
        break;
    }

    return inputs;
  }

  renderTopRight() {
    let onClick = () => this.setFormType('login');
    if (this.state.formType === 'login') onClick = () => this.setFormType('register');

    return (
      <SideSpacer>
        <Spacer>{this.i18nKey('switchQuery')}</Spacer>
        <GradientBorderButton large content={this.i18nKey('switchAction')} onClick={onClick} />
      </SideSpacer>
    );
  }

  renderBelowButton() {
    let node;
    switch (this.state.formType) {
      case 'register':
        node = <Terms>{t('auth.register.terms')}</Terms>;
        break;
      case 'login':
        node = <a onClick={() => this.setFormType('forgotPasswordRequest')}>{t('auth.login.forgotPassword')}</a>;
        break;
      case 'forgotPasswordRequest':
        node = <Terms>{t('auth.forgotPasswordRequest.description')}</Terms>;
        break;
      case 'forgotPassword':
        node = <Terms>{t('auth.forgotPassword.description')}</Terms>;
        break;
      default:
        break;
    }

    return node;
  }

  render() {
    analytics.page('login');

    return (
      <WhiteFormPage
        topRight={this.renderTopRight()}
        errorMessage={this.errorMessage()}
        isDisabled={this.isDisabled()}
        onSubmit={this.submitForm}
        title={this.i18nKey('title')}
        subTitle={this.i18nKey('subtitle')}
        confirmText={this.i18nKey('action')}
        belowButton={this.renderBelowButton()}
      >
        {this.renderInputs()}
      </WhiteFormPage>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const queryParams = queryString.parse(state.router.location.search);
  const location = state.router.location.pathname;
  const locationQuery = state.router.location.search || '';
  let initialFormType: FormType = 'login';
  if (location.includes('register') || locationQuery.includes('register')) initialFormType = 'register';
  else if (location.includes('forgot_password')) {
    initialFormType =
      queryParams.token && queryParams.userId && queryParams.goodUntil ? 'forgotPassword' : 'forgotPasswordRequest';
  }
  // FIX: replaced the calculation of whether any of the auth states were loading
  const loading = Object.values(state.auth.loading).some(isLoading => isLoading);

  return {
    queryParams: queryParams as { [key: string]: string | undefined },
    initialFormType,
    loading,
    errorMessage: state.auth.errorMessage || state.auth.errorPayloadMessage,
    errorPayload: state.auth.errorPayload,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onRegister: (email: string, password: string, passwordConfirmation: string) => {
      dispatch(auth.register(email, password, passwordConfirmation));
    },
    onLogin: (email: string, password: string) => {
      dispatch(auth.login(email, password));
    },
    onForgotPasswordRequest: async (email: string) => {
      await dispatch(auth.forgotPasswordRequest(email));
      await dispatch(push('/'));
    },
    onForgotPassword: async (
      userId: number,
      token: string,
      goodUntil: number,
      password: string,
      passwordConfirmation: string
    ) => {
      await dispatch(auth.forgotPassword(userId, token, goodUntil, password, passwordConfirmation));
      await dispatch(push('/dashboard'));
    },
  };
};

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export { Login, LoginContainer, mapStateToProps };
