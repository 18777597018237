import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { CoinInfo, Order as OrderData } from 'app/types/api';
import { FromTo } from 'app/components/order/from_to';
import { CoinPrices, CoinHistory } from 'app/types/interface';

const Wrapper = styled.div`
  margin: 20px;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 20px;
`;

type Props = {
  allCoinsData: Array<CoinHistory>;
  coinPrices: CoinPrices;
  coinInfo: CoinInfo;
  order: OrderData;
  isLoading: boolean;
};

const Order = (props: Props) => {
  const momentTime = moment(props.order.timestamp);
  return (
    <Wrapper>
      <div>
        <em title={momentTime.format('MMMM Do YYYY, h:mm:ss a')}>{momentTime.fromNow()}</em>
      </div>
      <FromTo {...props} />
    </Wrapper>
  );
};

export { Order };
