import * as React from 'react';
import { flatten, head, find, findIndex } from 'lodash';

import { FormState, InputState, SingleParentState, Ordering, Field, Input } from 'app/types/typeform';
import { InputSection } from 'app/components/typeform/input_section';
import { FullScreenFixedPanel } from 'app/components/typeform/full_screen_fixed_panel';
import { StaticLoadingSpinner } from 'app/components/loading_spinner';

type Props = {
  inputs: Array<Input>;
  order: Array<Ordering>;
  onComplete: (state: FormState) => void;
};

type State = {
  flattenedIds: Array<string>;
  current: {
    id: string;
    index: number;
    input: Input;
  };
  formState: FormState;
  submitting: boolean;
};

const isSingleKindInput = (input: Input) => input.kind === 'text' || input.kind === 'step' || Array.isArray(input.kind);

const buildFormState = (inputs: Array<Input>, ids: Array<string>): FormState => {
  const buildState = (fields: Array<Field>) =>
    fields.reduce((obj, { id }) => {
      obj[id] = '';
      return obj;
    }, {} as SingleParentState);

  const formState: FormState = {
    single: {},
    lists: {},
  };

  inputs.forEach(input => {
    if (ids.indexOf(input.id) > -1) {
      if (input.kind === 'text') formState.single[input.id] = { [input.id]: '' };
      else if (Array.isArray(input.kind)) formState.single[input.id] = buildState(input.kind);
      else if (input.kind === 'step') formState.single[input.id] = { [input.id]: '' };
      else formState.lists[input.id] = [];
    }
  });

  return formState;
};

const getInput = (inputs: Array<Input>, id: string | undefined): Input => {
  const found = find(inputs, ['id', id]);
  if (found != null) return found;
  else throw new Error('invalid');
};

class TypeForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const wrappedOrder: Array<Array<string>> = props.order.map(idOrArray =>
      Array.isArray(idOrArray) ? idOrArray : [idOrArray]
    );
    const flattenedIds: Array<string> = flatten(wrappedOrder);
    const id = head(flattenedIds) || '';
    const index = 0;
    const input = getInput(this.props.inputs, id);
    const current = { id, index, input };
    const formState = buildFormState(props.inputs, flattenedIds);

    this.state = { current, flattenedIds, formState, submitting: false };

    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
    this.skip = this.skip.bind(this);
    this.updateInputStateForId = this.updateInputStateForId.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const input = getInput(nextProps.inputs, this.state.current.id);
    this.setState({ current: { ...this.state.current, input } });
  }

  next() {
    if (this.state.current.index !== this.state.flattenedIds.length - 1) {
      const index = this.state.current.index + 1;
      const id = this.state.flattenedIds[index];
      const current = { index, id, input: getInput(this.props.inputs, id) };

      analytics.track('ux.typeform.next.click', { id });

      this.setState({ current });
    } else {
      analytics.track('ux.typeform.complete.click');

      this.setState({ submitting: true }, () => this.props.onComplete(this.state.formState));
    }
  }

  back() {
    if (this.state.current.index !== 0) {
      const index = this.state.current.index - 1;
      const id = this.state.flattenedIds[index];
      const current = { index, id, input: getInput(this.props.inputs, id) };

      analytics.track('ux.typeform.back.click', { id });

      this.setState({ current });
    }
  }

  findCurrentStep(): { index: number; id: string | Array<string> } {
    const index = findIndex(
      this.props.order,
      idOrGroup =>
        idOrGroup === this.state.current.id ||
        (Array.isArray(idOrGroup) && idOrGroup.indexOf(this.state.current.id) >= 0)
    );
    const id = this.props.order[index];

    return { index, id };
  }

  skip() {
    const input = this.state.current.input;
    if (!input.required) {
      const { index: foundIndex, id: found } = this.findCurrentStep();

      analytics.track('ux.typeform.skip.click', { id: found });

      if (found && typeof found === 'string') this.next();
      else if (found && Array.isArray(found)) {
        const nextInOrder = foundIndex + 1;

        if (nextInOrder < this.props.order.length) {
          const idOrArray: string | Array<string> = this.props.order[nextInOrder];
          let id = Array.isArray(idOrArray) ? head(idOrArray) : idOrArray;
          // i think getInput protects against this, but i'm not sure right now the best way to tell typescript that head(x) is safe
          id = id || '';
          const index = this.state.flattenedIds.indexOf(id);
          const current = { index, id, input: getInput(this.props.inputs, id) };

          this.setState({ current });
        }
      }
    }
  }

  updateInputStateForId(id: string) {
    return (newState: InputState) => {
      const formState = this.state.formState;

      if (Array.isArray(newState)) {
        this.setState({
          formState: {
            single: formState.single,
            lists: {
              ...formState.lists,
              [id]: newState,
            },
          },
        });
      } else {
        this.setState({
          formState: {
            lists: formState.lists,
            single: {
              ...formState.single,
              [id]: newState,
            },
          },
        });
      }
    };
  }

  currentInputState(): InputState {
    return isSingleKindInput(this.state.current.input)
      ? this.state.formState.single[this.state.current.id]
      : this.state.formState.lists[this.state.current.id];
  }

  renderCurrentInput() {
    const current = this.state.current;
    const currentStep = this.findCurrentStep().id;

    const isFirstStepOfGroup = Array.isArray(currentStep) && current.id === head(currentStep);
    const atTheEnd = current.index === this.state.flattenedIds.length - 1;

    const hideSkipButton = atTheEnd || current.input.required || (!isFirstStepOfGroup && current.input.kind === 'step');

    return (
      <InputSection
        key={current.id}
        input={current.input}
        onNext={this.next}
        onBack={this.back}
        onSkip={this.skip}
        updateInputState={this.updateInputStateForId(current.id)}
        parentState={this.currentInputState()}
        showBackButton={current.index !== 0}
        showSkipButton={!hideSkipButton}
      />
    );
  }

  render() {
    return (
      <FullScreenFixedPanel>
        {this.state.submitting ? <StaticLoadingSpinner large /> : this.renderCurrentInput()}
      </FullScreenFixedPanel>
    );
  }
}

export { TypeForm };
