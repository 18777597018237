import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isEqual, map } from 'lodash';
import * as ReactVis from 'react-vis';

import colors from 'app/styles/colors';
import * as styles from 'app/components/charts/gradient_charts/styles';
import { PriceSymbol } from 'app/types/interface';
import { TimeRange } from 'app/types/interface';
import { NBSP } from 'app/constants';
import { kindToFormatter } from 'app/utils/formatter';

const gradientDefs = (
  <ReactVis.GradientDefs>
    <linearGradient id={styles.strokeGradientId} x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={colors.gradient.secondary} stopOpacity={1.0} />
      <stop offset="100%" stopColor={colors.gradient.primary} stopOpacity={1.0} />
    </linearGradient>
    <filter id={styles.shadowId} x="-10%" y="-10%" width="200%" height="200%">
      <feDropShadow dx="0" dy="4" stdDeviation="8" floodColor="#aaa" />
    </filter>
  </ReactVis.GradientDefs>
);

const CrosshairBox = styled.div`
  text-align: center;
  color: ${colors.ui.grey6};
`;

export type Point = {
  x: number;
  y: number;
};

export type Props = {
  name: string;
  timerange: TimeRange;
  series: Array<Point>;
  barChartSeries?: Array<Point>;
  crosshairIncludesValue?: boolean;
  toSymbol: PriceSymbol;
  onHover?: (x?: number) => void;
};

type State = {
  crosshair: Array<Point>;
  lineData: Array<Point>;
  barData: Array<Point>;
};

const renderXAxisDate = (range: TimeRange) => (timestamp: number) => {
  switch (range) {
    case 'hour':
      return moment(timestamp).format('h:mm'); // 3:43
    case 'day':
      return moment(timestamp).format('hA'); // 3PM
    case 'week':
      return moment(timestamp).format('MMM D'); // Mar 3
    case 'month':
      return moment(timestamp).format('MMM D'); // Mar 3
    default:
      return moment(timestamp).format("MMM 'YY"); // Mar '18
  }
};

class GradientLineChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const lineAndBarData = this.rescaleData(props);
    this.state = { ...lineAndBarData };

    this.onNearestX = this.onNearestX.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState(this.rescaleData(nextProps));
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextProps.name !== this.props.name) return true;
    if (!isEqual(nextProps.series, this.props.series)) return true;
    if (!isEqual(nextProps.barChartSeries, this.props.barChartSeries)) return true;
    if (!isEqual(this.state, nextState)) return true;
    return false;
  }

  rescaleData(props: Props): State {
    if (!this.state || !this.state.lineData || !isEqual(props.series, this.props.series)) {
      if (!props.barChartSeries) {
        return { barData: [], lineData: props.series, crosshair: this.state?.crosshair || [] };
      } else {
        const min = Math.min.apply(
          null,
          props.series.map(p => p.y)
        );
        const max = Math.max.apply(
          null,
          props.series.map(p => p.y)
        );
        const originalBarData = props.barChartSeries || [{ x: 0, y: 0 }];
        const barMin = Math.min.apply(
          null,
          originalBarData.map(p => p.y)
        );
        const barMax = Math.max.apply(
          null,
          originalBarData.map(p => p.y)
        );
        const lineData = map(props.series, p => {
          return { x: p.x, y: p.y - min };
        });
        const barData =
          barMax !== barMin
            ? map(originalBarData, p => {
                const denominator = barMax - barMin;
                const dimensionless = denominator ? (p.y - barMin) / denominator : 0;
                const scaled = (dimensionless * (max - min)) / 2.0;
                return { x: p.x, y: scaled };
              })
            : originalBarData;
        return { barData, lineData, crosshair: this.state?.crosshair || [] };
      }
    } else {
      return this.state;
    }
  }

  onNearestX(point: Point, extraInfo: { index: number }) {
    this.setState({ crosshair: [{ x: point.x, y: point.y }] }, () => {
      if (this.props.onHover) this.props.onHover(extraInfo.index);
    });
  }

  onMouseLeave() {
    this.setState({ crosshair: [] }, () => {
      if (this.props.onHover) this.props.onHover();
    });
  }

  renderCrosshairContent() {
    if (this.state.crosshair[0]) {
      const timeString =
        this.props.timerange === 'year'
          ? moment(this.state.crosshair[0].x).format('MMM Do YY, h:mm a')
          : moment(this.state.crosshair[0].x).format('MMM Do, h:mm a');

      const formattedTimestring = timeString.replace(/\s/g, NBSP);

      return this.props.crosshairIncludesValue ? (
        <React.Fragment>
          {formattedTimestring}
          <br />
          Value: {kindToFormatter(this.props.toSymbol)(this.state.crosshair[0].y)}
        </React.Fragment>
      ) : (
        formattedTimestring
      );
    } else return null;
  }

  render() {
    let barChartSeries;
    if (this.props.barChartSeries && this.props.barChartSeries.length) {
      barChartSeries = <ReactVis.VerticalBarSeries animation data={this.state.barData} style={styles.barStyle} />;
    }
    return this.props.series.length ? (
      <ReactVis.FlexibleWidthXYPlot height={200} onMouseLeave={this.onMouseLeave} className="gradient-chart">
        {gradientDefs}
        <ReactVis.VerticalGridLines style={styles.verticalLinesStyle} />
        <ReactVis.XAxis
          tickTotal={styles.xAxisTickCount}
          tickFormat={renderXAxisDate(this.props.timerange)}
          style={styles.xAxisStyle}
        />
        {barChartSeries}
        <ReactVis.LineSeries
          className="line"
          curve="linear"
          data={this.state.lineData}
          style={styles.lineStyle}
          onNearestX={this.onNearestX}
        />
        <ReactVis.Crosshair values={this.state.crosshair} style={styles.crosshairStyle}>
          <CrosshairBox>{this.renderCrosshairContent()}</CrosshairBox>
        </ReactVis.Crosshair>
      </ReactVis.FlexibleWidthXYPlot>
    ) : (
      <div>loading</div>
    );
  }
}

export { GradientLineChart };
