import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import dimensions from 'app/styles/dimensions';
import NavigationLink, { Props as NavigationLinkProps } from 'app/components/navigationLink';
import { media } from 'app/utils/responsive';

const Link = styled(NavigationLink)<NavigationLinkProps>`
  text-decoration: none;
`;

const SubContents = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  right: 0px;
  background-color: ${colors.black};
  z-index: 10;
  text-align: center;
  box-sizing: border-box;
  width: ${dimensions.sideBar.width}px;

  &:hover {
    display: block;
  }

  ${media.nonDesktop} {
    width: 100%;
  }
`;

const Contents = styled.div`
  position: relative;
  color: ${colors.ui.grey5};
  padding-right: 15px;
  text-align: right;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:hover ${SubContents} {
    display: block;
  }

  ${media.nonDesktop} {
    position: static;
  }
`;

const Line = styled.li`
  padding: 20px 30px;
  cursor: pointer;
  background-color: black;
  font-size: 14px;
  font-weight: bold;
  border-top: 1px solid transparent;

  & + & {
    border-top-color: ${colors.ui.grey9};
  }

  &:hover {
    color: white;
  }
`;

export type Opt = {
  key: string;
  name?: string | undefined;
  onClick?: (event: React.MouseEvent) => void;
  to?: string | undefined;
};

type Props = {
  options: Array<Opt>;
  firstLine: React.ReactNode;
};

const DropDown = ({ options, firstLine }: Props) => {
  const renderedOptions = options.map(({ key, name, onClick, to }) => {
    const text = name || key;
    return (
      <Line key={key} onClick={onClick}>
        {to ? <Link to={to}>{text}</Link> : text}
      </Line>
    );
  });

  return (
    <Contents>
      <span>{firstLine}</span>
      <SubContents>{renderedOptions}</SubContents>
    </Contents>
  );
};

export { Line, DropDown, SubContents, Contents };
