import t from 'app/utils/i18n';
import { Input, Kind, Validator, StringInputFields } from 'app/types/typeform';

export const buildInputI18n = (
  id: string,
  required: boolean,
  keys: Array<keyof StringInputFields>,
  kind: Kind,
  validator: Validator | null = null
): Input => {
  const output: Input = { id, title: t(`onboarding.${id}.title`), required, kind };
  if (validator) output.validator = validator;

  keys.concat(['description']).forEach((key: keyof StringInputFields) => {
    output[key] = t(`onboarding.${id}.${key}`);
  });

  return { ...output };
};
