import * as React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedComponent } from 'react-redux';

import { AppContainer } from 'app/pages/app';

type Props<T extends React.ComponentType<P>, P> = {
  component: ConnectedComponent<T, P> | T;
  path: string;
};

const Default = function <T extends React.ComponentType<P>, P>({ component: Component, ...rest }: Props<T, P>) {
  const render = (matchProps: unknown) => (
    <AppContainer>
      {/* @ts-expect-error */}
      <Component {...matchProps} />
    </AppContainer>
  );

  return <Route {...rest} render={render} />;
};

export default Default;
