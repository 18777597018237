import { Reducer } from 'redux';

import { CoinData, WalletMapping, Order } from 'app/types/api';
import { LoadingKey, ApiActions } from 'app/store/modules/api/actions';
import { reduceLoading } from 'app/store/modules/utils';

export type ApiState = {
  coinInfo: Array<CoinData>;
  walletOverTime: Array<WalletMapping>;
  orders: Array<Order>;
  loading: Partial<Record<LoadingKey, boolean>>;
};

const initialState: ApiState = {
  coinInfo: [],
  walletOverTime: [],
  orders: [],
  loading: {},
};

export const reducer: Reducer<ApiState, ApiActions> = (
  state: ApiState = initialState,
  action: ApiActions
): ApiState => {
  switch (action.type) {
    case 'api/FETCH_COIN_INFO':
      return {
        ...state,
        coinInfo: action.payload,
      };
    case 'api/UPDATE_COIN_INFO':
      const coinInfo = state.coinInfo.slice();
      action.payload.forEach(({ symbol, image }) => {
        if (image?.thumb) {
          const uppercaseSymbol = symbol.toUpperCase();
          const index = coinInfo.findIndex(info => info.symbol === uppercaseSymbol);
          if (index >= 0 && !coinInfo[index].imageUrl)
            coinInfo[index] = {
              ...coinInfo[index],
              imageUrl: image.thumb,
            };
        }
      });
      return {
        ...state,
        coinInfo,
      };
    case 'api/FETCH_WALLET_OVER_TIME':
      return {
        ...state,
        walletOverTime: action.payload,
      };
    case 'api/FETCH_ORDERS':
      return {
        ...state,
        orders: action.payload,
      };
    case 'api/LOADING':
      return reduceLoading<LoadingKey, ApiState>(state, action.payload);
    default:
      return state;
  }
};
