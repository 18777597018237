import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as api from 'app/store/modules/api/actions';
import * as user from 'app/store/modules/user/actions';
import { TypeForm } from 'app/components/typeform';
import { Field, Errors, Input, Ordering, FormState, Validator, SingleParentState } from 'app/types/typeform';
import { SettingsData } from 'app/pages/settings';
import { validateFloatKey } from 'app/components/typeform/validators';
import { buildInputI18n } from 'app/components/typeform/builders';
import { CoinInfo } from 'app/types/api';
import { buildAutocompleteCoinProps } from 'app/utils/autocomplete_utils';
import services from 'app/services';
import { State } from 'app/store/types';

type ApiKey = {
  site: string;
  key: string;
  secret: string;
};

const { apiService } = services;

const inputs: Array<Input> = [
  buildInputI18n('welcome', false, ['confirmText'], 'step'),
  buildInputI18n('complete', false, ['confirmText'], 'step'),
  buildInputI18n('totalCashInvested', true, ['placeholder'], 'text', validateFloatKey('totalCashInvested')),
  buildInputI18n('askCredentials', false, ['confirmText', 'skipText'], 'step'),
];

const order: Array<Ordering> = [
  'welcome',
  'totalCashInvested',
  ['askCredentials', 'credentials'],
  'walletHoldings',
  'complete',
];

type Props = {
  validateApiKeys: (object: ApiKey) => Promise<Errors>;
  onComplete: (state: FormState) => void;
  onDisplay: () => void;
  coinInfo: CoinInfo;
  loadSettings: () => void;
  allowedSites: Array<string>;
};

class Onboarding extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.onDisplay();
    this.props.loadSettings();
  }

  buildCredentials() {
    let timeoutId: number;
    const validator: Validator = (state: SingleParentState) => {
      clearTimeout(timeoutId);
      return new Promise(resolve => {
        timeoutId = setTimeout(() => {
          resolve(this.props.validateApiKeys(state as ApiKey));
        }, 1000);
      });
    };

    const obfuscate = (string: string) => `${string.substr(0, 3)}***`;

    return buildInputI18n(
      'credentials',
      false,
      ['confirmText'],
      {
        fields: [
          {
            id: 'site',
            title: 'Exchange',
            placeholder: 'Select your exchange below...',
            required: true,
            allowedValues: this.props.allowedSites.map(id => {
              return { id, name: id };
            }),
          },
          {
            id: 'key',
            title: 'Api Key',
            placeholder: 'ex: a0dc04aa2347b49fa6d2477b44966608',
            required: true,
            render: obfuscate,
          },
          {
            id: 'secret',
            title: 'Api Secret',
            placeholder: 'ex: 6aab2f6fe9c3fab4e4c971aafb9bc908',
            required: true,
            render: obfuscate,
          },
        ] as Array<Field>,
      },
      validator
    );
  }

  buildAutocompleteWallet() {
    return buildInputI18n(
      'walletHoldings',
      false,
      ['confirmText'],
      {
        fields: [
          {
            id: 'kind',
            title: 'Symbol',
            placeholder: 'BTC',
            required: true,
            autocomplete: buildAutocompleteCoinProps(this.props.coinInfo),
          },
          { id: 'amount', title: 'Amount Owned', placeholder: '2.30932', required: true },
        ] as Array<Field>,
      },
      validateFloatKey('amount')
    );
  }

  render() {
    const finalInputs = inputs.concat(this.buildAutocompleteWallet(), this.buildCredentials());

    return <TypeForm inputs={finalInputs} order={order} onComplete={this.props.onComplete} />;
  }
}

const processFormData = (formData: FormState): SettingsData => {
  return {
    credentials: formData.lists.credentials.map(({ site, key, secret }) => {
      return { site, key, secret };
    }),
    totalCashInvested: parseFloat(formData.single.totalCashInvested.totalCashInvested),
    allowedSites: [],
    addresses: [],
    walletHoldings: formData.lists.walletHoldings.map(holdingObj => {
      return {
        kind: holdingObj.kind,
        amount: parseFloat(holdingObj.amount),
      };
    }),
  };
};

const mapStateToProps = (state: State) => {
  return {
    allowedSites: state.user.settings.allowedSites,
    coinInfo: state.api.coinInfo,
    validateApiKeys: ({ site, key, secret }: ApiKey) =>
      apiService
        .checkCredentials(site, key, secret, state.auth.userId as number, state.auth.authToken as string)
        .then(() => {
          return {};
        })
        .catch(() => {
          return { key: 'key failed!', secret: 'secret failed!' };
        }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onDisplay: () => {
      dispatch(api.fetchCoinInfo());
    },
    onComplete: async (formData: FormState) => {
      await dispatch(user.updateSettings(processFormData(formData)));
      dispatch(push('/dashboard'));
    },
    loadSettings: () => {
      dispatch(user.loadSettings());
    },
  };
};

const OnboardingContainer = connect(mapStateToProps, mapDispatchToProps)(Onboarding);

export { Onboarding, OnboardingContainer, processFormData };
