import { head, compact } from 'lodash';

import { AddressInfo } from 'app/types/ethplorer';
import WebRequestService, { ResponseError } from 'app/services/web_request_service';
import { chunkDelay, delayedPromise } from 'app/utils';

const maxRetries = 3;
const throttleDelay = 2000;
const throttleJitter = 1000;

class EthplorerService extends WebRequestService {
  baseUrl: string;

  constructor() {
    super();
    this.baseUrl = 'https://api.ethplorer.io/';
    this.defaultHeaders = {};
    this.defaultQueryParams = { apiKey: 'freekey' };
  }

  async getAddressInfoMulti(addresses: Array<string>): Promise<Array<AddressInfo>> {
    if (addresses.length === 0) return [];
    else {
      const result = await chunkDelay(addresses, 2)(this.getAddressInfoWithRetry.bind(this));
      return compact(result);
    }
  }

  private async getAddressInfoWithRetry(address: string, callCount = 1): Promise<AddressInfo | undefined> {
    try {
      return this.getJson(`getAddressInfo/${address}`);
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 429 && callCount <= maxRetries) {
          const delay = callCount * throttleDelay + Math.random() * throttleJitter;
          console.warn(`rate limited by ethplorer, retrying in: ${delay}ms`);
          await delayedPromise(delay);
          return this.getAddressInfoWithRetry(address, callCount + 1);
        } else if (error.response.status === 429) console.warn('ethplorer rate limit hit');
        else console.error(`ethplorer error: ${JSON.stringify(error.json)}`);
      } else console.error('ethplorer is down', error);

      return;
    }
  }

  extractJsonData(json: Array<{}> | {}) {
    return Array.isArray(json) && json.length === 1 ? head(json) : json;
  }
}

export default EthplorerService;
