import * as React from 'react';
import styled from 'styled-components';
import { every, uniqWith, isEqual, some, mapValues } from 'lodash';
import hash from 'object-hash';

import { Field, Errors, StateUpdater, SingleParentState, Input, ListParentState } from 'app/types/typeform';
import { Table, Tr, DeleteButton } from 'app/components/typeform/table';
import { AddButton } from 'app/components/typeform/buttons';
import { SingleInput } from 'app/components/typeform/inputs/single_input';
import { media } from 'app/utils/responsive';
import { MicroStaticLoadingSpinner as Spinner } from 'app/components/loading_spinner';

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding-right: 15px;
`;

const TableSection = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  font-size: 18px;
  margin: 0px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${InputSection} {
    label {
      font-size: 14px;

      ${media.nonDesktop} {
        font-size: 12px;
      }
    }

    input {
      padding: 10px;
      font-size: 18px;

      ${media.nonDesktop} {
        font-size: 14px;
        padding: 5px 8px;
      }
    }
  }
`;

const ButtonRow = styled.div`
  margin-top: 5px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  ${Spinner} {
    margin-right: 5px;
  }
`;

export type Props = {
  id: string;
  errors: Errors;
  updateErrors: (errors: Errors) => void;
  parentState: ListParentState;
  updateInputState: StateUpdater;
  placeholder?: string;
  input: Input;
  fields?: Array<Field>;
};

type State = {
  current: SingleParentState;
  items: ListParentState;
  fields: Array<Field>;
  isLoading: boolean;
};

const createBlankState = (fields: Array<Field>) => {
  const blankState: SingleParentState = {};
  fields.forEach(field => {
    blankState[field.id] = '';
  });
  return blankState;
};

const defaultFields = (props: Props, fields: Array<Field> | undefined) =>
  fields || [
    {
      id: props.id,
      required: props.input.required,
      placeholder: props.placeholder,
    },
  ];

class ListInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const fields = defaultFields(props, props.fields);
    this.state = {
      fields,
      current: createBlankState(fields),
      items: props.parentState,
      isLoading: false,
    };

    this.submitNew = this.submitNew.bind(this);
    this.cancelNew = this.cancelNew.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({ items: nextProps.parentState, fields: defaultFields(nextProps, nextProps.fields) });
  }

  renderItems() {
    const headers = this.state.fields.map(field => {
      const key = field.title || field.id;
      return <th key={key}>{key}</th>;
    });
    headers.push(<th key="delete-header" />);

    const items = this.state.items.map((item, i) => {
      const values = this.state.fields.map(field => {
        const content = field.render ? field.render(item[field.id]) : item[field.id];
        return <td key={field.id}>{content}</td>;
      });
      values.push(
        <td key="delete-button">
          <DeleteButton onClick={this.deleteItem(i)} />
        </td>
      );

      return <Tr key={hash(item)}>{values}</Tr>;
    });
    return (
      <Table>
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>{items}</tbody>
      </Table>
    );
  }

  onKeyPress(event: React.KeyboardEvent, source?: Object) {
    if (event.key === 'Enter') this.submitNew(event, source);
  }

  onKeyUp(event: React.KeyboardEvent, source?: Object) {
    if (event.keyCode === 27) this.cancelNew(event, source);
  }

  deleteItem(index: number) {
    return (event: React.SyntheticEvent) => {
      const newItems = this.state.items.slice();
      const [removed] = newItems.splice(index, 1);
      this.props.updateInputState(newItems);

      analytics.track('ux.typeform.listInput.deleteItem', { removed });

      event.preventDefault();
    };
  }

  cancelNew(_event: React.KeyboardEvent, source?: Object) {
    analytics.track('ux.typeform.listInput.cancelNew', { id: this.props.id, fromKeyboard: !!source });

    this.setState({ current: createBlankState(this.state.fields) }, () => {
      this.props.updateErrors(mapValues(this.props.errors, () => null));
    });
  }

  inputIsValid() {
    const anyInputNonEmpty = some(this.state.current, value => value.length > 0);
    const requiredFields = this.state.fields.filter(field => field.required);
    const requiredInputsNonEmpty = every(requiredFields, field => this.state.current[field.id].length > 0);
    const inputValid = every(this.props.errors, value => value === null);

    return !this.state.isLoading && inputValid && anyInputNonEmpty && requiredInputsNonEmpty;
  }

  submitNew(_event: React.SyntheticEvent | undefined, source?: Object) {
    const capturedCurrentState = this.state.current;
    const isValid = this.inputIsValid();

    analytics.track('ux.typeform.listInput.submitNew', {
      isValid,
      capturedCurrentState,
      id: this.props.id,
      fromKeyboard: !!source,
    });

    if (isValid) {
      const newParentState = uniqWith(this.state.items.concat(capturedCurrentState), isEqual);
      const update = () => {
        this.props.updateInputState(newParentState);
      };

      this.setState({ current: createBlankState(this.state.fields) }, update);
    }
  }

  setLoading(isLoading: boolean) {
    this.setState({ isLoading });
  }

  render() {
    return (
      <Wrapper>
        <InputSection>
          <SingleInput
            key={`item-${this.props.parentState.length + 1}`}
            errors={this.props.errors}
            updateErrors={this.props.updateErrors}
            parentState={this.state.current}
            updateInputState={current => this.setState({ current })}
            input={this.props.input}
            fields={this.state.fields}
            setLoading={this.setLoading}
            onKeyUp={this.onKeyUp}
            onKeyPress={this.onKeyPress}
          />
          <ButtonRow>
            {this.state.isLoading && <Spinner />}
            <AddButton onClick={this.submitNew} disabled={!this.inputIsValid()}>
              Add
            </AddButton>
          </ButtonRow>
        </InputSection>
        <TableSection>{this.renderItems()}</TableSection>
      </Wrapper>
    );
  }
}

export { ListInput };
