import url from 'url';
import { ParsedUrlQueryInput } from 'querystring';
import { AuthFailed } from 'app/types/auth';

interface JsonResponse {
  data?: Array<unknown>;
}

class BaseError {
  constructor(message: string) {
    Error.apply(this, [message]);
  }
}

export class ResponseError extends BaseError {
  public message: string;

  constructor(public json: AuthFailed, public response: Response) {
    super(json.message);
    this.message = json.message;
    this.json = json;
    this.response = response;

    // this allows using instanceof to narrow the type
    // see here for more details: https://stackoverflow.com/a/41429145/410286
    Object.setPrototypeOf(this, ResponseError.prototype);
  }
}

type RequestJsonOptions = {
  body?: unknown;
  query?: ParsedUrlQueryInput;
  headers?: Record<string, string>;
};

/**
 * ApiService contains all functions used to make requests to the backend. It also
 * contains helper functions that are used to help make web requests less verbose
 *
 * expects this.baseUrl to be set
 */
abstract class WebRequestService<PayloadStructure = JsonResponse> {
  abstract baseUrl: string;
  defaultHeaders: Record<string, string>;
  defaultQueryParams: ParsedUrlQueryInput;

  constructor() {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
    this.defaultQueryParams = {};
  }

  abstract extractJsonData(json: PayloadStructure): unknown;

  getJson(endpoint: string, json: ParsedUrlQueryInput = {}, headers: Record<string, string> = {}) {
    return this.requestJson('GET', endpoint, { headers, query: json });
  }

  postJson(endpoint: string, json: unknown = {}, headers: Record<string, string> = {}) {
    return this.requestJson('POST', endpoint, { headers, body: json });
  }

  putJson(endpoint: string, json: unknown = {}, headers: Record<string, string> = {}) {
    return this.requestJson('PUT', endpoint, { headers, body: json });
  }

  // refine return type here
  requestJson(
    method: string,
    endpoint: string,
    { body = {}, query = {}, headers = {} }: RequestJsonOptions
  ): Promise<any> {
    const pathname = this.baseUrl + endpoint;

    return fetch(url.format({ pathname, query: { ...query, ...this.defaultQueryParams } }), {
      method,
      credentials: 'same-origin',
      headers: { ...headers, ...this.defaultHeaders },
      body: method === 'GET' || method === 'DELETE' ? null : JSON.stringify(body),
    })
      .then(this.handleResponse.bind(this))
      .catch(err => {
        console.warn(`warning: request to ${endpoint} failed with message: ${err.message}`);
        throw err;
      });
  }

  handleResponse(response: Response) {
    return response
      .json()
      .catch(err => {
        throw new Error(`failed to parse response body: ${err}`);
      })
      .then(json => {
        if (!response.ok || json.status === 'error') {
          const error = new ResponseError(json, response);
          throw error;
        }
        return this.extractJsonData(json);
      });
  }
}

export default WebRequestService;
