import * as React from 'react';
import styled from 'styled-components';
import { find, last, first } from 'lodash';

import { IndividualCoinChart } from 'app/components/charts/individual_coin_chart';
import { GradientStackedBarChart } from 'app/components/charts/gradient_charts/gradient_stacked_bar_chart';
import { PieData } from 'app/components/charts/donut_chart';
import { CoinHistory, HistoricalPoint } from 'app/types/interface';
import { CurrentCoinData, PriceSymbol, CoinPrices } from 'app/types/interface';
import { CoinBalances, CoinInfo } from 'app/types/api';
import { DashboardSection } from 'app/components/dashboard_section';
import { DisplayDollars, DisplayValue, DisplayPercent, DisplayLargeValue } from 'app/components/display_value';
import { Section } from 'app/components/dashboard_section/data_sections';
import { SideChartWrapper } from 'app/components/chart_panel/side_chart_wrapper';
import { ChartSection } from 'app/components/chart_panel/chart_section';
import { TimeRange } from 'app/types/interface';

const GrowthChartTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 200px;
  margin: 0px auto;
`;

type Props = {
  timerange: TimeRange;
  toSymbol: PriceSymbol;
  portfolioHoverValue: number | undefined | null;
  onHighlightChange: (coin?: string) => void;
  coinPrices: CoinPrices;
  coinBalances: CoinBalances;
  coinInfo: CoinInfo;
  highlightCoin: string | undefined;
  individualHoverData: HistoricalPoint | undefined | null;
  growthHoverData: Array<PieData>;
  currentPriceData: CurrentCoinData | undefined | null;
  onIndividualHover: (
    coin: string | undefined,
    individualHoverData: HistoricalPoint | undefined,
    index: number | undefined
  ) => void;
  data: Array<CoinHistory>;
};

const panelData = (
  currentPriceData: CurrentCoinData | undefined | null,
  individualHoverData: HistoricalPoint | undefined | null
): Array<Section> => {
  if (currentPriceData) {
    const result: Array<Section> = [];

    if (individualHoverData) {
      result.push(
        {
          title: 'Market Cap',
          content: (
            <DisplayDollars miniDecimal value={currentPriceData.current.supply * individualHoverData.price.value} />
          ),
        },
        {
          title: 'Volume Out',
          content: <DisplayValue miniDecimal value={individualHoverData.volume.from || 0} />,
        },
        {
          title: 'Volume In',
          content: <DisplayValue miniDecimal value={individualHoverData.volume.to || 0} />,
        }
      );
    } else {
      result.unshift({
        title: 'Total Supply',
        content: <DisplayLargeValue miniDecimal value={currentPriceData.current.supply} />,
      });
      result.push(
        { title: 'Market Cap', content: <DisplayDollars miniDecimal value={currentPriceData.current.marketCap} /> },
        {
          title: '24 Hour Volume',
          content: <DisplayLargeValue miniDecimal value={currentPriceData.current.hour24.totalVolume} />,
        }
      );
    }

    return result;
  }
  return [];
};

const IndividualCoinSection = (props: Props) => {
  const coinInfo = find(props.coinInfo, ['symbol', props.highlightCoin]);
  let infoPanelHeader = 'Coin Info';
  if (coinInfo) infoPanelHeader = `${coinInfo.symbol} - ${coinInfo.name || ''}`;

  let individualPrice;
  const currentPrice = props.highlightCoin ? props.coinPrices[props.highlightCoin][props.toSymbol].current.price : null;
  if (props.individualHoverData) {
    individualPrice = props.individualHoverData.price.value;
  } else if (props.highlightCoin) {
    individualPrice = currentPrice;
  }

  const individualValue = individualPrice ? (
    <DisplayValue miniDecimal kind={props.toSymbol} value={individualPrice} />
  ) : null;
  let individualPercent;
  if (props.highlightCoin) {
    const { data } = find(props.data, ['coin', props.highlightCoin]) || { data: [] };
    if (data.length) {
      const firstClose = first(data)?.price.value || 0;
      const comparePoint = props.individualHoverData
        ? props.individualHoverData.price.value
        : last(data)?.price.value || 0;
      const percent = firstClose ? (comparePoint - firstClose) / firstClose : 0;
      individualPercent = <DisplayPercent miniDecimal value={percent} change={percent} />;
    }
  }

  const barData = props.growthHoverData.map(({ name, value }) => {
    return { name, x: 0, y: value };
  });

  let highlightCoinGrowthValue;
  const found = find(barData, ['name', props.highlightCoin]);
  if (found) highlightCoinGrowthValue = found.y;

  return (
    <DashboardSection
      title={infoPanelHeader}
      mainValue={individualValue}
      mainPercentValue={individualPercent}
      data={panelData(props.currentPriceData, props.individualHoverData)}
    >
      <ChartSection>
        <div style={{ zIndex: 1, width: '100%', height: '100%' }}>
          <IndividualCoinChart
            timerange={props.timerange}
            coinBalances={props.coinBalances}
            data={props.data}
            highlightCoin={props.highlightCoin}
            toSymbol={props.toSymbol}
            onHover={props.onIndividualHover}
          />
        </div>
        <SideChartWrapper style={{ zIndex: 0 }}>
          <GradientStackedBarChart
            highlightCoin={props.highlightCoin}
            onHover={props.onHighlightChange}
            series={barData}
            name="dominance"
          />
          <GrowthChartTitle>
            <span>Relative Growth:</span>
            <span>{highlightCoinGrowthValue && <DisplayPercent miniDecimal value={highlightCoinGrowthValue} />}</span>
          </GrowthChartTitle>
        </SideChartWrapper>
      </ChartSection>
    </DashboardSection>
  );
};

export { IndividualCoinSection };
