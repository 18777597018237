import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import LogoWhite from 'app/assets/images/logo.white.svg';

const Wrapper = styled.div`
  background-color: ${colors.black};
  height: 95px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  padding: 0px 35px;
  margin-top: 100px;

  a {
    display: inline-block;
    margin-left: 35px;
  }
`;

const Footer = () => (
  <Wrapper>
    <LogoWhite height={58} width={58} />
    <a href="#">Terms & conditions</a>
    <a href="#">FAQ</a>
    <a href="#">About</a>
  </Wrapper>
);

export { Footer };
