import * as React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { AllowedOption, SingleParentState, Errors, Input, Autocomplete } from 'app/types/typeform';
import { ErrorMessage } from 'app/components/typeform/inputs/error_message';
import { AutocompleteInput } from 'app/components/inputs/autocomplete_input';
import { SimpleFormEvent } from 'types/globals';

const Wrapper = styled.div`
  width: 100%;
`;

type Props = {
  id: string;
  errors: Errors;
  parentState: SingleParentState;
  input: Input;
  onChange: (event: { currentTarget: Pick<React.FormEvent<HTMLInputElement>['currentTarget'], 'value'> }) => void;
  allowedValues?: Array<AllowedOption>;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  placeholder?: string;
  shouldFocus?: boolean;
  autocomplete?: Autocomplete;
};

type State = {
  isTouched: boolean;
};

class InputField extends React.Component<Props, State> {
  inputRef: HTMLElement | undefined;

  constructor(props: Props) {
    super(props);

    this.state = { isTouched: false };

    this.setTouched = this.setTouched.bind(this);
  }

  setTouched() {
    this.setState({ isTouched: true });
  }

  componentDidMount() {
    if (this.props.shouldFocus && this.inputRef) this.inputRef.focus();
  }

  componentDidUpdate(prevProps: Props) {
    const valueChanged = prevProps.parentState[prevProps.id] !== this.props.parentState[this.props.id];
    if (valueChanged && this.props.shouldFocus && this.inputRef) this.inputRef.focus();
  }

  renderInput(className: string | undefined) {
    const props = this.props;
    const touchedOnChange = (event: SimpleFormEvent) => {
      props.onChange(event);
      if (!this.state.isTouched) this.setState({ isTouched: true });
    };

    const inputProps = {
      className,
      type: 'text',
      ref: (input: HTMLInputElement) => {
        this.inputRef = input;
      },
      value: props.parentState[props.id],
      name: props.id,
      placeholder: props.placeholder || props.input.placeholder,
      onChange: touchedOnChange,
      onKeyUp: props.onKeyUp,
      onKeyDown: props.onKeyDown,
      onKeyPress: props.onKeyPress,
      onBlur: this.setTouched,
    };

    if (props.autocomplete) {
      return <AutocompleteInput onChange={touchedOnChange} inputProps={inputProps} {...props.autocomplete} />;
    } else if (props.allowedValues) {
      // @ts-expect-error
      const transformedOnChange = option =>
        touchedOnChange({ currentTarget: { value: option.value }, target: { value: option.value } });
      const options = props.allowedValues.map(option => {
        return { value: option.id, label: option.name };
      });

      return (
        <Select
          {...(inputProps as Omit<typeof inputProps, 'ref' | 'value'>)}
          onChange={transformedOnChange}
          options={options}
        />
      );
    } else return <input {...inputProps} />;
  }

  render() {
    const errorMessage = this.props.errors[this.props.id];

    return (
      <Wrapper>
        {this.renderInput(this.state.isTouched && errorMessage ? 'error' : undefined)}
        <ErrorMessage>{this.state.isTouched && errorMessage}</ErrorMessage>
      </Wrapper>
    );
  }
}

export { InputField };
