import * as React from 'react';
import styled from 'styled-components';
import AnimatedNumber from 'react-animated-number';

import { kindToFormatter, Kind } from 'app/utils/formatter';
import colors from 'app/styles/colors';

export { Kind };

const animationDuration = 3;
const transition = 'cubic-bezier(0.315, 0.295, 0.020, 1.000)';

const Span = styled.span`
  text-align: center;
  ${props => (props.color ? `color: ${props.color}` : '')};
`;

type Props = {
  value: number;
  change?: number;
  miniDecimal?: boolean;
  changeFlash?: boolean;
};

type MiniDecimalProps = {
  children?: string | number;
  style?: Object;
};

const MiniDecimal = (props: MiniDecimalProps) => {
  if (props.children) {
    const stringNumber = props.children.toString();
    const [first, second] = stringNumber.split('.');
    const style = props.style ? props.style : {};
    return (
      <span style={style}>
        {`${first}.`}
        <span style={{ fontSize: '80%' }}>{second}</span>
      </span>
    );
  } else return <span />;
};

const DisplayBTC = (props: Props) => <DisplayValue kind="BTC" {...props} />;
const DisplayPercent = (props: Props) => <DisplayValue kind="%" {...props} />;
const DisplayDollars = (props: Props) => <DisplayValue kind="USD" {...props} />;
const DisplayLargeValue = (props: Props) => <DisplayValue kind="UNITLESS" {...props} />;

type DisplayProps = Props & { kind: Kind };
type State = { classname: 'change-up' | 'change-down' | undefined };

class DisplayValue extends React.Component<DisplayProps, State> {
  static defaultProps = {
    kind: 'RAW',
    changeFlash: false,
  };

  constructor(props: DisplayProps) {
    super(props);
    this.state = { classname: undefined };
  }

  shouldComponentUpdate(nextProps: DisplayProps) {
    return this.props.value !== nextProps.value || this.props.change !== nextProps.change;
  }

  UNSAFE_componentWillReceiveProps(nextProps: DisplayProps) {
    if (this.props.changeFlash) {
      if (nextProps.value > this.props.value) this.setState({ classname: 'change-up' });
      else if (nextProps.value < this.props.value) this.setState({ classname: 'change-down' });
      else this.setState({ classname: undefined });
    }
  }

  renderNumberContent() {
    const { changeFlash, miniDecimal, value, kind } = this.props;
    const cleanValue = typeof value === 'string' ? parseFloat(value) : value;

    const formatter = kindToFormatter(kind);

    let color: string | undefined;
    if (this.state.classname === 'change-up') color = colors.green;
    else if (this.state.classname === 'change-down') color = colors.red;

    if (changeFlash)
      return (
        <AnimatedNumber
          style={{ transition: `${animationDuration}s ${transition}`, transitionProperty: 'color' }}
          duration={animationDuration * 1000}
          value={cleanValue}
          formatValue={formatter}
          component={miniDecimal ? MiniDecimal : 'span'}
          frameStyle={(perc: number) => (perc === 100 ? {} : { color })}
        />
      );
    else {
      const formattedValue = formatter(cleanValue);
      if (miniDecimal) {
        return <MiniDecimal>{formattedValue}</MiniDecimal>;
      } else return formattedValue;
    }
  }

  render() {
    const { changeFlash, value, change, kind } = this.props;

    let color;
    if (!changeFlash && change) {
      if (change > 0) color = colors.green;
      else if (change < 0) color = colors.red;
    }

    const titleHeader = kind === 'RAW' ? 'value:' : `value in ${kind}:`;
    const title = `${titleHeader} ${value}`;

    return (
      <Span className={this.state.classname} color={color} title={title}>
        {this.renderNumberContent()}
      </Span>
    );
  }
}

export { DisplayBTC, DisplayPercent, DisplayDollars, DisplayValue, Span, DisplayLargeValue };
