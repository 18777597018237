import * as React from 'react';
import styled from 'styled-components';

import LogoBlue from 'app/assets/images/logo.blue.svg';
import LogoText from 'app/assets/images/logo.text.black.svg';
import colors from 'app/styles/colors';
import dimensions from 'app/styles/dimensions';
import { GradientBorderButton } from 'app/components/gradient_border_button';
import NavigationLink from 'app/components/navigationLink';
import { media } from 'app/utils/responsive';

const { paddingVertical, paddingHorizontal, height } = dimensions.home.header;

const Wrapper = styled.div`
  padding: ${paddingVertical}px ${paddingHorizontal}px;
  height: ${height}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.loading .logo-blue {
    animation: spin-reverse 0.3s linear infinite;
  }

  ${media.nonDesktop} {
    padding: 0px 20px;
    padding-top: ${paddingVertical}px;
    margin-bottom: ${paddingVertical}px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${media.desktop} {
    & > * {
      margin-left: 40px;
    }
  }

  ${media.nonDesktop} {
    & > * {
      display: none;
      &:last-child {
        display: block;
      }
    }
  }
`;

const SignupWrapper = styled.div`
  ${media.nonDesktop} {
    min-width: auto;
  }
`;

const actionStyling = `
  font-size: 14px;
  font-weight: bold;
  color: ${colors.text.black};
`;

const NavAction = styled(NavigationLink)<{ to: string }>`
  ${actionStyling};
`;

const Action = styled.a`
  ${actionStyling};
`;

type Props = {
  isLoading?: boolean;
  loggedIn: boolean;
  onRegister: () => void;
  onLoginOrDashboard: () => void;
};

const Header = (props: Props) => {
  const loginButton = props.loggedIn ? (
    <SignupWrapper key="dashboard">
      <GradientBorderButton content="go to dashboard" large onClick={props.onLoginOrDashboard} />
    </SignupWrapper>
  ) : (
    <NavAction key="login" to="/dashboard">
      login
    </NavAction>
  );
  const featuresButton = <Action key="features">features</Action>;
  const contactButton = <Action key="contact">contact</Action>;
  const signUpButton = !props.loggedIn && (
    <SignupWrapper key="signup">
      <GradientBorderButton content="sign up" large onClick={props.onRegister} />
    </SignupWrapper>
  );
  return (
    <Wrapper className={props.isLoading ? 'loading' : ''}>
      <LogoWrapper>
        <LogoBlue style={{ marginRight: 8 }} className="logo-blue" />
        <LogoText style={{ paddingBottom: 7 }} />
      </LogoWrapper>
      <Actions>{[featuresButton, contactButton, loginButton, signUpButton]}</Actions>
    </Wrapper>
  );
};

export { Header };
