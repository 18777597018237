import { flatMap, difference, uniq, drop, findIndex, fromPairs, map, omit, sortBy, take } from 'lodash';

import { BalanceState } from 'app/store/modules/balance/reducer';
import { Balance, CoinHoldings } from 'app/types/api';
import { AddressInfo } from 'app/types/ethplorer';
import { convertToInterfaceSnapshotData, convertToInterfaceCoinPrices } from 'app/types/crypto_compare';
import { CoinHistory, PriceSymbol, CoinPrices } from 'app/types/interface';
import { detailedToInterface, marketChartRangeToInterface } from 'app/types/coingecko';
import services from 'app/services';
import { State } from 'app/store/types';
import { TimeRange } from 'app/types/interface';
import { chunkDelay, reallignTime } from 'app/utils';
import { loadingActionBuilder } from 'app/store/modules/utils';
import { setTimePoints } from '../crypto_compare';

export type LoadingKey = 'ethereumBalances' | 'coinPrices' | 'balances' | 'coinHistorical' | 'allCoinsHistorical';

export type CoinOrigin = 'cryptoCompare' | 'coingecko' | 'nomics';

export type CoinPricesAction = { type: 'balance/COIN_PRICES'; payload: CoinPrices };
export type BalancesAction = { type: 'balance/BALANCES'; payload: Balance | Array<Balance> };
export type CoinHistorical = { type: 'balance/COIN_HISTORICAL'; payload: Array<CoinHistory> };
export type DisableCoinsAction = { type: 'balance/DISABLE_COINS'; payload: string | Array<string> };
export type SetCoinOrigins = { type: 'balance/SET_COIN_ORIGINS'; payload: Array<[coin: string, origin: CoinOrigin]> };
export type CalculateCoinHoldingsAction = { type: 'balance/CALCULATE_COIN_HOLDINGS' };
export type TrackCoinsAction = { type: 'balance/TRACK_COINS'; payload: Array<string> };
export type EthereumBalancesAction = { type: 'balance/ETHEREUM_BALANCES'; payload: AddressInfo | Array<AddressInfo> };
export type LoadingCoinsAction = { type: 'balance/LOADING_COINS'; payload: BalanceState['loadingCoins'] };
export type LoadingAction = { type: 'balance/LOADING'; payload: { key: LoadingKey; isLoading: boolean } };

export type BalanceActions =
  | CoinPricesAction
  | BalancesAction
  | CoinHistorical
  | DisableCoinsAction
  | SetCoinOrigins
  | CalculateCoinHoldingsAction
  | TrackCoinsAction
  | EthereumBalancesAction
  | LoadingCoinsAction
  | LoadingAction;

export const loadingAction = loadingActionBuilder<'balance/LOADING', LoadingKey>('balance/LOADING');

export const disableCoinsAction = (coinOrCoins: string | Array<string>): DisableCoinsAction => ({
  type: 'balance/DISABLE_COINS',
  payload: coinOrCoins,
});

export const balancesAction = (balanceOrBalances: Balance | Array<Balance>): BalancesAction => ({
  type: 'balance/BALANCES',
  payload: balanceOrBalances,
});

export const ethereumBalancesAction = (
  balanceOrBalances: AddressInfo | Array<AddressInfo>
): EthereumBalancesAction => ({
  type: 'balance/ETHEREUM_BALANCES',
  payload: balanceOrBalances,
});

export const calculateCoinHoldingsAction = (): CalculateCoinHoldingsAction => ({
  type: 'balance/CALCULATE_COIN_HOLDINGS',
});

export const fetchCoinPrices = (coins?: Array<string>) => async (dispatch: Dispatch, getState: () => State) => {
  dispatch(loadingAction('coinPrices', { isLoading: true }));

  const coinsToLookup = coins && coins.length ? coins : getState().balance.trackedCoins;
  const coinsWithBTC = uniq(coinsToLookup.concat('BTC'));

  let result: CoinPrices | undefined;
  try {
    const cryptoCompareResult = await services.cryptoCompareService.getPrice(coinsWithBTC);
    result = convertToInterfaceCoinPrices(cryptoCompareResult);
  } catch (e) {
    if (e instanceof Error && e.message.match(/rate limit/i)) {
      const {
        auth: { userId, authToken },
      } = getState();
      if (userId && authToken) {
        const nomicsResult = await services.apiService.proxyNomics(userId, authToken, coinsWithBTC);
        result = convertToInterfaceCoinPrices(nomicsResult);
      }
    } else throw e;
  }

  if (!result) {
    alert('Cannot load coin prices, possibly being rate limited. Try turning off wifi or waiting a day.');
    throw new Error('probably rate limited');
  }

  const coinsWithPrices = Object.keys(result);
  dispatch({ type: 'balance/SET_COIN_ORIGINS', payload: coinsWithPrices.map(coin => [coin, 'cryptoCompare']) });
  const coinsWithoutPrices = difference(coinsWithBTC, coinsWithPrices);
  let extraData = {};
  if (coinsWithoutPrices.length) {
    const detailedPrices = await services.coinGeckoService.getDetailedPrices(coinsWithoutPrices);
    dispatch({ type: 'api/UPDATE_COIN_INFO', payload: detailedPrices });
    extraData = Object.assign({}, ...detailedPrices.map(detailedToInterface));
  }

  const finalCoinPrices = Object.assign({}, result, extraData);
  const finalCoinsWithPrices = Object.keys(finalCoinPrices);
  const coingeckoCoins = difference(finalCoinsWithPrices, coinsWithPrices);
  dispatch({ type: 'balance/SET_COIN_ORIGINS', payload: coingeckoCoins.map(coin => [coin, 'coingecko']) });
  const finalCoinsWithoutPrices = difference(coinsWithBTC, finalCoinsWithPrices);

  if (finalCoinsWithPrices.length) dispatch(disableCoinsAction(finalCoinsWithoutPrices));

  dispatch({ type: 'balance/COIN_PRICES', payload: finalCoinPrices });
  dispatch(calculateCoinHoldingsAction());
  dispatch(loadingAction('coinPrices', { isLoading: false }));
};

export const fetchBalances = () => async (dispatch: Dispatch, getState: () => State) => {
  const {
    user: {
      settings: { addresses },
    },
    auth: { userId, authToken },
  } = getState();

  if (userId && authToken) {
    dispatch(loadingAction('balances', { isLoading: true }));
    dispatch(loadingAction('ethereumBalances', { isLoading: true }));

    try {
      const addressInfo = await services.ethplorerService.getAddressInfoMulti(addresses);
      dispatch(ethereumBalancesAction(addressInfo));

      const balances = await services.apiService.getBalances(userId, authToken);
      dispatch(balancesAction(balances));

      const trackedCoins = Object.keys(getState().balance.balances);
      dispatch({ type: 'balance/TRACK_COINS', payload: trackedCoins });

      dispatch(calculateCoinHoldingsAction());
    } catch (e) {
      console.log(e);
    }

    dispatch(loadingAction('balances', { isLoading: false }));
    dispatch(loadingAction('ethereumBalances', { isLoading: false }));
  }
};

export const fetchCoinHistorical =
  (coin: string, toSymbol: PriceSymbol, mtimeRange?: TimeRange) =>
  async (dispatch: Dispatch, getState: () => State) => {
    dispatch(loadingAction('coinHistorical', { isLoading: true }));

    const timeRange = mtimeRange ?? getState().cryptoCompare.timeRange;

    const cryptoCompareResult = await services.cryptoCompareService.getPriceHistory(coin, timeRange, toSymbol);
    let result = convertToInterfaceSnapshotData(cryptoCompareResult);

    // store x axis for realligning coingecko data
    const timePoints = getState().cryptoCompare.timePoints;
    if (result && timePoints == undefined) {
      const newTimePoints = result.map(({ time }) => time).sort((a, b) => a - b);
      dispatch(setTimePoints(newTimePoints));
    }

    // try coingecko data as a backup
    if (result.length === 0) {
      // delay in case we don't have timePoints yet
      const recentTimepoints =
        timePoints ??
        ((await new Promise(resolve => setTimeout(() => resolve(undefined), 3000))) ||
          getState().cryptoCompare.timePoints);

      if (recentTimepoints) {
        const coingeckoResult = await services.coinGeckoService.getPriceHistory(coin, timeRange);
        if (coingeckoResult) {
          const converted = marketChartRangeToInterface(coingeckoResult);
          result = reallignTime(recentTimepoints, converted);
        }
      }
    }

    const {
      balance: { coinHistorical: state, loadingCoins },
    } = getState();

    dispatch({ type: 'balance/LOADING_COINS', payload: omit(loadingCoins, coin) });
    if (result.length === 0) dispatch(disableCoinsAction(coin));

    const existingIndex = findIndex(state, (c: { coin: string }) => c.coin === coin);
    const results = [...take(state, existingIndex), { coin, data: result }, ...drop(state, existingIndex + 1)];

    dispatch({ type: 'balance/COIN_HISTORICAL', payload: results });
    dispatch(loadingAction('coinHistorical', { isLoading: false }));
  };

const coinPriority = (toSymbol: PriceSymbol, coinHoldings: CoinHoldings) => (coin: string) => {
  let ret = Infinity;
  if (coin in coinHoldings) {
    const {
      [coin]: { [toSymbol]: value },
    } = coinHoldings;
    if (value != null) {
      ret = -value;
    }
  }
  return ret;
};

export const fetchAllCoinsHistorical =
  (coinsIn: Array<string>, toSymbol: PriceSymbol, mtimeRange?: TimeRange) =>
  async (dispatch: Dispatch, getState: () => State) => {
    dispatch(loadingAction('allCoinsHistorical', { isLoading: true }));

    const {
      balance: { coinHoldings, loadingCoins, coinDataOrigin },
      cryptoCompare,
    } = getState();

    const cryptoCompareCoinsIn = coinsIn.filter(coin => coinDataOrigin[coin] == 'cryptoCompare');
    const coingeckoCoinsIn = coinsIn.filter(coin => coinDataOrigin[coin] == 'coingecko');

    const timeRange = mtimeRange ?? cryptoCompare.timeRange;
    const cryptoCompareCoins = sortBy(cryptoCompareCoinsIn, coinPriority(toSymbol, coinHoldings));
    const coingeckoCoins = sortBy(coingeckoCoinsIn, coinPriority(toSymbol, coinHoldings));

    const newLoadingCoins: Record<string, true> = fromPairs(
      map([...cryptoCompareCoins, ...coingeckoCoins], c => [c, true])
    );
    dispatch({ type: 'balance/LOADING_COINS', payload: { ...loadingCoins, ...newLoadingCoins } });

    await chunkDelay(cryptoCompareCoins, 5)(coin => dispatch(fetchCoinHistorical(coin, toSymbol, timeRange)));
    await chunkDelay(coingeckoCoins, 5)(coin => dispatch(fetchCoinHistorical(coin, toSymbol, timeRange)));

    dispatch(loadingAction('allCoinsHistorical', { isLoading: false }));
  };

export const fetchPricesForOrders = () => async (dispatch: Dispatch, getState: () => State) => {
  const {
    api: { orders },
  } = getState();
  const coins = uniq(flatMap(orders, order => [order.from, order.to]).concat(['BTC', 'ETH']));
  await dispatch(fetchCoinPrices(coins));
};
