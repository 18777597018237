import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as api from 'app/store/modules/api/actions';
import * as balance from 'app/store/modules/balance/actions';
import { State } from 'app/store/types';
import { CoinInfo, Order as OrderData } from 'app/types/api';
import { StaticLoadingSpinner } from 'app/components/loading_spinner';
import { Order } from 'app/components/order';
import { CoinHistory, CoinPrices } from 'app/types/interface';

const Wrapper = styled.div`
  margin: 0px auto;
  max-width: 100%;
  min-height: 400px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

type Props = {
  onDisplay: () => void;
  orders: Array<OrderData>;
  coinInfo: CoinInfo;
  isLoading: boolean;
  allCoinsData: Array<CoinHistory>;
  coinPrices: CoinPrices;
};

class Orders extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    if (this.props.onDisplay) this.props.onDisplay();
  }

  render() {
    if (this.props.isLoading)
      return (
        <Wrapper>
          <StaticLoadingSpinner large />
        </Wrapper>
      );
    else {
      const orders = this.props.orders.map(order => (
        <Order
          key={order.id}
          coinInfo={this.props.coinInfo}
          order={order}
          isLoading={this.props.isLoading}
          allCoinsData={this.props.allCoinsData}
          coinPrices={this.props.coinPrices}
        />
      ));
      return <Wrapper>{orders}</Wrapper>;
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    allCoinsData: state.balance.coinHistorical,
    orders: state.api.orders,
    coinInfo: state.api.coinInfo,
    isLoading: !!(
      state.api.loading.orders ||
      state.balance.loading.coinPrices ||
      // since orders => prices is chained, there is a brief moment between the two where both might not be loading
      Object.keys(state.balance.coinPrices).length === 0 ||
      state.api.loading.coinInfo ||
      state.balance.loading.coinHistorical
    ),
    coinPrices: state.balance.coinPrices,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onDisplay: async () => {
      await dispatch(api.fetchOrders());
      await dispatch(balance.fetchPricesForOrders());
      await dispatch(api.fetchCoinInfo());
      await dispatch(balance.fetchAllCoinsHistorical(['BTC', 'ETH'], 'USD', 'month'));
    },
  };
};

const OrdersContainer = connect(mapStateToProps, mapDispatchToProps)(Orders);

export { OrdersContainer, Orders };
