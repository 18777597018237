const colors = {
  green: '#2EB235',
  red: '#D6312D',
  gradient: {
    primary: '#774FFF',
    secondary: '#25ADE6',
  },
  text: {
    black: '#3d3d4f',
    darkBlack: '#1e2e42',
    grey: '#8b8b95',
    darkBlue: '#373749',
    blue: '#166ec6',
    brightBlue: '#0037ff',
  },
  ui: {
    grey1: '#F6F6F6',
    grey2: '#f0f0f0',
    grey3: '#ccc',
    grey4: '#bbb',
    grey5: '#a2a2a2',
    grey6: '#979797',
    grey7: '#617283',
    grey9: '#3c3c3c',
  },
  graphs: {
    verticalLine: '#EFEFEF',
    axisLabels: '#BDBDBD',
    crosshair: '#CCC',
    bars: '#EEE',
  },
  table: {
    border: '#EAEAEA',
  },
  black: '#202020',
};

export default colors;
