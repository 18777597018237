import styled from 'styled-components';

import { media } from 'app/utils/responsive';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 60px;

  ${media.nonDesktop} {
    flex-direction: column;

    > div:first-of-type {
      display: none;
    }

    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 20px;
    }
  }
`;
