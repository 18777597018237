import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import { DataSections, Section } from 'app/components/dashboard_section/data_sections';

const Wrapper = styled.div``;

const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.3px;
  color: ${colors.text.blue};
  margin-bottom: 3px;
`;

const MainValue = styled.div`
  font-size: 30px;
  font-weight: bold;
  min-width: 210px;
  height: 38px;
  line-height: 38px;
  color: ${colors.text.darkBlue};
`;

const Small = styled.span`
  font-size: 14px;
  margin-left: 3px;
`;

type Props = {
  title: React.ReactNode;
  mainValue: React.ReactNode;
  mainPercentValue?: React.ReactNode;
  data: Array<Section>;
  children: React.ReactNode;
};

const DashboardSection = (props: Props) => (
  <Wrapper>
    <Title>{props.title}</Title>
    <NumbersWrapper>
      <MainValue>
        {props.mainValue}
        {props.mainPercentValue && <Small>{props.mainPercentValue}</Small>}
      </MainValue>
      <DataSections data={props.data} />
    </NumbersWrapper>
    {props.children}
  </Wrapper>
);

export { DashboardSection, Wrapper };
