import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { difference, uniq } from 'lodash';

import { loadSettings, updateSettings } from 'app/store/modules/user/actions';
import colors from 'app/styles/colors';
import { FormSection, InputSection } from 'app/components/form/form_section';
import { FormError } from 'app/components/form/form_error';
import { LoadingSpinner } from 'app/components/loading_spinner';
import { EditList, Item } from 'app/components/inputs/edit_list';
import { GradientBorderButton } from 'app/components/gradient_border_button';
import { State as GlobalState } from 'app/store/types';
import { SimpleFormEvent } from 'types/globals';

const SettingLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CredentialsWrapper = styled.div`
  font-size: 14px;

  strong {
    color: ${colors.ui.grey6};
    font-weight: normal;
  }

  ${SettingLine} {
    margin-bottom: 10px;
    & + & {
      margin-top: 5px;
    }
  }
`;

export type WalletHolding = {
  amount: number;
  kind: string;
  address?: string;
};

export type Credentials = {
  site: string;
  key: string;
  secret: string | undefined;
};

export type SettingsData = {
  totalCashInvested: number | undefined;
  allowedSites: Array<string>;
  credentials: Array<Credentials>;
  addresses: Array<string>;
  walletHoldings: Array<WalletHolding>;
};

type Props = {
  isWaitingOnServer: boolean;
  errorMessage?: string;
  data: SettingsData;
  loadSettings: () => void;
  saveSettings: (settings: SettingsData) => Promise<void>;
  onSettingsEnd: () => void;
};

type State = {
  data: SettingsData;
};

class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.data,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.updateAddresses = this.updateAddresses.bind(this);
    this.updateWalletHoldings = this.updateWalletHoldings.bind(this);
    this.updateCredentials = this.updateCredentials.bind(this);
    this.renderCredentials = this.renderCredentials.bind(this);
    this.renderCredentialsInput = this.renderCredentialsInput.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadSettings();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      data: nextProps.data,
    });
  }

  handleChange(key: string) {
    return (event?: React.FormEvent<HTMLInputElement>) => {
      const toAdd = event ? { [key]: event.currentTarget.value } : {};
      this.setState({
        data: {
          ...this.state.data,
          ...toAdd,
        },
      });
    };
  }

  submitForm(event: React.SyntheticEvent | undefined) {
    if (event) event.preventDefault();

    const payload: SettingsData = { ...this.state.data };
    const numericAmount = parseFloat((this.state.data.totalCashInvested || '').toString());
    payload.totalCashInvested = isNaN(numericAmount) ? undefined : numericAmount;

    this.props.saveSettings(payload).then(() => this.props.onSettingsEnd());
  }

  updateAddresses(updated: Array<string>) {
    this.setState({ data: { ...this.state.data, addresses: uniq(updated) } });
  }

  updateWalletHoldings(updatedList: Array<WalletHolding>) {
    updatedList.forEach(item => {
      const numericAmount = parseFloat(item.amount.toString());
      item.amount = isNaN(numericAmount) ? 0 : numericAmount;
    });

    this.setState({ data: { ...this.state.data, walletHoldings: updatedList } });
  }

  updateCredentials(updatedList: Array<Credentials>) {
    this.setState({ data: { ...this.state.data, credentials: updatedList } });
  }

  renderCredentials(credentials: Credentials) {
    const obfuscate = (string: string) => string.slice(0, 5) + Array(27).join('*');
    return (
      <CredentialsWrapper>
        <SettingLine>
          <strong>Site:</strong>
          <span>{credentials.site}</span>
        </SettingLine>
        <SettingLine>
          <strong>Key:</strong>
          <span>{obfuscate(credentials.key)}</span>
        </SettingLine>
        <SettingLine>
          <strong>Secret:</strong>
          <span>{obfuscate(credentials.secret || '')}</span>
        </SettingLine>
      </CredentialsWrapper>
    );
  }

  renderWalletHolding(holding: WalletHolding) {
    return (
      <SettingLine>
        <strong>{holding.kind}</strong>
        <em>{holding.amount}</em>
      </SettingLine>
    );
  }

  renderCredentialsInput(
    update: (name: string) => (event: SimpleFormEvent) => void,
    onKeyPress: (key: React.KeyboardEvent) => void,
    value: Item | string
  ) {
    const existingSites = this.state.data.credentials.map(credentials => credentials.site);
    const newSitesAvailable = difference(this.state.data.allowedSites, existingSites);

    // FIX: guard against misuse inside of EditList, flow was hiding this issue
    if (typeof value === 'string') throw new Error();

    if (newSitesAvailable.length > 0) {
      if (value.site == null) {
        const value = newSitesAvailable[0];
        // FIX: react doesn't like when you update within a state transition (this function is called within render)
        process.nextTick(() => {
          update('site')({ target: { value }, currentTarget: { value } });
        });
      }

      const render = (name: string, placeholder: string) => (
        <input
          id={name}
          key={name}
          placeholder={placeholder}
          onChange={update(name)}
          onKeyPress={onKeyPress}
          // FIX: i think this was an error
          // onKeyUp={onKeyPress(name)}
          value={value[name] || ''}
        />
      );

      const options = newSitesAvailable.map(siteKey => (
        <option key={siteKey} value={siteKey}>
          {siteKey}
        </option>
      ));
      const selectBox = (
        <select id="site" key="site" onChange={update('site')} value={value.site}>
          {options}
        </select>
      );

      return (
        <div>
          {[
            selectBox,
            render('key', 'API key, e.g. qWERsdafwera8234asdfq09234adfakweoruWEr'),
            render('secret', 'API Secret, e.g. qpisdipaoiuEPOIUWER203894s8df0Sd9fasdfq (optional)'),
          ]}
        </div>
      );
    } else {
      return <div>You have credentials for all available integrations</div>;
    }
  }

  renderWalletHoldingInput(
    update: (name: string) => (event: React.SyntheticEvent) => void,
    onKeyPress: (key: React.KeyboardEvent) => void,
    value: Item | string
  ) {
    if (typeof value === 'string') throw new Error();

    const render = (name: string, placeholder: string) => (
      <input
        id={name}
        key={name}
        placeholder={placeholder}
        onChange={update(name)}
        onKeyPress={onKeyPress}
        // FIX: i think this was an error
        // onKeyUp={onKeyPress(name)}
        value={value[name] || ''}
      />
    );

    return (
      <div>{[render('kind', 'coin symbol, e.g. BTC'), render('amount', 'coin count in wallet, e.g. 10.012345')]}</div>
    );
  }

  render() {
    return (
      <form>
        <InputSection
          id="totalCashInvested"
          label="Total Cash Invested"
          value={this.state.data.totalCashInvested || ''}
          disabled={this.props.isWaitingOnServer}
          onChange={this.handleChange('totalCashInvested')}
        />
        <EditList
          label="Exchange Integrations (Bittrex, etc...)"
          labelFor="credentials"
          items={this.state.data.credentials as Array<Item>}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          updateList={this.updateCredentials}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          renderItem={this.renderCredentials}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          renderInput={this.renderCredentialsInput}
        />
        <EditList
          label="Holdings in Wallets"
          labelFor="kind"
          items={this.state.data.walletHoldings as Array<Item>}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          updateList={this.updateWalletHoldings}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          renderItem={this.renderWalletHolding}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          renderInput={this.renderWalletHoldingInput}
        />
        <EditList
          label="Monitored Ethereum Addresses"
          labelFor="addresses"
          items={this.state.data.addresses}
          // @ts-ignore FIX: rework edit list to be generic, create EditStringList as a premade instance of the generic
          updateList={this.updateAddresses}
        />
        <FormSection style={{ textAlign: 'center' }}>
          {this.props.errorMessage && <FormError>{this.props.errorMessage}</FormError>}
          <GradientBorderButton content="Update" onClick={this.submitForm} disabled={this.props.isWaitingOnServer} />
          <GradientBorderButton content="Cancel" onClick={this.props.onSettingsEnd} selected={false} />
          <LoadingSpinner position="static" isLoading={this.props.isWaitingOnServer} />
        </FormSection>
      </form>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    isWaitingOnServer: state.user.fetchingSettings || state.user.updatingSettings,
    data: state.user.settings,
    errorMessage: state.user.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveSettings: (data: SettingsData) => dispatch(updateSettings(data)),
    loadSettings: () => {
      dispatch(loadSettings());
    },
  };
};

const SettingsContainer = connect(mapStateToProps, mapDispatchToProps)(Settings);

export { Settings, SettingsContainer };
