import { compact } from 'lodash';

import { DetailedPrice, CoinListing, SimplePriceResponse, toInterface, MarketChartRange } from 'app/types/coingecko';
import { TimeRange, PriceSymbol, CoinPrices } from 'app/types/interface';
import WebRequestService from 'app/services/web_request_service';

const timeRangeToDays = {
  hour: { days: 1 / 24, interval: 'minutely' },
  '6 hours': { days: 6 / 24, interval: 'minutely' },
  day: { days: 1, interval: 'minutely' },
  week: { days: 7, interval: 'hourly' },
  '2 weeks': { days: 7 * 2, interval: 'hourly' },
  month: { days: 31, interval: 'daily' },
  '3 months': { days: 31 * 3, interval: 'daily' },
  '6 months': { days: 31 * 6, interval: 'daily' },
  year: { days: 365, interval: 'daily' },
};

export class CoinGeckoService extends WebRequestService<Array<unknown> | unknown> {
  baseUrl: string;

  private symbolToInfo: Record<string, { id: string; name: string }> = {};
  private idToSymbol: Record<string, string> = {};

  constructor() {
    super();
    this.baseUrl = 'https://api.coingecko.com/api/v3/';
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }

  async getCoinList(): Promise<Array<CoinListing>> {
    const result: Array<CoinListing> = await this.getJson('coins/list');
    result.forEach(({ id, symbol, name }) => {
      // a few coins have two ids with the same symbol and one named "...[OLD]"
      if (!name.match('\\[OLD\\]')) {
        this.symbolToInfo[symbol] = { id, name };
        this.idToSymbol[id] = symbol;
      }
    });

    return result;
  }

  async getPriceHistory(coin: string, timeRange: TimeRange = 'day'): Promise<MarketChartRange | undefined> {
    const id = this.symbolToInfo[coin.toLowerCase()]?.id;
    if (id) {
      const { days, interval } = timeRangeToDays[timeRange];
      const result = await this.getJson(`coins/${id}/market_chart`, {
        id,
        vs_currency: 'usd',
        days,
        interval,
      });

      return result;
    } else return;
  }

  async getPrices(coins: Array<string>, currencies: Array<PriceSymbol> = ['USD', 'BTC']): Promise<CoinPrices> {
    const ids = compact(coins.map(symbol => this.symbolToInfo[symbol.toLowerCase()]?.id));
    const result: SimplePriceResponse<PriceSymbol> = await this.getJson('simple/price', {
      ids,
      vs_currencies: currencies,
    });

    return toInterface(result, this.idToSymbol);
  }

  async getDetailedPrices(
    coins: Array<string>,
    currencies: Array<PriceSymbol> = ['USD', 'BTC']
  ): Promise<Array<DetailedPrice>> {
    if (Object.keys(this.idToSymbol).length == 0) await this.getCoinList();
    const rawResults = await Promise.all(coins.map(coin => this.getPrice(coin, currencies)));
    return compact(rawResults);
  }

  async getPrice(coin: string, currencies: Array<PriceSymbol> = ['USD', 'BTC']): Promise<DetailedPrice | undefined> {
    const id = this.symbolToInfo[coin.toLowerCase()]?.id;
    const defaultOptions = {
      tickers: false,
      market_data: true,
      community_data: false,
      developer_data: false,
      sparkline: false,
    };
    if (id) {
      return await this.getJson(`coins/${id}`, defaultOptions);
    } else return;
  }

  extractJsonData(json: Array<unknown> | unknown) {
    return json;
  }
}
