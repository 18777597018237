import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';

// @supports -webkit-appearance is a chrome specific hack for the rendering of border buttons
const InnerButton = styled.div<{ large?: boolean }>`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 22px;
  padding: ${props => (props.large ? '13px 24px 11px 24px' : '7px 15px 5px 15px')};

  @supports (-webkit-appearance: none) {
    padding: ${props => (props.large ? '11px 24px' : '6px 15px')};
  }

  ${props => (props.large ? 'font-size: 16px' : '')};
`;

const GradientButton = styled.button`
  border-radius: 24px;
  display: inline-block;
  padding: 1px;

  position: relative;
  text-align: center;
  font-size: 12px;

  color: ${colors.ui.grey5};
  & + & {
    margin-left: 15px;
  }

  &.deselected {
    font-weight: normal;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      color: black;
    }
  }

  &.selected,
  &.normal-button,
  &.disabled {
    cursor: default;
    font-weight: bold;
    background-image: linear-gradient(to bottom, ${colors.gradient.primary}, ${colors.gradient.secondary});
    color: black;

    ${InnerButton} {
      background-color: white;
    }

    padding: 1px 1px 0.2px 1px;

    @supports (-webkit-appearance: none) {
      padding: 1px;
    }
  }

  &.disabled {
    font-weight: lighter;
    background-color: ${colors.ui.grey5};
  }

  &.normal-button,
  &.disabled {
    cursor: pointer;
    display: inline-block;
    margin: 1px;

    &:hover {
      padding: 2px 2px 3px 2px;
      margin: 0px;

      @supports (-webkit-appearance: none) {
        padding: 2px;
      }
    }
  }

  &.disabled {
    cursor: default;
    background-image: none;
    color: ${colors.ui.grey5};

    &:hover {
      padding: 1px;
      margin: 1px;
    }
  }
`;

type ButtonProps = {
  content: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
  disabled?: boolean;
  large?: boolean;
};

const GradientBorderButton = (props: ButtonProps) => {
  const classes = [
    props.selected === true ? 'selected' : null,
    props.selected === false ? 'deselected' : null,
    props.disabled === true ? 'disabled' : null,
    props.selected === undefined ? 'normal-button' : null,
  ];
  return (
    <GradientButton
      onClick={event => {
        if (props.onClick) props.onClick(event);
      }}
      className={classes.join(' ')}
    >
      <InnerButton large={props.large}>{props.content}</InnerButton>
    </GradientButton>
  );
};

export { GradientBorderButton, GradientButton, InnerButton };
