import colors from 'app/styles/colors';

export const xAxisTickCount = 7;

export const strokeGradientId = 'gradient1';

export const shadowId = 'dropShadow1';

export const lineStyle = {
  strokeLineJoin: 'round',
  strokeWidth: 4,
  stroke: `url(#${strokeGradientId})`,
  fill: 'none',
  filter: `url(#${shadowId})`,
};

export const barStyle = {
  fill: colors.graphs.bars,
  strokeWidth: 0,
};

export const xAxisStyle = {
  line: {
    display: 'none',
  },
  text: {
    stroke: 'none',
    fill: colors.graphs.axisLabels,
    fontWeight: 300,
    fontSize: '12px',
  },
};

export const verticalLinesStyle = {
  stroke: colors.graphs.verticalLine,
};

export const crosshairStyle = {
  line: {
    background: colors.graphs.crosshair,
  },
  box: {
    display: 'none',
  },
};
