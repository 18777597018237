import * as React from 'react';
import { PieChart, Pie, Cell, PieLabelRenderProps } from 'recharts';

import colors from 'app/styles/colors';

const GradientDefinitions = (
  <defs>
    <linearGradient id="donut-gradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor={colors.gradient.primary} stopOpacity={1.0} />
      <stop offset="100%" stopColor={colors.gradient.secondary} stopOpacity={1.0} />
    </linearGradient>
  </defs>
);

export type PieData = {
  name: string;
  value: number;
};

type Props = {
  width: number;
  height: number;
  data: Array<PieData>;
  activeIndex: number | undefined;
  onMouseEnter: (event: MouseEvent, index: number) => void;
  colorScale: Array<string>;
  renderLabel: (object: PieLabelRenderProps) => React.ReactNode;
};

const DonutChart = (props: Props) => (
  <PieChart width={props.width} height={props.height}>
    {GradientDefinitions}
    <Pie
      data={props.data}
      isAnimationActive={false}
      dataKey="value"
      nameKey="name"
      cx="50%"
      cy="50%"
      innerRadius="65%"
      outerRadius="92%"
      paddingAngle={1}
      onMouseEnter={props.onMouseEnter}
    >
      {props.data.map((entry, index) => (
        <Cell key={entry.name} fill={props.colorScale[index]} />
      ))}
    </Pie>
    <Pie
      data={props.data}
      isAnimationActive={false}
      dataKey="value"
      nameKey="name"
      cx="50%"
      cy="50%"
      innerRadius="63%"
      outerRadius="100%"
      paddingAngle={1}
      labelLine={false}
      label={props.renderLabel}
      activeIndex={props.activeIndex}
      onMouseEnter={props.onMouseEnter}
    >
      {props.data.map((entry, index) => (
        <Cell
          key={entry.name}
          strokeWidth={0}
          fill={index === props.activeIndex ? 'url(#donut-gradient)' : 'transparent'}
        />
      ))}
    </Pie>
  </PieChart>
);

export { DonutChart };
