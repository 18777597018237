import { UserActions } from 'app/store/modules/user/actions';
import { SettingsData } from 'app/pages/settings';
import { JsonError } from 'app/types/auth';

export type UserState = {
  fetchingSettings: boolean;
  updatingSettings: boolean;

  updateFailed: boolean;
  errorMessage?: string;
  errorPayload: Array<JsonError>;

  settings: SettingsData;
};

export const initialState: UserState = {
  fetchingSettings: false,
  updatingSettings: false,

  updateFailed: false,
  errorMessage: undefined,
  errorPayload: [],

  settings: {
    totalCashInvested: undefined,
    allowedSites: [],
    credentials: [],
    addresses: [],
    walletHoldings: [],
  },
};

export const reducer = (state: UserState = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case 'user/LOAD_SETTINGS':
    case 'user/UPDATE_SETTINGS':
      return { ...state, settings: action.payload };
    case 'user/ERROR':
      return {
        ...state,
        updatingSettings: false,
        updateFailed: true,
        errorMessage: action.payload.message,
        errorPayload: action.payload.json.data,
      };
    case 'user/LOADING':
      return { ...state, [action.payload.key]: action.payload.isLoading };
    default:
      return state;
  }
};
