import { every, values, isNull, mapValues } from 'lodash';

import { Validator, Field, Errors, SingleParentState } from 'app/types/typeform';

function copyKeys(obj: SingleParentState): Errors {
  return mapValues(obj, () => null);
}

function filterNull<T>(list: Array<T | undefined>): Array<T> {
  const output: Array<T> = [];
  for (let i = 0; i < list.length; i += 1) {
    const existing = list[i];
    if (existing) output.push(existing);
  }
  return output;
}

export const compose =
  (...maybeValidators: Array<Validator | undefined>): Validator =>
  (state: SingleParentState): Promise<Errors> => {
    const noErrors = copyKeys(state);
    const validators: Array<Validator> = filterNull(maybeValidators);

    return validators
      .reverse()
      .reduce(
        (errorsPromise: Promise<Errors>, validator: Validator) =>
          errorsPromise.then(errors => (every(values(errors), isNull) ? validator(state) : errors)),
        Promise.resolve(noErrors)
      );
  };

export const validateRequiredNonEmpty =
  (fields: Array<Field>): Validator =>
  (state: SingleParentState) => {
    const errors = copyKeys(state);
    fields.forEach(field => {
      if (field.required && state[field.id].length === 0) errors[field.id] = 'Field can not be empty';
    });
    return Promise.resolve(errors);
  };

export const validateFloatKey =
  (key: string): Validator =>
  (data: SingleParentState) => {
    const output = copyKeys(data);
    if (data[key] && data[key].length > 0) {
      const numeric = parseFloat(data[key]);
      output[key] = isNaN(numeric) ? 'please enter a number with no extra characters' : null;
    }
    return Promise.resolve(output);
  };

export const validateFloatKeys = (keys: Array<string>): Validator => compose(...keys.map(validateFloatKey));
