import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';

import * as balance from 'app/store/modules/balance/actions';
import * as auth from 'app/store/modules/auth/actions';
import * as api from 'app/store/modules/api/actions';
import { CoinHistory, CoinPrices } from 'app/types/interface';
import { CoinInfo } from 'app/types/api';
import { Header } from 'app/pages/home/header';
import { Footer } from 'app/pages/home/footer';
import { Floater } from 'app/pages/home/floater';
import { Jumbotron } from 'app/pages/home/jumbotron';
import { Features } from 'app/pages/home/features';
import { HeatMap } from 'app/pages/home/heat_map';
import colors from 'app/styles/colors';
import laptopUrl from 'app/assets/images/home.laptop.normal.png';
import phoneUrl from 'app/assets/images/home.phone.normal.png';
import { FeatureDetail, DetailSection } from 'app/pages/home/feature_detail';
import { media, NonDesktop } from 'app/utils/responsive';
import t from 'app/utils/i18n';
import singlePageAnimationUrl from 'app/assets/images/coinholio.singlepage.01.webm';
import sidebarAnimationUrl from 'app/assets/images/coinholio.animation.02.webm';
import smartDesignUrl from 'app/assets/images/coinholio.smart.design.png';
import { State as GlobalState } from 'app/store/types';
import { CRYPTO_COMPARE_REFRESH_INTERVAL } from 'app/constants';

const coins = ['BTC', 'ETH', 'XRP', 'XMR', 'LINK'];

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 1200px;
  min-height: 800px;

  > img {
    pointer-events: none;
  }

  .laptop-image {
    position: absolute;
    right: 30px;
    top: 138px;
    z-index: 2;
    height: 40%;
    max-height: 590px;
  }

  .phone-image {
    position: absolute;
    right: 0px;
    top: 420px;
    height: 35%;
    z-index: 3;
    max-height: 516px;
  }

  ${media.nonDesktop} {
    min-width: auto;
    width: 100%;
    margin: 0px auto;
    flex: 1 1 auto;

    > img {
      display: none;
    }
  }
`;

const BoxWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 960px;
  left: 50%;
  z-index: -1;

  ${media.nonDesktop} {
    display: none;
  }
`;

const FloaterWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 820px;
  pointer-events: none;

  ${media.nonDesktop} {
    display: none;
  }
`;

const BackgroundFade = styled.div`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 300px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;

  ${media.nonDesktop} {
    display: none;
  }
`;

const HorizontalBar = styled.div`
  width: 100%;
  height: 10px;
  background-image: linear-gradient(to right, ${colors.gradient.primary}, ${colors.gradient.secondary});
  margin-top: 436px;

  ${media.nonDesktop} {
    margin-top: 100px;
  }
`;

const Details = styled.div`
  margin: 0px 80px;
  height: 800px;

  ${media.nonDesktop} {
    margin: 100px 0px;
  }
`;

type Props = {
  coinPrices: CoinPrices;
  coinInfo: CoinInfo;
  allCoinsData: Array<CoinHistory>;
  onDisplay: () => void;
  reloadHistoricalData: () => void;
  updatePrices: () => void;
  onLoginOrDashboard: () => void;
  onRegister: () => void;
  loggedIn: boolean;
  isLoading: boolean;
};

type State = {
  priceInterval: number | null;
  heatmapDataUpdateInterval: number | null;
  highlightCoin: string | null | undefined;
};

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      priceInterval: null,
      heatmapDataUpdateInterval: null,
      highlightCoin: null,
    };

    this.highlight = this.highlight.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.state.highlightCoin == null && nextProps.allCoinsData.length === coins.length) {
      this.setState({ highlightCoin: nextProps.allCoinsData[0].coin });
    }
  }

  UNSAFE_componentWillMount() {
    this.props.onDisplay();
    this.setState({
      heatmapDataUpdateInterval: setInterval(() => this.props.reloadHistoricalData(), 60 * 1000),
      priceInterval: setInterval(() => this.props.updatePrices(), CRYPTO_COMPARE_REFRESH_INTERVAL),
    });
  }

  componentWillUnmount() {
    if (this.state.heatmapDataUpdateInterval) clearInterval(this.state.heatmapDataUpdateInterval);
    if (this.state.priceInterval) clearInterval(this.state.priceInterval);
    this.setState({ heatmapDataUpdateInterval: null, priceInterval: null });
  }

  highlight(coin: string | undefined) {
    this.setState({ highlightCoin: coin });
  }

  renderFeatureDetails() {
    const features: Array<DetailSection> = [
      {
        title: t(`home.details.detail1.title`),
        boldText: t('home.details.detail1.boldText'),
        text: t('home.details.detail1.text'),
        lastLine: t('home.details.detail1.lastLine'),
        link: t('home.details.detail1.linkText'),
        image: sidebarAnimationUrl,
      },
      {
        title: t(`home.details.detail2.title`),
        boldText: t('home.details.detail2.boldText'),
        text: t('home.details.detail2.text'),
        lastLine: t('home.details.detail2.lastLine'),
        link: t('home.details.detail2.linkText'),
        image: singlePageAnimationUrl,
      },
      {
        title: t(`home.details.detail3.title`),
        boldText: t('home.details.detail3.boldText'),
        text: t('home.details.detail3.text'),
        lastLine: t('home.details.detail3.lastLine'),
        link: t('home.details.detail3.linkText'),
        image: <img src={smartDesignUrl} alt={t(`home.details.detail3.title`)} />,
      },
    ];

    return (
      <Details>
        <FeatureDetail sections={features} onAction={this.props.onRegister} />
      </Details>
    );
  }

  render() {
    return (
      <Wrapper>
        <BoxWrapper>
          <HeatMap data={this.props.allCoinsData} onHover={this.highlight} />
        </BoxWrapper>
        <img src={laptopUrl} alt="coinholio on a laptop" className="laptop-image" />
        <img src={phoneUrl} alt="coinholio on a phone" className="phone-image" />
        {this.state.highlightCoin && this.props.coinPrices[this.state.highlightCoin] && (
          <FloaterWrapper>
            <Floater
              coin={this.state.highlightCoin}
              coinPrices={this.props.coinPrices}
              coinInfo={this.props.coinInfo}
              data={this.props.allCoinsData}
            />
          </FloaterWrapper>
        )}
        <BackgroundFade />
        <Header
          isLoading={this.props.isLoading}
          loggedIn={this.props.loggedIn}
          onRegister={this.props.onRegister}
          onLoginOrDashboard={this.props.onLoginOrDashboard}
        />
        <Jumbotron onAction={this.props.onRegister} />
        <HorizontalBar />
        <Features />
        <NonDesktop>
          <HorizontalBar />
        </NonDesktop>
        {this.renderFeatureDetails()}
        <Footer />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    coinInfo: state.api.coinInfo,
    coinPrices: state.balance.coinPrices,
    allCoinsData: state.balance.coinHistorical,
    loggedIn: !!state.auth.loggedIn,
    isLoading: !!(state.balance.loading.coinHistorical || state.balance.loading.coinPrices),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onRegister: () => dispatch(push('/dashboard?register=true')),
    onLoginOrDashboard: () => dispatch(push('/dashboard')),
    updatePrices: () => dispatch(balance.fetchCoinPrices(coins)),
    reloadHistoricalData: () => {
      const promises = [
        dispatch(balance.fetchAllCoinsHistorical(coins, 'USD', 'hour')),
        dispatch(balance.fetchCoinPrices(coins)),
      ];

      return Promise.all(promises);
    },
    onDisplay: () => {
      dispatch(auth.checkLoginState());

      const promises = [
        dispatch(api.fetchCoinInfo()),
        dispatch(balance.fetchAllCoinsHistorical(coins, 'USD', 'hour')),
        dispatch(balance.fetchCoinPrices(coins)),
      ];

      return Promise.all(promises);
    },
  };
};

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export { HomeContainer, Home };
