import styled from 'styled-components';

const style = `
  height: 20px;
  width: 20px;
  vertical-align: middle;
`;

const CoinIcon = styled.img`
  ${style};
`;
const DummyIcon = styled.div`
  ${style} display: inline-block;
`;

export { CoinIcon, DummyIcon };
