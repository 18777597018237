import * as d3 from 'd3-format';

export type Kind = 'BTC' | 'USD' | '%' | 'RAW' | 'UNITLESS';

// possible choices for satoshis: サ し ㋛ シ 里 SAT
// see: https://en.bitcoin.it/wiki/Satoshi_(unit)

// possible choices for bitcoin: Ƀ ฿ BTC
// also: http://fontawesome.io/icon/btc/

const satoshiDecimals = 100000000;
const bitcoinPrefix = '';
const satoshiPrefix = '';
const bitcoinSuffix = ' BTC';
const satoshiSuffix = ' SAT';
const centsPrefix = '¢';

const largeNumberFormatter = d3.format(',.4s');

const fullNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 8,
});

const smallNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatValue = (value: number): string => dollarFormatter.format(value);

export const formatLargeValue = (value: number): string => largeNumberFormatter(value);

export const formatHigherPrecision = (value: number): string => fullNumberFormatter.format(value);

export const formatDollars = (value: number): string => {
  const absValue = Math.abs(value);
  if (absValue >= 1000) {
    return `$${largeNumberFormatter(value)}`;
  } else if (absValue >= 1.0) {
    return `$${dollarFormatter.format(value)}`;
  } else {
    const cents = value * 100.0;
    return `${centsPrefix}${smallNumberFormatter.format(cents)}`;
  }
};

export const formatPercent = (value: number): string => `%${dollarFormatter.format(100.0 * value)}`;

export const formatBTC = (value: number): string => {
  const clipped = Math.round(value * satoshiDecimals) / satoshiDecimals;
  const absValue = Math.abs(clipped);
  if (absValue >= 1.0) {
    return `${bitcoinPrefix}${largeNumberFormatter(clipped)}${bitcoinSuffix}`;
  } else if (absValue >= 0.1) {
    return `${bitcoinPrefix}${smallNumberFormatter.format(clipped)}${bitcoinSuffix}`;
  } else if (absValue >= 0.00001) {
    const satoshis = clipped * satoshiDecimals;
    return `${satoshiPrefix}${largeNumberFormatter(satoshis)}${satoshiSuffix}`;
  } else {
    const satoshis = clipped * satoshiDecimals;
    return `${satoshiPrefix}${Math.round(satoshis)}${satoshiSuffix}`;
  }
};

export const kindToFormatter = (kind: Kind | string): ((value: number) => string) => {
  let formatter = formatValue;
  if (kind === 'BTC') formatter = formatBTC;
  else if (kind === 'USD') formatter = formatDollars;
  else if (kind === '%') formatter = formatPercent;
  else if (kind === 'UNITLESS') formatter = formatLargeValue;

  return formatter;
};
