import * as React from 'react';
import styled from 'styled-components';

const Bad = styled.div`
  width: 100%;
  height: 100%;
`;

type Props = {
  children: JSX.Element;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('from error boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Bad>Something went wrong.</Bad>;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
