import styled from 'styled-components';

const LabeledInput = styled.div`
  text-align: left;
  width: 100%;

  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: inline-block;
  }

  & + & {
    margin-top: 5px;
  }
`;

export { LabeledInput };
