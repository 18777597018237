import * as React from 'react';
import styled from 'styled-components';

import { CoinHistory } from 'app/types/interface';
import { smoothHistoricalData } from 'app/utils/data_transformers';

type Props = {
  data: Array<CoinHistory>;
  onHover?: (coin?: string) => void;
};

type State = {
  min: { [key: string]: number };
  max: { [key: string]: number };
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
`;

const Box = styled.div`
  min-width: 10px;
  min-height: 10px;
  flex-grow: 1;
  animation: fade-in 1s linear;
`;

class HeatMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { min: {}, max: {} };

    this.hoverCoin = this.hoverCoin.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.data.length) {
      const newMinMax = this.state;

      nextProps.data.forEach(({ coin, data }) => {
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;

        data.forEach(({ price: { value } }) => {
          min = Math.min(min, value);
          max = Math.max(max, value);
        });

        newMinMax.min[coin] = min;
        newMinMax.max[coin] = max;
      });

      this.setState(newMinMax);
    }
  }

  hoverCoin(coin: string) {
    return () => {
      if (this.props.onHover) this.props.onHover(coin);
    };
  }

  valueToColor(coin: string, value: number) {
    const base = this.state.max[coin] - this.state.min[coin];
    const percent = base ? (value - this.state.min[coin]) / base : 0;
    const adjustedPercent = (1 - percent) * 0.8;
    return `rgba(128, 128, 128, ${adjustedPercent})`;
  }

  render() {
    let contents;
    if (this.props.data.length) {
      const smoothData = smoothHistoricalData(this.props.data, 20);
      contents = smoothData.map(({ coin, data }, j) => {
        const totalTime = data.length * smoothData.length;
        const boxes = data.map(({ close }, i) => (
          <Box
            key={`box-${coin}-${i}`}
            style={{
              backgroundColor: this.valueToColor(coin, close),
              animationDuration: `${((i * j) / totalTime) * 2}s`,
            }}
          />
        ));
        return (
          <Column key={`column-${coin}`} onMouseOver={this.hoverCoin(coin)}>
            {boxes}
          </Column>
        );
      });
    }

    return <Wrapper>{contents}</Wrapper>;
  }
}

export { HeatMap };
