const dimensions = {
  home: {
    header: {
      height: 44,
      paddingVertical: 40,
      paddingHorizontal: 80,
    },
    bottom: {
      paddingHorizontal: 80,
      paddingTop: 150,
    },
  },
  header: {
    height: 68,
  },
  desktop: 1440,
  pieChart: {
    width: 200,
  },
  chartPanel: {
    width: 920,
    padding: {
      left: 71,
      right: 80,
    },
  },
  logoIcon: {
    height: 60,
    width: 60,
  },
  topBar: {
    height: 60,
  },
  sideBar: {
    width: 360,
    topBarWithInfo: 300,
    topBarWithoutInfo: 245,
  },
};

export default dimensions;
