import styled from 'styled-components';

const CoinName = styled.span`
  font-weight: bold;
  flex-grow: 10;
  text-align: left;
  vertical-align: middle;
`;

export default CoinName;
