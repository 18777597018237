import * as React from 'react';
import styled from 'styled-components';
import { find, findIndex, range, shuffle } from 'lodash';
import { PieLabelRenderProps } from 'recharts';

import { CoinPrices } from 'app/types/interface';
import { CoinBalances } from 'app/types/api';
import { formatPercent } from 'app/utils/formatter';
import dimensions from 'app/styles/dimensions';
import { DonutChart, PieData } from 'app/components/charts/donut_chart';

const Wrapper = styled.div`
  width: ${dimensions.pieChart.width}px;
  margin: 0px auto;
`;

type Props = {
  showBTC: boolean;
  coinPrices: CoinPrices;
  coinBalances: CoinBalances;
  onHighlightChange: (coin?: string) => void;
  highlightCoin: string | undefined;
};

type State = {
  data: Array<PieData>;
  colorScale: Array<string>;
  totalValue: number;
};

const randomGrays = (count: number) => {
  const indices = range(count);
  return shuffle(indices).map(index => {
    const v = Math.round(((index + 1) / count) * (256 / 2) + 100);
    return `rgb(${v}, ${v}, ${v})`;
  });
};

class HoldingsDonutChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.buildState(props);

    this.onPieEnter = this.onPieEnter.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  buildState(props: Props): State {
    if (!this.state) this.state = { data: [], colorScale: [], totalValue: 0 };

    if (Object.keys(props.coinPrices).length) {
      const coins = Object.keys(props.coinBalances).filter(coin => coin in props.coinPrices);
      const data = coins.map(coin => {
        const amount = props.coinBalances[coin];
        let price = props.coinPrices[coin][this.props.showBTC ? 'BTC' : 'USD'].current.price;
        if (coin === 'BTC' && this.props.showBTC) price = 1;

        return {
          name: coin,
          value: amount * price,
        };
      });
      data.sort((a, b) => (a.value > b.value ? -1 : 1));
      const totalValue = data.reduce((sum, { value }) => sum + value, 0);
      const colorScale =
        this.state.colorScale && this.state.colorScale.length === data.length
          ? this.state.colorScale
          : randomGrays(data.length);

      return { data, colorScale, totalValue };
    } else return { data: [], colorScale: [], totalValue: 0 };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState(this.buildState(nextProps));
  }

  onPieEnter(_data: unknown, index: number) {
    this.props.onHighlightChange(this.state.data[index].name);
  }

  renderLabel(data: PieLabelRenderProps): React.ReactNode {
    const { name, cx, cy } = data;
    let label: React.ReactNode = null;
    const found = find(this.state.data, ['name', this.props.highlightCoin]);
    if (found) {
      const highlightCoinValue = found.value;
      label = (
        <text x={cx} y={cy} fill="black" textAnchor="middle" dominantBaseline="middle">
          <tspan fontWeight="bold">{this.props.highlightCoin || ''}</tspan>
          <tspan>: {formatPercent(highlightCoinValue / this.state.totalValue)}</tspan>
        </text>
      );
    }

    return this.props.highlightCoin === name ? label : null;
  }

  render() {
    if (this.state.data.length <= 0) return <div>loading</div>;
    else {
      return (
        <Wrapper>
          <DonutChart
            width={dimensions.pieChart.width}
            height={dimensions.pieChart.width}
            data={this.state.data}
            activeIndex={findIndex(this.state.data, ['name', this.props.highlightCoin]) || 0}
            onMouseEnter={this.onPieEnter}
            colorScale={this.state.colorScale}
            renderLabel={this.renderLabel}
          />
        </Wrapper>
      );
    }
  }
}

export { HoldingsDonutChart };
