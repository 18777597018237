import { difference, uniq, fromPairs, map, toPairs } from 'lodash';

import { Balance, CoinBalances, CoinHoldings } from 'app/types/api';
import { CoinOrigin, BalanceActions, LoadingKey } from 'app/store/modules/balance/actions';
import { reduceLoading, wrapArray } from 'app/store/modules/utils';
import { CoinHistory, CoinPrices } from 'app/types/interface';
import { Token as EthToken } from '../../../types/ethplorer';

export type BalanceState = {
  balances: CoinBalances;
  coinHoldings: CoinHoldings;
  loading: Partial<Record<LoadingKey, boolean>>;
  coinPrices: CoinPrices;
  coinHistorical: Array<CoinHistory>;
  loadingCoins: Record<string, boolean>;
  ethereumBalances: { ETH?: number } & Record<string, number>;
  disabledCoins: Array<string>;
  coinDataOrigin: Record<string, CoinOrigin | undefined>;
  trackedCoins: Array<string>;
  visibleCoins: Array<string>;
  spamCoins: Record<string, EthToken['tokenInfo']>;
};

export const initialState: BalanceState = {
  balances: {},
  coinHoldings: {},
  loading: {},
  coinPrices: {},
  coinHistorical: [],
  loadingCoins: {},
  ethereumBalances: {},
  disabledCoins: [],
  coinDataOrigin: {},
  trackedCoins: [],
  visibleCoins: [],
  spamCoins: {},
};

const mergeBalances = (
  otherState: Array<Balance>,
  balances: CoinBalances,
  shouldInclude: (balance: Balance) => boolean = () => true
) =>
  otherState.forEach((coin: Balance) => {
    if (shouldInclude(coin)) {
      if (balances[coin.currency]) balances[coin.currency] += coin.balance;
      else balances[coin.currency] = coin.balance;
    }
  });

export const reducer = (state: BalanceState = initialState, action: BalanceActions): BalanceState => {
  switch (action.type) {
    case 'balance/COIN_PRICES':
      return { ...state, coinPrices: action.payload };
    case 'balance/SET_COIN_ORIGINS':
      return {
        ...state,
        coinDataOrigin: {
          ...state.coinDataOrigin,
          ...Object.fromEntries(action.payload),
        },
      };
    case 'balance/BALANCES': {
      const balances = { BTC: 0.0 };
      const wrappedPayload = wrapArray(action.payload);
      mergeBalances(wrappedPayload, balances, coin => coin.balance > 0.00001 || coin.balance < -0.00001);

      const ethereumBalances: Array<Balance> = map(
        toPairs(state.ethereumBalances),
        ([currency, balance]: [string, number]) => {
          return { balance, currency, source: 'ethplorer' };
        }
      );
      mergeBalances(ethereumBalances, balances);

      return { ...state, balances, loading: { ...state.loading, balances: false } };
    }
    case 'balance/COIN_HISTORICAL':
      return { ...state, coinHistorical: action.payload };
    case 'balance/DISABLE_COINS': {
      const disabledCoins = uniq(state.disabledCoins.concat(action.payload));
      const visibleCoins = difference(state.trackedCoins, disabledCoins);
      const visibleDisabledCoins = disabledCoins.filter(coin => {
        return !(coin in state.spamCoins);
      });

      return { ...state, disabledCoins: visibleDisabledCoins, visibleCoins };
    }
    case 'balance/CALCULATE_COIN_HOLDINGS': {
      const { coinPrices, balances } = state;

      const defaultEmpty = { BTC: { current: { price: 0 } }, USD: { current: { price: 0 } } };
      const coinHoldings: CoinHoldings = fromPairs(
        toPairs(balances).map(([coin, balance]) => {
          const coinPrice = coinPrices[coin] || defaultEmpty;
          return [coin, { BTC: balance * coinPrice.BTC.current.price, USD: balance * coinPrice.USD.current.price }];
        })
      );

      const trackedCoins = Object.keys(coinHoldings);
      const visibleCoins = difference(trackedCoins, state.disabledCoins);

      return { ...state, coinHoldings, trackedCoins, visibleCoins };
    }
    case 'balance/TRACK_COINS':
      return { ...state, trackedCoins: action.payload };
    case 'balance/ETHEREUM_BALANCES': {
      const wrappedPayload = wrapArray(action.payload);
      let ethereumBalances = {};
      if (wrappedPayload.length) {
        const balances: BalanceState['ethereumBalances'] = { ETH: 0.0 };
        const spamCoins: BalanceState['spamCoins'] = {};

        wrappedPayload.forEach(addressInfo => {
          balances.ETH = balances.ETH == undefined ? addressInfo.ETH.balance : balances.ETH + addressInfo.ETH.balance;

          if (addressInfo.tokens && addressInfo.tokens.length) {
            addressInfo.tokens.forEach(token => {
              if (!token.tokenInfo.symbol) return;

              const coin = token.tokenInfo.symbol.toUpperCase();
              const decimals = parseInt(token.tokenInfo.decimals.toString(), 10);
              const amount = token.balance / 10 ** decimals;

              const spammy = (token.tokenInfo.holdersCount ?? 0) < 50_000;
              if (spammy) {
                console.log('evicting spam token:', token);
                spamCoins[coin] = token.tokenInfo;
              }

              if (balances[coin]) balances[coin] += amount;
              else balances[coin] = amount;
            });
          }
        });

        ethereumBalances = balances;
      }

      return { ...state, ethereumBalances, loading: { ...state.loading, ethereumBalances: false } };
    }
    case 'balance/LOADING_COINS':
      return { ...state, loadingCoins: action.payload };
    case 'balance/LOADING':
      return reduceLoading<LoadingKey, BalanceState>(state, action.payload);
    default:
      return state;
  }
};
