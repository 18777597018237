import styled from 'styled-components';

import colors from 'app/styles/colors';
import { media } from 'app/utils/responsive';

export const FormPanel = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.nonDesktop} {
    margin-top: 50px;
  }

  h1 {
    font-size: 32px;
    color: ${colors.text.black};
    font-weight: bold;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 16px;
    color: ${colors.text.black};
    opacity: 0.6;
    margin-bottom: 40px;
  }
`;
