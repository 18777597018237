import { CoinData, WalletMapping, Order } from 'app/types/api';
import services from 'app/services';
import { State } from 'app/store/types';
import { loadingActionBuilder } from 'app/store/modules/utils';
import { DetailedPrice } from 'app/types/coingecko';

export type LoadingKey = 'coinInfo' | 'walletOverTime' | 'orders' | 'overall';

export type FetchCoinInfoAction = { type: 'api/FETCH_COIN_INFO'; payload: Array<CoinData> };
export type UpdateCoinInfo = { type: 'api/UPDATE_COIN_INFO'; payload: Array<DetailedPrice> };
export type FetchWalletOverTimeAction = { type: 'api/FETCH_WALLET_OVER_TIME'; payload: Array<WalletMapping> };
export type FetchOrdersAction = { type: 'api/FETCH_ORDERS'; payload: Array<Order> };
export type LoadingAction = { type: 'api/LOADING'; payload: { key: LoadingKey; isLoading: boolean } };

export type ApiActions =
  | FetchCoinInfoAction
  | FetchWalletOverTimeAction
  | FetchOrdersAction
  | LoadingAction
  | UpdateCoinInfo;

export const loadingAction = loadingActionBuilder<'api/LOADING', LoadingKey>('api/LOADING');

function wire<Payload>(
  key: LoadingKey,
  serviceCall: (getState: () => State) => Promise<Payload>,
  actionBuilder: (payload: Payload) => ApiActions
): (dispatch: Dispatch, getState: () => State) => Promise<void> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(loadingAction(key, { isLoading: true }));
    try {
      const result = await serviceCall(getState);
      dispatch(actionBuilder(result));
    } catch (e) {
      console.log(e);
    }
    dispatch(loadingAction(key, { isLoading: false }));
  };
}

export const setLoadingOverall = (payload: { isLoading: boolean }) => loadingAction('overall', payload);

export const fetchCoinInfo = () =>
  wire(
    'coinInfo',
    async () => {
      const [regularInfo, geckoList] = await Promise.all([
        services.apiService.getCoinInfo(),
        services.coinGeckoService.getCoinList(),
      ]);

      const converted = geckoList.map(({ name, symbol }) => ({
        symbol: symbol.toUpperCase(),
        name,
        imageUrl: undefined,
      }));

      return [...regularInfo, ...converted];
    },
    payload => ({ type: 'api/FETCH_COIN_INFO', payload })
  );

export const fetchWalletOverTime = () =>
  wire(
    'walletOverTime',
    () => services.apiService.getWalletOverTime(),
    payload => ({ type: 'api/FETCH_WALLET_OVER_TIME', payload })
  );

export const fetchOrders = () =>
  wire(
    'orders',
    getState => {
      const {
        auth: { userId, authToken },
      } = getState();
      if (userId && authToken) return services.apiService.getOrders(userId, authToken);
      else throw new Error('not logged in');
    },
    payload => ({ type: 'api/FETCH_ORDERS', payload })
  );
