import styled from 'styled-components';

import { media } from 'app/utils/responsive';
import colors from 'app/styles/colors';

const ErrorMessage = styled.div`
  color: ${colors.red};
  font-size: 12px;
  margin-top: 5px;
  height: 15px;

  ${media.nonDesktop} {
    font-size: 11px;
    margin-top: 1px;
    height: 11px;
  }
`;

export { ErrorMessage };
