import * as React from 'react';
import styled from 'styled-components';

import dimensions from 'app/styles/dimensions';
import colors from 'app/styles/colors';
import { CoinholioLogoIcon } from 'app/components/coinholio_logo_icon';
import CoinholioTextImage from 'app/assets/images/logo.text.black.small.svg';
import { DropDown, Contents as DropDownWrapper, Opt } from 'app/components/drop_down';
import Link from 'app/components/navigationLink';

const Wrapper = styled.div`
  height: ${dimensions.topBar.height}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.black};
  align-items: center;
  position: relative;

  ${DropDownWrapper} {
    background-color: transparent;
    color: ${colors.ui.grey5};
    width: 100px;
    font-size: 30px;
    outline: 0;
    align-self: stretch;
  }
`;

const Title = styled.div`
  flex-grow: 2;
  margin: 0px 15px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.2px;
`;

type Props = {
  isLoading: boolean;
  loggedIn: boolean;
  onLogout: () => void;
  onSettingsClick: () => void;
  onSettingsEnd: () => void;
  onLogoClick: () => void;
};

const TopBar = (props: Props) => {
  const options: Array<Opt> = [
    { key: 'Settings', onClick: props.onSettingsClick },
    { key: 'Logout', onClick: props.onLogout },
  ];

  return (
    <Wrapper>
      <CoinholioLogoIcon isLoading={props.isLoading} onClick={props.onLogoClick} />
      <Title>
        <Link to="/">
          <CoinholioTextImage />
        </Link>
      </Title>
      <DropDown options={options} firstLine="&#x22EE;" />
    </Wrapper>
  );
};

export { TopBar };
