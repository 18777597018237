import * as React from 'react';
import styled from 'styled-components';

import { media } from 'app/utils/responsive';

const border = '20px';
const nonDesktopBorder = '10px';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  min-height: 500px;
  z-index: 200;

  ${media.desktop} {
    padding: ${border};
    top: ${border};
    bottom: ${border};
    right: ${border};
    left: ${border};
    border-radius: ${border};
  }

  ${media.nonDesktop} {
    padding: ${nonDesktopBorder};
    top: ${nonDesktopBorder};
    bottom: ${nonDesktopBorder};
    right: ${nonDesktopBorder};
    left: ${nonDesktopBorder};
    border-radius: ${nonDesktopBorder};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

type Props = {
  children?: React.ReactNode;
};

export const FullScreenFixedPanel = (props: Props) => (
  <div>
    <Wrapper>
      <InnerWrapper>{props.children}</InnerWrapper>
    </Wrapper>
  </div>
);
