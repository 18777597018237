import styled from 'styled-components';

import { media } from 'app/utils/responsive';
import colors from 'app/styles/colors';

export const DeleteButton = styled.button`
  color: ${colors.red};
  padding: 5px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: transparent;
  text-align: center;

  &:after {
    content: '×';
  }

  ${media.nonDesktop} {
    height: auto;
    width: auto;
  }
`;

export const Table = styled.table`
  width: 100%;

  ${media.nonDesktop} {
    font-size: 12px;
  }

  td {
    vertical-align: middle;
  }

  thead {
    color: ${colors.ui.grey5};
    font-size: 14px;

    tr {
      border-bottom: 1px solid ${colors.ui.grey3};
    }

    th {
      padding: 0px 5px;
      padding-bottom: 7px;
      vertical-align: middle;

      ${media.nonDesktop} {
        font-size: 12px;
        padding: 2px;
        padding-bottom: 3px;
      }
    }
  }

  tbody tr:nth-child(odd) {
    background-color: ${colors.ui.grey2};
  }

  tbody tr:hover {
    background-color: ${colors.ui.grey3};

    ${DeleteButton} {
      opacity: 1;
    }
  }
`;

export const Tr = styled.tr`
  ${media.nonDesktop} {
    padding: 2px;
    margin: 2px;
  }

  ${media.desktop} {
    padding: 5px;
    margin: 5px;

    ${DeleteButton} {
      opacity: 0;
    }
  }
`;
