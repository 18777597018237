export const NBSP = '\u00A0';

export const links = {
  linkedin: 'https://wwww.linkedin.com/company/originate',
  twitter: 'https://twitter.com/OriginateLabs',
};

// cryptocompare keeps making their rate limits stricter, so we bump to a minute to hopefully
// last us a bit longer
// see here: https://min-api.cryptocompare.com/stats/rate/limit
export const CRYPTO_COMPARE_REFRESH_INTERVAL = 30 * 1000;

/**
 * How often we should check the price when the app is in the background
 */
export const BACKGROUNDED_APP_PRICE_CHECK_RATE = 1 / 10;

export default links;
