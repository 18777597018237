import * as React from 'react';

import { GradientBorderButton } from 'app/components/gradient_border_button';

type Props = {
  children: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  selected?: boolean;
};

export const ConfirmButton = (props: Props) => (
  <GradientBorderButton content={props.children} onClick={props.onClick} large disabled={props.disabled} />
);

export const AddButton = (props: Props) => (
  <GradientBorderButton content={props.children} onClick={props.onClick} disabled={props.disabled} />
);

export const SkipButton = (props: Props) => (
  <GradientBorderButton content={props.children} onClick={props.onClick} selected={false} />
);

export const BackButton = (props: Props) => (
  <GradientBorderButton content={props.children} onClick={props.onClick} selected={false} disabled={props.disabled} />
);
