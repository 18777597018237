import * as React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';

import { SettingsContainer } from 'app/pages/settings';
import { TopInfo } from 'app/components/sidebar/top_info';
import { CoinRow } from 'app/components/sidebar/coin_row';
import { media } from 'app/utils/responsive';
import { CoinHistory, CoinPrices, PriceSymbol } from 'app/types/interface';
import { CoinBalances, CoinInfo } from 'app/types/api';
import dimensions from 'app/styles/dimensions';
import { TopBar } from 'app/components/top_bar';
import { ToggleButtons } from 'app/components/toggle_buttons';
import colors from 'app/styles/colors';
import { Table } from 'app/components/sidebar/table';
import { MiniGraphRow } from 'app/components/sidebar/mini_graph_row';
import { NonDesktop, Desktop } from 'app/utils/responsive';

const Wrapper = styled.div`
  position: relative;
  width: ${dimensions.sideBar.width}px;
  color: ${colors.text.black};

  ${media.nonDesktop} {
    width: 100%;
  }
`;

const FixedTop = styled.div<{ settingsMode?: boolean; hideInfo?: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  height: ${props =>
    props.settingsMode
      ? dimensions.topBar.height
      : props.hideInfo
      ? dimensions.sideBar.topBarWithoutInfo
      : dimensions.sideBar.topBarWithInfo}px;
  background-color: ${colors.ui.grey2};

  ${media.desktop} {
    right: auto;
    width: ${dimensions.sideBar.width}px;
  }

  ${media.nonDesktop} {
    position: sticky;
    top: 0;
    z-index: 20;
  }
`;

const SettingsWrapper = styled.div`
  margin-top: ${dimensions.topBar.height}px;
  padding-top: 15px;
`;

const ToggleWrapper = styled.div`
  padding: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

export type Props = {
  fetchHistoricalData?: (coin: string, toSymbol: PriceSymbol) => void;
  historicalData: Array<CoinHistory>;
  totalCashInvested?: number;
  coinPrices: CoinPrices;
  coinBalances: CoinBalances;
  coinInfo: CoinInfo;
  disabledCoins: Array<string>;
  highlightCoin?: string;
  toSymbol: PriceSymbol;
  loggedIn: boolean;
  loading: boolean;
  loadingCoins: Record<string, boolean>;
  onMouseEnter: (event: string) => void;
  onMouseLeave: (event?: string) => void;
  toggleBTC: () => void;
  onLogout: () => void;
  reloadData: () => void;
};

type State = {
  coins: Array<string>;
  editingSettings: boolean;
  collapseMiniGraph: boolean;
};

class SideBar extends React.Component<Props, State> {
  static defaultProps = {
    onMouseLeave: () => {},
    onMouseEnter: () => {},
    collapseMiniGraph: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = { coins: Object.keys(props.coinBalances), editingSettings: false, collapseMiniGraph: true };

    this.coinPrice = this.coinPrice.bind(this);
    this.dollarValue = this.dollarValue.bind(this);
    this.setSettingsModeOn = this.setSettingsModeOn.bind(this);
    this.setSettingsModeOff = this.setSettingsModeOff.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
  }

  onMouseEnter(coin: string) {
    if (coin === this.props.highlightCoin) this.setState({ collapseMiniGraph: !this.state.collapseMiniGraph });
    this.props.onMouseEnter(coin);
  }

  setSettingsModeOn() {
    this.setState({ editingSettings: true });
  }

  setSettingsModeOff() {
    this.setState({ editingSettings: false }, () => {
      this.props.reloadData();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({ coins: Object.keys(nextProps.coinBalances) });
  }

  sortedCoins() {
    return this.state.coins.slice().sort((a, b) => {
      if (this.props.disabledCoins.indexOf(a) >= 0) return 1;
      else if (this.props.disabledCoins.indexOf(b) >= 0) return -1;
      else return this.dollarValue(a) > this.dollarValue(b) ? -1 : 1;
    });
  }

  coinPrice(coin: string): number {
    const symbol = this.props.toSymbol;
    const price = this.props.coinPrices[coin] ? this.props.coinPrices[coin][symbol].current.price : 0;
    if (price) return coin === 'BTC' && symbol === 'BTC' ? 1 : price;
    else return 0;
  }

  dollarValue(coin: string) {
    const amount = this.props.coinBalances[coin];
    const currentPrice = this.coinPrice(coin);
    return amount * currentPrice;
  }

  lookupCoinInfo(coin: string) {
    return find(this.props.coinInfo, ['symbol', coin]);
  }

  renderRows() {
    if (this.state.coins.length > 0) {
      return this.sortedCoins().map(coin => {
        const coinInfo = this.lookupCoinInfo(coin);
        const isDisabled = this.props.disabledCoins.indexOf(coin) >= 0;
        const shouldHide = (this.props.toSymbol === 'BTC' && coin === 'BTC') || isDisabled;
        const isHighlighted = this.props.highlightCoin === coin;

        return (
          <React.Fragment key={`fragment-coin-${coin}`}>
            <CoinRow
              key={`coin-${coin}`}
              toSymbol={this.props.toSymbol}
              disabled={shouldHide}
              icon={coinInfo ? coinInfo.imageUrl : undefined}
              coin={coin}
              amount={this.dollarValue(coin)}
              count={this.props.coinBalances[coin]}
              currentPrice={this.coinPrice(coin)}
              data={this.props.coinPrices[coin]}
              onClick={() => this.onMouseEnter(coin)}
              onMouseEnter={() => this.onMouseEnter(coin)}
              onMouseLeave={() => this.props.onMouseLeave()}
              highlighted={isHighlighted}
              isLoading={!!this.props.loadingCoins[coin]}
              isExpanded={isHighlighted && !this.state.collapseMiniGraph}
            />
            <MiniGraphRow
              key={`coin-${coin}-minigraph`}
              fetchHistoricalData={() => this.props.fetchHistoricalData?.(coin, this.props.toSymbol)}
              shouldDisplay={isHighlighted && !this.state.collapseMiniGraph}
              coin={coin}
              coinBalances={this.props.coinBalances}
              historicalData={this.props.historicalData}
              highlightCoin={this.props.highlightCoin}
              toSymbol={this.props.toSymbol}
            />
            <tr key={`underline-${coin}`} className="underline">
              <NonDesktop>
                <td colSpan={5} />
              </NonDesktop>
              <Desktop>
                <td colSpan={5} />
              </Desktop>
            </tr>
          </React.Fragment>
        );
      });
    } else {
      return null;
    }
  }

  render() {
    return (
      <Wrapper>
        <FixedTop settingsMode={this.state.editingSettings} hideInfo={this.props.totalCashInvested === 0}>
          <TopBar
            onLogoClick={this.props.reloadData}
            isLoading={this.props.loading}
            loggedIn={this.props.loggedIn}
            onLogout={this.props.onLogout}
            onSettingsClick={this.setSettingsModeOn}
            onSettingsEnd={this.setSettingsModeOff}
          />
          {!this.state.editingSettings && (
            <div>
              <ToggleWrapper>
                <ToggleButtons
                  options={['USD', 'BTC']}
                  currentSelection={this.props.toSymbol}
                  onSelect={this.props.toggleBTC}
                />
              </ToggleWrapper>
              <TopInfo
                toSymbol={this.props.toSymbol}
                totalCashInvested={this.props.totalCashInvested}
                coinPrices={this.props.coinPrices}
                coinBalances={this.props.coinBalances}
                dollarValue={this.dollarValue}
                coinPrice={this.coinPrice}
              />
            </div>
          )}
        </FixedTop>
        {this.state.editingSettings ? (
          <SettingsWrapper>
            <SettingsContainer onSettingsEnd={this.setSettingsModeOff} />
          </SettingsWrapper>
        ) : (
          <Table hideInfo={this.props.totalCashInvested === 0}>
            <thead>
              <tr>
                <NonDesktop>
                  <th>&nbsp;</th>
                </NonDesktop>
                <th>coin</th>
                <th>holding</th>
                <th>value</th>
                <th>price</th>
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </Table>
        )}
      </Wrapper>
    );
  }
}

export { SideBar };
