import * as React from 'react';
import styled from 'styled-components';

import { NonDesktop } from 'app/utils/responsive';
import { CoinHistory, PriceSymbol } from 'app/types/interface';
import { CoinBalances } from 'app/types/api';
import { IndividualCoinChart } from 'app/components/charts/individual_coin_chart';

const Loading = styled.div`
  text-align: center;
`;

export type Props = {
  fetchHistoricalData?: () => void;
  historicalData: Array<CoinHistory>;
  coinBalances: CoinBalances;
  highlightCoin?: string;
  toSymbol: PriceSymbol;
  shouldDisplay: boolean;
  coin: string;
};

const everyFiveMinutes = () => Math.floor(Date.now() / (1000 * 5 * 60));

export const MiniGraphRow = (props: Props) => {
  React.useEffect(() => {
    if (props.shouldDisplay) props.fetchHistoricalData?.();
  }, [everyFiveMinutes(), props.shouldDisplay]);

  const dataExists = props.historicalData.findIndex(data => data.coin === props.coin) >= 0;
  const body = dataExists ? (
    <IndividualCoinChart
      crosshairIncludesValue
      timerange="day"
      coinBalances={props.coinBalances}
      data={props.historicalData}
      highlightCoin={props.highlightCoin}
      toSymbol={props.toSymbol}
    />
  ) : (
    <Loading>loading...</Loading>
  );

  return (
    <NonDesktop>
      {props.shouldDisplay && (
        <tr key={`graph-row-${props.coin}`} className="mobile-graph">
          <td colSpan={5}>{body}</td>
        </tr>
      )}
    </NonDesktop>
  );
};
