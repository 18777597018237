import { Reducer } from 'redux';

import { TimeRange } from 'app/types/interface';

export type CryptoCompareState = {
  timeRange?: TimeRange;
  timePoints: Array<number> | undefined;
};

const initialState: CryptoCompareState = {
  timeRange: 'day',
  timePoints: undefined,
};

export type TimeRangeAction = { type: 'crypto_compare/TIME_RANGE'; payload: TimeRange };
export type SetTimePointsAction = { type: 'crypto_compare/SET_TIME_POINTS'; payload: Array<number> | undefined };

export type CryptoCompareActions = TimeRangeAction | SetTimePointsAction;

export const setTimeRange = (timeRange: TimeRange): TimeRangeAction => ({
  type: 'crypto_compare/TIME_RANGE',
  payload: timeRange,
});

export const resetTimePoints = (): SetTimePointsAction => ({
  type: 'crypto_compare/SET_TIME_POINTS',
  payload: undefined,
});

export const setTimePoints = (timePoints: Array<number>): SetTimePointsAction => ({
  type: 'crypto_compare/SET_TIME_POINTS',
  payload: timePoints,
});

export const reducer: Reducer<CryptoCompareState, CryptoCompareActions> = (
  state: CryptoCompareState = initialState,
  action: CryptoCompareActions
): CryptoCompareState => {
  switch (action.type) {
    case 'crypto_compare/TIME_RANGE':
      return {
        ...state,
        timeRange: action.payload,
      };
    case 'crypto_compare/SET_TIME_POINTS':
      return {
        ...state,
        timePoints: action.payload,
      };
    default:
      return state;
  }
};
