export type LoadingObject<LoadingKey extends string> = {
  loading: Partial<Record<LoadingKey, boolean>>;
};

export type LoadingPayload<LoadingKey extends string> = {
  key: LoadingKey;
  isLoading: boolean;
};

export const loadingActionBuilder =
  <T, LoadingKey>(typeString: T) =>
  (key: LoadingKey, { isLoading }: { isLoading: boolean }) => ({
    type: typeString,
    payload: { key, isLoading },
  });

export const reduceLoading = <LoadingKey extends string, State extends LoadingObject<LoadingKey>>(
  state: State,
  { key, isLoading }: LoadingPayload<LoadingKey>
): State => ({
  ...state,
  loading: {
    ...state.loading,
    [key]: isLoading,
  },
});

export const wrapArray = <T>(singleTorArrayOfT: T | Array<T>): Array<T> =>
  Array.isArray(singleTorArrayOfT) ? singleTorArrayOfT : [singleTorArrayOfT];
