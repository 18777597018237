/* eslint-disable max-len */
// prettier-ignore
export default {
  auth: {
    placeholders: {
      email: 'email',
      password: 'password',
      passwordConfirmation: 'confirm password',
    },
    forgotPasswordRequest: {
      title: 'Forgot password?',
      subtitle: 'Don\'t worry, we can send you an email that will let you reset it',
      switchQuery: 'Know your password?',
      switchAction: 'Log in',
      action: 'Send recovery email',
      description: 'Please check your email',
    },
    forgotPassword: {
      title: 'Update your password',
      subtitle: 'Consider using a password manager so that you don\'t forget it again?',
      switchQuery: 'Know your password?',
      switchAction: 'Log in',
      action: 'Update password',
      description: 'Don\'t forget to remember it this time!',
    },
    login: {
      title: 'Log in to your account',
      subtitle: 'Enter your details below.',
      switchQuery: 'Don’t have an account?',
      switchAction: 'Sign Up',
      action: 'Log In',
      forgotPassword: 'Forgot Password?',
    },
    register: {
      title: 'Create a free account.',
      subtitle: 'Portfolio zen awaits you',
      switchQuery: 'Already have an account?',
      switchAction: 'Log in',
      action: 'Create Account',
      terms: 'By clicking “Create Account“ I agree to to the Terms of Service and Privacy Policy.',
    },
  },
  home: {
    jumbotron: {
      title: 'Feed your crypto addiction',
      subtitle: 'Stay up all night watching your fortunes rise and fall on your new favorite portfolio tracker.',
      action: 'Enter the coin hole!',
    },
    features: {
      feature1: {
        title: 'Stupid Simple',
        content: 'View your portfolio on a single page, and feel like an expert trader without all the extra monitors.',
      },
      feature2: {
        title: 'Extra Addictive',
        content: 'Dynamic graphs, live updates, and tasteful animations ensure you\'ll never look up from your screen again.',
      },
      feature3: {
        title: 'All the everything, forever!',
        content: 'Keep track of all your coins in one place - every coin, on any device, for free.'},
    },
    details: {
      lastLine: 'request access to your very own',
      linkText: 'cryptocurrency portfolio',
      detail1: {
        title: 'Always know what\'s changing',
        boldText: 'live updating animations',
        text: 'show you which coins are up and down every few seconds',
        lastLine: 'I\'m jonesin\'',
        linkText: 'give it to me!',
      },
      detail2: {
        title: 'See all your coins at once',
        boldText: 'a single page interface',
        text: 'lets you quickly compare your coins\' performance at a glance',
        lastLine: 'I need it',
        linkText: 'sign me up!',
      },
      detail3: {
        title: 'We designed it!',
        boldText: 'smarter design',
        text: 'is easy on the eyes, good for something you stare at constantly',
        lastLine: 'activate crypto expert mode',
        linkText: 'now',
      },
    },
  },
  errors: {
    notFound: 'Uh-oh, The page you were looking for could not be found',
  },
  charts: {
    descriptions: {
      totalValue: 'This chart shows the total value of your portfolio over time. Please note that currently we can only show an approximation based on a projection of your current holdings. We do not account for past trades.',
      totalPercent: 'A pie chart representing your portfolio in terms of holdings. Larger sections represent coins that you have more investment in.',
      relativeGrowth: 'This chart shows you the relative changes in your portfolio over time. When one coin has grown more compared to others, it will take up more of the graph. If all coins are moving in the same direction at the same rates, then you will see evenly spaced horizontal bars. For starters, try looking at the right side of the graph for the color that is the thickest. That is the coin that has the most growth activity in your portfolio.',
      streamGraph: 'This chart allows you to see the relative growth/decline of your portfolio over time, which is visible by the total thickness of the ribbon. Colors that are thicker represent coins that have grown the most or have the most growth activity.',
    },
  },
  onboarding: {
    credentials: {
      title: 'Enter API keys so we can automatically load your portfolio',
      description: 'Please make sure that you only generate READ ONLY API keys, that will help ensure that your account is protected',
      confirmText: 'All Set',
    },
    welcome: {
      title: 'Welcome to Coinholio',
      description: 'Let\'s get your account set up so you can start tracking your gains!',
      confirmText: 'Let\'s do this!',
    },
    complete: {
      title: 'It\'s go time!',
      description: 'We have everything we need, and you\'re ready to go. Note that you can always change these settings under "Settings" (in the top right of the home page).',
      confirmText: 'Take me to my dashboard already!',
    },
    totalCashInvested: {
      title: 'How much cash in total have you invested in cryptocurrency?',
      description: "We'll use this information to calculate the total gains/losses of your portfolio. If you're not sure, enter an estimate. You can always adjust these settings later.",
      placeholder: '2500',
    },
    askCredentials: {
      title: 'Do you have coins stored on an exchange like Bittrex or Gemini? Would you like to automatically import data?',
      description: 'Coinholio will only use your data in order to properly display your portfolio. We\'ll read from your accounts so that you don\'t have to manually enter the currencies that you own.',
      confirmText: 'Hell yes!',
      skipText: 'Nope',
    },
    bittrexKeys: {
      title: 'Please create a read only API key on Bittrex, and then paste in the key and secret below',
      description: "Important: please make sure that the API key you generate is READ ONLY, that way you can protect your Bittrex account and you don't have to worry that anyone else has control over it.",
    },
    bittrexApiKey: {
      title: 'Bittrex API Key',
    },
    bittrexApiSecret: {
      title: 'Bittrex API Secret',
    },
    ethereumAddresses: {
      title: 'Do you have buy ICOs or tokens and stored them on Ethereum addresses?',
      description: 'Coinholio can scan the public token data from all of your Ethereum addresses in order to properly display your portfolio.',
      confirmText: 'Next',
      skipText: 'Nah',
      placeholder: '0xc3A108bAc3F017edefE344218c2e9d03ab5faD6F',
    },
    walletHoldings: {
      title: "Enter in the currencies that you own so we can build your portfolio",
      description: "If you hold all of your coins on Bittrex.com, click to the next step and we can automatically read your portfolio from there.",
      confirmText: 'Done!',
    },
  },
};
/* eslint-enable max-len */
