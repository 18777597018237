import React from 'react';
import Responsive from 'react-responsive';

export const widths = {
  desktop: 992,
  tablet: 768,
  mobile: 767,
};

export const media = {
  desktop: `@media (min-width: ${widths.desktop}px)`,
  tablet: `@media (min-width: ${widths.tablet}px, max-width: ${widths.desktop}px)`,
  mobile: `@media (max-width: ${widths.mobile}px)`,
  nonPhone: `@media (min-width: ${widths.tablet}px)`,
  nonDesktop: `@media (max-width: ${widths.desktop}px)`,
};

export const Desktop = function <T>(props: T) {
  return <Responsive {...props} minWidth={widths.desktop} />;
};
export const Tablet = function <T>(props: T) {
  return <Responsive {...props} minWidth={widths.tablet} maxWidth={widths.desktop} />;
};
export const Mobile = function <T>(props: T) {
  return <Responsive {...props} maxWidth={widths.mobile} />;
};
export const NonPhone = function <T>(props: T) {
  return <Responsive {...props} minWidth={widths.tablet} />;
};
export const NonDesktop = function <T>(props: T) {
  return <Responsive {...props} maxWidth={widths.desktop} />;
};
