import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import LogoBlue from 'app/assets/images/logo.blue.svg';
import NavigationLink from 'app/components/navigationLink';
import { FormError } from 'app/components/form/form_error';
import { FormPanel } from 'app/pages/login/form_panel';
import { GradientBorderButton } from 'app/components/gradient_border_button';
import { Header } from 'app/pages/login/header';

const Wrapper = styled.div``;

const SideSpacer = styled.div`
  width: 300px;
`;

const BelowButton = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: ${colors.ui.grey7};
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type Props = {
  onSubmit: (event?: React.SyntheticEvent) => void;
  title: string;
  confirmText: string;
  errorMessage: string | null;
  subTitle?: string;
  topLeft?: React.ReactNode;
  topRight?: React.ReactNode;
  isDisabled?: boolean;
  belowButton?: React.ReactNode;
  children: React.ReactNode;
};

const WhiteFormPage = (props: Props) => {
  const submit = (event: React.SyntheticEvent | undefined) => {
    if (event) event.preventDefault();
    if (!props.isDisabled) props.onSubmit(event);
  };
  return (
    <Wrapper>
      <Header>
        <SideSpacer>{props.topLeft}</SideSpacer>
        <NavigationLink to="/">
          <LogoBlue width={36} height={36} />
        </NavigationLink>
        <SideSpacer>{props.topRight}</SideSpacer>
      </Header>
      <form onSubmit={submit}>
        <FormPanel>
          <h1>{props.title}</h1>
          {props.subTitle && <h2>{props.subTitle}</h2>}
          <Inputs>{props.children}</Inputs>
          <FormError>{props.errorMessage}</FormError>
          <GradientBorderButton large onClick={submit} disabled={props.isDisabled} content={props.confirmText} />
          {props.belowButton && <BelowButton>{props.belowButton}</BelowButton>}
        </FormPanel>
      </form>
    </Wrapper>
  );
};

export { WhiteFormPage, BelowButton };
