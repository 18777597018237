import * as React from 'react';

import { Input, Errors, StateUpdater, InputState, SingleParentState } from 'app/types/typeform';
import { SingleInput, Props as SingleInputProps } from 'app/components/typeform/inputs/single_input';
import { ListInput, Props as ListInputProps } from 'app/components/typeform/inputs/list_input';

type Props = {
  input: Input;
  errors: Errors;
  updateErrors: (errors: Errors) => void;
  updateInputState: StateUpdater;
  parentState: InputState;
  onSubmit: () => void;
};

function isArray<T>(arg: Array<T> | unknown): arg is Array<T> {
  return Array.isArray(arg);
}

const InputSwitch = (props: Props) => {
  const kind = props.input.kind;
  const constantParentState = props.parentState;

  if (isArray(constantParentState)) {
    const inputProps: ListInputProps = {
      id: props.input.id,
      errors: props.errors,
      updateErrors: props.updateErrors,
      parentState: constantParentState,
      updateInputState: props.updateInputState,
      placeholder: props.input.placeholder,
      input: props.input,
    };

    if (kind === 'multitext') {
      return <ListInput {...inputProps} />;
    } else if (typeof kind === 'object' && !Array.isArray(kind)) {
      return <ListInput {...inputProps} fields={kind.fields} />;
    }
  } else if (typeof constantParentState === 'object') {
    const singleParentState: SingleParentState = constantParentState;
    const inputProps: Omit<SingleInputProps, 'fields'> = {
      errors: props.errors,
      updateErrors: props.updateErrors,
      parentState: singleParentState,
      updateInputState: props.updateInputState,
      input: props.input,
    };

    const onKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') props.onSubmit();
    };

    if (kind === 'text') {
      const fields = [{ id: props.input.id, required: props.input.required, placeholder: props.input.placeholder }];
      return <SingleInput {...inputProps} fields={fields} onKeyPress={onKeyPress} />;
    } else if (Array.isArray(kind)) {
      return <SingleInput {...inputProps} fields={kind} onKeyPress={onKeyPress} />;
    }
  }

  return <div />;
};

export { InputSwitch };
