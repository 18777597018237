import { zip, omit, compact } from 'lodash';

import { CoinPrices, CurrentPriceData } from 'app/types/crypto_compare';
import { CurrencyTicker } from 'app/types/nomics';

export type Currencies = ReadonlyArray<string> & readonly ['USD', 'BTC'];

export const DEFAULT_INTERVAL = '1h,1d';

// Note, no service, just helper functions because we proxy this through the backend to work around CORS
// issues

export const convertNomicsToCryptoCompare = (
  currencies: Currencies,
  result: Array<Array<CurrencyTicker>>
): CoinPrices => {
  const output: CoinPrices = {};

  const transposed: Record<
    string,
    {
      USD: CurrencyTicker | undefined;
      BTC: CurrencyTicker | undefined;
      [currency: string]: CurrencyTicker | undefined;
    }
  > = {};
  zip(currencies, result).forEach(([currency, result]) => {
    if (currency && result) {
      result.forEach(data => {
        if (!(data.symbol in transposed)) transposed[data.symbol] = { USD: undefined, BTC: undefined };
        transposed[data.symbol][currency] = data;
      });
    }
  });

  Object.entries(transposed).forEach(([symbol, currencyData]) => {
    const { USD, BTC } = currencyData;
    if (USD && BTC) {
      output[symbol] = {
        USD: convertNomicsToCryptoComparePriceData('USD', USD),
        BTC: convertNomicsToCryptoComparePriceData('BTC', BTC),
        ...Object.fromEntries(
          compact(
            Object.entries(omit(currencyData, ['USD', 'BTC'])).map(([currency, data]) =>
              data ? [currency, convertNomicsToCryptoComparePriceData(currency, data)] : undefined
            )
          )
        ),
      };
    }
  });

  return output;
};

const convertNomicsToCryptoComparePriceData = (toSymbol: string, data: CurrencyTicker): CurrentPriceData => ({
  CHANGE24HOUR: +data['1d'].price_change,
  CHANGEDAY: 0,
  CHANGEHOUR: +data['1h'].price_change,
  CHANGEPCT24HOUR: +data['1d'].price_change_pct,
  CHANGEPCTDAY: 0,
  CHANGEPCTHOUR: +data['1h'].price_change_pct,
  CONVERSIONSYMBOL: undefined,
  CONVERSIONTYPE: undefined,
  FLAGS: '',
  FROMSYMBOL: data.symbol,
  HIGH24HOUR: 0,
  HIGHDAY: 0,
  HIGHHOUR: undefined,
  IMAGEURL: data.logo_url,
  LASTMARKET: '(unknown)',
  LASTTRADEID: '(unknown)',
  LASTUPDATE: new Date(data.price_timestamp).getTime(),
  LASTVOLUME: new Date(data.price_timestamp).getTime(),
  LASTVOLUMETO: 0,
  LOW24HOUR: 0,
  LOWDAY: 0,
  LOWHOUR: undefined,
  MARKET: '(unknown)',
  MEDIAN: undefined,
  MKTCAP: +data.market_cap,
  OPEN24HOUR: 0,
  OPENDAY: 0,
  OPENHOUR: undefined,
  PRICE: +data.price,
  SUPPLY: +data.circulating_supply,
  TOPTIERVOLUME24HOUR: undefined,
  TOPTIERVOLUME24HOURTO: undefined,
  TOSYMBOL: toSymbol,
  TOTALTOPTIERVOLUME24H: undefined,
  TOTALTOPTIERVOLUME24HTO: undefined,
  TOTALVOLUME24H: +data['1d'].volume,
  TOTALVOLUME24HTO: +data['1d'].volume,
  TYPE: '(unknown)',
  VOLUME24HOUR: +data['1d'].volume,
  VOLUME24HOURTO: +data['1d'].volume,
  VOLUMEDAY: 0,
  VOLUMEDAYTO: 0,
  VOLUMEHOUR: +data['1h'].volume,
  VOLUMEHOURTO: undefined,
});
