import * as React from 'react';

import { CoinInfo } from 'app/types/api';

export const buildAutocompleteCoinProps = function (coinInfo: CoinInfo) {
  return {
    getSuggestions: (input: string) => {
      const cleanedInput = input.trim().toLowerCase();

      if (cleanedInput.length) {
        const matches = coinInfo.filter(data => data.symbol.toLowerCase().includes(cleanedInput));

        if (cleanedInput.length > 1)
          matches.sort((a, b) => {
            if (a.symbol.toLowerCase() === cleanedInput) return -1;
            else if (b.symbol.toLowerCase() === cleanedInput) return 1;
            else return 0;
          });

        let nameMatches: CoinInfo = [];
        if (matches.length < 5) {
          nameMatches = coinInfo.filter(data => (data.name ? data.name.toLowerCase().includes(cleanedInput) : false));
        }

        return matches.concat(nameMatches);
      } else return [];
    },
    getSuggestionValue: (obj: { symbol: string }) => obj.symbol,
    render: (obj: { symbol: string; name: string }) => (
      <div>
        <strong>{obj.symbol}</strong>
        {obj.name ? ` - ${obj.name}` : ''}
      </div>
    ),
  };
};
