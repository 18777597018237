import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import t from 'app/utils/i18n';
import dimensions from 'app/styles/dimensions';
import { media } from 'app/utils/responsive';

const { paddingTop, paddingHorizontal } = dimensions.home.bottom;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: ${paddingTop}px ${paddingHorizontal}px;

  h2 {
    font-size: 22px;
    font-weight: bold;
    color: ${colors.text.darkBlack};
    margin-bottom: 17px;
  }

  p {
    font-size: 16px;
    line-height: 1.75;
    color: ${colors.ui.grey7};
  }

  ${media.nonDesktop} {
    margin-top: 50px;
    padding: 0px;
    flex-direction: column;
  }
`;

const FeatureWrapper = styled.div`
  max-width: 350px;

  ${media.nonDesktop} {
    text-align: center;
    margin: 0px 20px;
    max-width: none;

    & + & {
      margin-top: 50px;
    }
  }
`;

type Props = {
  title: string;
  content: string;
};

const Feature = ({ title, content }: Props) => (
  <FeatureWrapper>
    <h2>{title}</h2>
    <p>{content}</p>
  </FeatureWrapper>
);

const Features = () => (
  <Wrapper>
    <Feature title={t('home.features.feature1.title')} content={t('home.features.feature1.content')} />
    <Feature title={t('home.features.feature2.title')} content={t('home.features.feature2.content')} />
    <Feature title={t('home.features.feature3.title')} content={t('home.features.feature3.content')} />
  </Wrapper>
);

export { Features };
