import { push } from 'connected-react-router';

import { AuthData, AuthFailed } from 'app/types/auth';
import services from 'app/services';
import { State } from 'app/store/types';
import { ResponseError } from 'app/services/web_request_service';
import { loadingActionBuilder } from 'app/store/modules/utils';

export type LoadingKey = 'login' | 'register' | 'forgotPasswordRequest' | 'forgotPassword';

export type CheckLoginStateAction = { type: 'auth/CHECK_LOGIN_STATE' };
export type LogoutAction = { type: 'auth/LOGOUT' };
export type LoginAction = { type: 'auth/LOGIN'; payload: AuthData };
export type RegisterAction = { type: 'auth/REGISTER'; payload: AuthData };
export type ForgotPasswordAction = { type: 'auth/SUBMIT_FORGOT_PASSWORD'; payload: AuthData };
export type ForgotPasswordRequestAction = { type: 'auth/FORGOT_PASSWORD_REQUEST' };
export type AuthFailedAction = { type: 'auth/FAILED'; payload: { message: string; json: AuthFailed } };
export type LoadingAction = { type: 'auth/LOADING'; payload: { key: LoadingKey; isLoading: boolean } };

export type AuthActions =
  | CheckLoginStateAction
  | LogoutAction
  | LoginAction
  | RegisterAction
  | ForgotPasswordRequestAction
  | ForgotPasswordAction
  | AuthFailedAction
  | LoadingAction;

export const loadingAction = loadingActionBuilder<'auth/LOADING', LoadingKey>('auth/LOADING');

export const authFailedAction = (message: string, json: AuthFailed): AuthFailedAction => ({
  type: 'auth/FAILED',
  payload: { message, json },
});

function wire<Payload>(
  key: LoadingKey,
  serviceCall: (getState: () => State) => Promise<Payload>,
  actionBuilder: (payload: Payload) => AuthActions,
  extra?: (dispatch: Dispatch) => ReturnType<Dispatch>
): (dispatch: Dispatch, getState: () => State) => Promise<void> {
  return async (dispatch, getState) => {
    dispatch(loadingAction(key, { isLoading: true }));
    try {
      const result = await serviceCall(getState);
      dispatch(actionBuilder(result));
      if (extra) extra(dispatch);
    } catch (e) {
      if (e instanceof ResponseError) {
        dispatch(authFailedAction(e.message, e.json));
      }
    }
    dispatch(loadingAction(key, { isLoading: false }));
  };
}

export const logout = (): LogoutAction => ({ type: 'auth/LOGOUT' });

export const checkLoginState = (): CheckLoginStateAction => ({ type: 'auth/CHECK_LOGIN_STATE' });

export const login = (email: string, password: string) =>
  wire(
    'login',
    () => services.apiService.login(email, password),
    payload => ({ type: 'auth/LOGIN', payload })
  );

export const register = (email: string, pw1: string, pw2: string) =>
  wire(
    'register',
    () => services.apiService.register(email, pw1, pw2),
    payload => ({ type: 'auth/REGISTER', payload }),
    dispatch => dispatch(push('/onboarding'))
  );

export const forgotPasswordRequest = (email: string) =>
  wire(
    'forgotPasswordRequest',
    () => services.apiService.forgotPasswordRequest(email),
    () => ({ type: 'auth/FORGOT_PASSWORD_REQUEST' })
  );

export const forgotPassword = (userId: number, token: string, goodUntil: number, pw1: string, pw2: string) =>
  wire(
    'forgotPassword',
    () => services.apiService.forgotPassword(userId, token, goodUntil, pw1, pw2),
    payload => ({ type: 'auth/SUBMIT_FORGOT_PASSWORD', payload })
  );
