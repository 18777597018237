import { CurrentCoinData, CoinPrices, HistoricalPoint } from 'app/types/interface';
import { lowerCase, zip } from 'lodash';

/**
 * ex: 2021-07-25T19:13:31.901Z
 */
type StringAsDate = string;

export type CoinListing = {
  id: string;
  symbol: string;
  name: string;
};

type SimplePrice = {
  usd: number;
  usd_market_cap: number;
  usd_24h_vol: number;
  usd_24h_change: number;
  btc: number;
  btc_market_cap: number;
  btc_24h_vol: number;
  btc_24h_change: number;
  last_updated_at: number;
};

type PriceTarget =
  | 'aed'
  | 'ars'
  | 'aud'
  | 'bch'
  | 'bdt'
  | 'bhd'
  | 'bits'
  | 'bmd'
  | 'bnb'
  | 'brl'
  | 'btc'
  | 'cad'
  | 'chf'
  | 'clp'
  | 'cny'
  | 'czk'
  | 'dkk'
  | 'dot'
  | 'eos'
  | 'eth'
  | 'eur'
  | 'gbp'
  | 'hkd'
  | 'huf'
  | 'idr'
  | 'ils'
  | 'inr'
  | 'jpy'
  | 'krw'
  | 'kwd'
  | 'link'
  | 'lkr'
  | 'ltc'
  | 'mmk'
  | 'mxn'
  | 'myr'
  | 'ngn'
  | 'nok'
  | 'nzd'
  | 'php'
  | 'pkr'
  | 'pln'
  | 'rub'
  | 'sar'
  | 'sats'
  | 'sek'
  | 'sgd'
  | 'thb'
  | 'try'
  | 'twd'
  | 'uah'
  | 'usd'
  | 'vef'
  | 'vnd'
  | 'xag'
  | 'xau'
  | 'xdr'
  | 'xlm'
  | 'xrp'
  | 'yfi'
  | 'zar';

export type PriceDetail = Record<PriceTarget, number>;

export type MarketData = {
  total_value_locked: {
    btc: number;
    usd: number;
  };
  market_cap_rank: number;
  price_change_24h: number;
  price_change_percentage_24h: number;
  price_change_percentage_7d: number;
  price_change_percentage_14d: number;
  price_change_percentage_30d: number;
  price_change_percentage_60d: number;
  price_change_percentage_200d: number;
  price_change_percentage_1y: number;
  market_cap_change_24h: number;
  market_cap_change_percentage_24h: number;
  total_supply: number;
  max_supply: number;
  circulating_supply: number;
  current_price: PriceDetail;
  ath: PriceDetail;
  /**
   * number 0-100
   */
  ath_change_percentage: PriceDetail;
  atl: PriceDetail;
  /**
   * number 0-100
   */
  atl_change_percentage: PriceDetail;
  market_cap: PriceDetail;
  fully_diluted_valuation: PriceDetail;
  total_volume: PriceDetail;
  high_24h: PriceDetail;
  low_24h: PriceDetail;
  price_change_24h_in_currency: PriceDetail;
  price_change_percentage_1h_in_currency: PriceDetail;
  price_change_percentage_24h_in_currency: PriceDetail;
  price_change_percentage_7d_in_currency: PriceDetail;
  price_change_percentage_14d_in_currency: PriceDetail;
  market_cap_change_24h_in_currency: PriceDetail;
  market_cap_change_percentage_24h_in_currency: PriceDetail;
  last_updated: StringAsDate;
};

export type DetailedPrice = {
  id: string;
  symbol: string;
  name: string;
  image?: {
    thumb?: string;
    small?: string;
    large?: string;
  };
  market_cap_rank: number;
  market_data: MarketData;
  last_updated: StringAsDate;
};

type TimePoint = [milliseconds: number, value: number];

export type MarketChartRange = {
  prices: Array<TimePoint>;
  market_caps: Array<TimePoint>;
  total_volumes: Array<TimePoint>;
};

export type SimplePriceResponse<Symbol extends string> = Record<Symbol, SimplePrice>;

export const toInterface = (data: SimplePriceResponse<string>, idToSymbol: Record<string, string>): CoinPrices =>
  Object.fromEntries(
    Object.entries(data).map(([coin, data]) => [
      idToSymbol[coin].toUpperCase(),
      {
        USD: {
          change: {
            currency: {
              hour24: data.usd_24h_change,
              day: data.usd_24h_change,
            },
            percent: {
              hour24: 0,
              day: 0,
            },
          },
          current: {
            price: data.usd,
            marketCap: data.usd_market_cap,
            supply: data.usd_market_cap / data.usd,
            hour24: {
              high: 0,
              low: 0,
              totalVolume: data.usd_24h_vol,
            },
          },
        },
        BTC: {
          change: {
            currency: {
              hour24: data.btc_24h_change,
              day: data.btc_24h_change,
            },
            percent: {
              hour24: 0,
              day: 0,
            },
          },
          current: {
            price: data.btc,
            marketCap: data.btc_market_cap,
            supply: data.btc_market_cap / data.btc,
            hour24: {
              high: 0,
              low: 0,
              totalVolume: data.btc_24h_vol,
            },
          },
        },
      },
    ])
  );

const marketDataForCurrency = (marketData: MarketData, priceTarget: PriceTarget): CurrentCoinData => ({
  change: {
    currency: {
      hour24: marketData.price_change_24h_in_currency[priceTarget],
      day: marketData.price_change_24h_in_currency[priceTarget],
    },
    percent: {
      hour24: marketData.price_change_percentage_24h,
      day: marketData.price_change_percentage_24h,
    },
  },
  current: {
    price: marketData.current_price[priceTarget],
    marketCap: marketData.market_cap[priceTarget],
    supply: marketData.total_supply || marketData.max_supply || marketData.circulating_supply,
    hour24: {
      high: marketData.high_24h[priceTarget],
      low: marketData.low_24h[priceTarget],
      totalVolume: marketData.total_volume[priceTarget],
    },
  },
});

export const detailedToInterface = ({ symbol, market_data }: DetailedPrice): CoinPrices => ({
  [symbol.toUpperCase()]: {
    USD: marketDataForCurrency(market_data, 'usd'),
    BTC: marketDataForCurrency(market_data, 'btc'),
  },
});

export const marketChartRangeToInterface = (data: MarketChartRange): Array<HistoricalPoint> =>
  data.prices.map((p, i) => ({
    time: Math.floor(p[0] / 1000),
    price: {
      value: p[1],
    },
    volume: {
      total: data.total_volumes[i][1],
    },
  }));
