import * as React from 'react';
import styled from 'styled-components';

import { CoinIcon } from 'app/components/coin_icon';
import { CoinData } from 'app/types/api';
import { DisplayDollars, Span } from 'app/components/display_value';

const Wrapper = styled.div`
  width: 250px;
  & > div {
    margin: 5px auto;
  }
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;

  ${Span} {
    margin: 0px;
  }
`;

type LineProps = {
  title: string;
  children?: React.ReactNode;
};

const Line = (props: LineProps) => (
  <LineWrapper>
    <strong>{props.title}:</strong>
    <div>{props.children}</div>
  </LineWrapper>
);

type Props = {
  icon: CoinData | undefined;
  coin: string;
  amount: number;
  cashValue: number;
  goodTrade: boolean;
};

const CoinAmount = (props: Props) => (
  <Wrapper>
    <div>{props.icon ? <CoinIcon src={props.icon.imageUrl} /> : null}</div>
    <div>
      <strong>{props.coin}</strong>
    </div>
    <Line title="Amount">{props.amount}</Line>
    <Line title="Current Price">
      <DisplayDollars change={props.goodTrade ? 1 : -1} value={props.cashValue} />
    </Line>
  </Wrapper>
);

export { CoinAmount };
