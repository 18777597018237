import * as React from 'react';
import Autosuggest, { InputProps, SuggestionSelectedEventData } from 'react-autosuggest';
import styled from 'styled-components';

import { Autocomplete, AutocompleteSuggestion } from 'app/types/typeform';
import { SimpleFormEvent } from 'types/globals';

const Theme = styled.div`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Circular, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`;

type Props = Autocomplete & {
  onChange: (event: SimpleFormEvent) => void;
  inputProps: InputProps<unknown> & { ref: React.LegacyRef<HTMLInputElement> };
};

type State = {
  suggestions: Array<Object>;
};

class AutocompleteInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { suggestions: [] };

    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
  }

  onSuggestionsFetchRequested({ value }: AutocompleteSuggestion) {
    this.setState({ suggestions: this.props.getSuggestions(value) });
  }

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  onSuggestionSelected(
    _event: React.FormEvent<HTMLInputElement>,
    suggestionData: SuggestionSelectedEventData<unknown>
  ) {
    this.props.onChange({ currentTarget: { value: suggestionData.suggestionValue } });
  }

  renderInputComponent(inputProps: Object) {
    return <input {...inputProps} ref={this.props.inputProps.ref} />;
  }

  render() {
    return (
      <Theme>
        <Autosuggest
          focusInputOnSuggestionClick={false}
          suggestions={this.state.suggestions}
          getSuggestionValue={this.props.getSuggestionValue}
          renderSuggestion={this.props.render}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          inputProps={this.props.inputProps}
          // fix for clobbered ref property, see: https://github.com/moroshko/react-autosuggest/issues/474
          renderInputComponent={this.renderInputComponent}
        />
      </Theme>
    );
  }
}

export { AutocompleteInput };
