import services from 'app/services';
import { SettingsData } from 'app/pages/settings';
import { AuthFailed } from 'app/types/auth';
import { State } from 'app/store/types';
import { logout } from 'app/store/modules/auth/actions';
import { ResponseError } from 'app/services/web_request_service';
import { loadingActionBuilder } from 'app/store/modules/utils';

export type LoadingKey = 'fetchingSettings' | 'updatingSettings';

export type LoadSettingsAction = { type: 'user/LOAD_SETTINGS'; payload: SettingsData };
export type UpdateSettingsAction = { type: 'user/UPDATE_SETTINGS'; payload: SettingsData };
export type LoadingAction = { type: 'user/LOADING'; payload: { key: LoadingKey; isLoading: boolean } };
export type ErrorAction = { type: 'user/ERROR'; payload: { message: string; json: AuthFailed } };

export type UserActions = LoadSettingsAction | UpdateSettingsAction | LoadingAction | ErrorAction;

export const loadingAction = loadingActionBuilder<'user/LOADING', LoadingKey>('user/LOADING');

export const authFailedAction = (message: string, json: AuthFailed): ErrorAction => ({
  type: 'user/ERROR',
  payload: { message, json },
});

export const loadSettings = () => async (dispatch: Dispatch, getState: () => State) => {
  const {
    auth: { userId, authToken },
  } = getState();

  if (userId != null && authToken) {
    dispatch(loadingAction('fetchingSettings', { isLoading: true }));

    try {
      const result = await services.apiService.loadSettings(userId, authToken);
      dispatch({ type: 'user/LOAD_SETTINGS', payload: result });
    } catch (e) {
      if (e instanceof ResponseError) {
        if (e.response && e.response.status === 401) dispatch(logout());
      }
    }

    dispatch(loadingAction('fetchingSettings', { isLoading: false }));
  }
};

export const updateSettings =
  (data: SettingsData) =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const {
      auth: { userId, authToken },
    } = getState();

    if (userId != null && authToken) {
      dispatch(loadingAction('updatingSettings', { isLoading: true }));

      try {
        const result = await services.apiService.updateSettings(data, userId, authToken);
        dispatch({ type: 'user/UPDATE_SETTINGS', payload: result });
      } catch (e) {
        if (e instanceof ResponseError) {
          dispatch({ type: 'user/ERROR', payload: { message: e.message, json: e.json } });
        }
      }

      dispatch(loadingAction('updatingSettings', { isLoading: false }));
    }
  };
