import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { difference, drop, isEqual, zip } from 'lodash';

import { PieData } from 'app/components/charts/donut_chart';
import { CoinHistory, HistoricalPoint, CurrentCoinData, CoinPrices, PriceSymbol } from 'app/types/interface';
import { CoinBalances, CoinHoldings, CoinInfo } from 'app/types/api';
import dimensions from 'app/styles/dimensions';
import { ToggleButtons } from 'app/components/toggle_buttons';
import CryptoCompareService from 'app/services/crypto_compare_service';
import { TimeRange } from 'app/types/interface';
import { Wrapper as DashboardSectionWrapper } from 'app/components/dashboard_section';
import { generateGrowthData, generateTotalValueData, PondTransformable } from 'app/utils/data_transformers';
import { Point } from 'app/components/charts/gradient_charts/gradient_line_chart';
import colors from 'app/styles/colors';
import { PortfolioSection } from 'app/components/chart_panel/portfolio_section';
import { IndividualCoinSection } from 'app/components/chart_panel/individual_coin_section';
import { ErrorBoundary } from 'app/components/error_boundary';
import { State as GlobalState } from 'app/store/types';
import { resetTimePoints, setTimeRange } from 'app/store/modules/crypto_compare';
import { fetchAllCoinsHistorical } from 'app/store/modules/balance/actions';

const Wrapper = styled.div`
  padding-left: ${dimensions.chartPanel.padding.left}px;
  padding-right: ${dimensions.chartPanel.padding.right}px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 15px 0px;
  padding-bottom: 30px;
`;

const Separator = styled.div`
  height: 2px;
  background-color: ${colors.ui.grey2};
  margin: 35px 0px;
  display: none;

  ${DashboardSectionWrapper} + & {
    display: block;
  }
`;

export type ChartData = {
  data: Array<CoinHistory>;
  highlightCoin: string | undefined;
  onHighlightChange: (coin?: string) => void;
};

export type Props = {
  coinBalances: CoinBalances;
  toSymbol: PriceSymbol;
  toggleBTC: () => void;
  visibleCoins: Array<string>;
  timerange: TimeRange;
  onSelectTime: (visibleCoins: Array<string>, symbol: PriceSymbol, time: TimeRange) => void;
  coinPrices: CoinPrices;
  onHighlightChange: (coin?: string) => void;
  highlightCoin: string | undefined;
  allCoinsData: Array<CoinHistory>;
  loadHistoricalData: (visibleCoins: Array<string>, symbol: PriceSymbol) => void;
  loading: boolean;
  coinInfo: CoinInfo;
  coinHoldings: CoinHoldings;
};

type State = {
  historicalPriceUpdateInterval: number | null | undefined;
  filteredAllCoinsData: Array<CoinHistory>;
  portfolioHoverValue: number | null | undefined;
  individualHoverData: HistoricalPoint | null | undefined;
  totalValueChartData: Array<Point>;
  totalValueStats: {
    min: number;
    max: number;
  };
  currentPriceData: CurrentCoinData | null | undefined;
  growthData: PondTransformable;
  growthHoverData: Array<PieData> | null | undefined;
};

class ChartPanel extends React.Component<Props, State> {
  static defaultProps = {
    visibleCoins: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      historicalPriceUpdateInterval: null,
      filteredAllCoinsData: [],
      portfolioHoverValue: null,
      individualHoverData: null,
      totalValueChartData: [],
      currentPriceData: null,
      totalValueStats: { min: 0, max: 0 },
      growthData: { columns: [], points: [] },
      growthHoverData: null,
    };

    this.onTotalValueHover = this.onTotalValueHover.bind(this);
    this.onIndividualHover = this.onIndividualHover.bind(this);
    this.getGrowthHoverData = this.getGrowthHoverData.bind(this);

    this.state = { ...this.state, ...this.buildState(props) };
  }

  onTotalValueHover(portfolioHoverValue: number | undefined | null) {
    this.setState({ portfolioHoverValue });
  }

  onIndividualHover(
    _coin: string | undefined,
    individualHoverData: HistoricalPoint | undefined,
    index: number | undefined
  ) {
    const growthHoverData = index ? this.getGrowthHoverData(index) : null;
    this.setState({ individualHoverData, growthHoverData });
  }

  getGrowthHoverData(index: number): Array<PieData> {
    const point = this.state.growthData.points[index];
    const output: Array<PieData> = [];

    const zipped = drop(
      zip(this.state.growthData.columns, point).map(([name, value]) => {
        return { name, value };
      }),
      1
    );

    // lodash zip does not drop unmatched elements on either side
    zipped.forEach(({ name, value }) => {
      if (name != null && value != null) output.push({ name, value });
    });

    return output;
  }

  UNSAFE_componentWillMount() {
    this.setState(
      {
        historicalPriceUpdateInterval: setInterval(
          () => this.props.loadHistoricalData(this.props.visibleCoins, this.props.toSymbol),
          2 * 60 * 1000
        ),
      },
      () => this.props.loadHistoricalData(this.props.visibleCoins, this.props.toSymbol)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState(this.buildState(nextProps));
  }

  buildState(nextProps: Props) {
    if (
      difference(nextProps.visibleCoins, this.props.visibleCoins).length > 0 ||
      this.props.toSymbol !== nextProps.toSymbol
    ) {
      this.props.loadHistoricalData(nextProps.visibleCoins, nextProps.toSymbol);
    }

    const filteredAllCoinsData = this.filterCoinData(nextProps);
    let totalValueChartData = this.state.totalValueChartData;
    let totalValueStats = this.state.totalValueStats;
    let growthData = this.state.growthData;

    if (
      filteredAllCoinsData.length &&
      filteredAllCoinsData.length >= this.props.visibleCoins.length &&
      (!isEqual(this.state.filteredAllCoinsData, filteredAllCoinsData) ||
        !isEqual(this.props.coinBalances, nextProps.coinBalances))
    ) {
      const { points } = generateTotalValueData(filteredAllCoinsData, nextProps.coinBalances);
      const yValues: Array<number> = [];
      totalValueChartData = points.map(([x, y]) => {
        yValues.push(y);
        return { x, y };
      });
      totalValueStats = {
        max: Math.max.apply(null, yValues),
        min: Math.min.apply(null, yValues),
      };

      growthData = generateGrowthData(filteredAllCoinsData);
    }

    let currentPriceData = this.state.currentPriceData;
    if (nextProps.highlightCoin) {
      currentPriceData = nextProps.coinPrices[nextProps.highlightCoin][this.props.toSymbol];
    }

    return { filteredAllCoinsData, totalValueChartData, currentPriceData, totalValueStats, growthData };
  }

  filterCoinData(props: Props) {
    return props.allCoinsData.filter(({ coin, data }) => {
      const emptyData = data.length === 0;
      const visible = props.visibleCoins.indexOf(coin) >= 0;
      return !emptyData && visible;
    });
  }

  componentWillUnmount() {
    if (this.state.historicalPriceUpdateInterval) clearInterval(this.state.historicalPriceUpdateInterval);
    this.setState({ historicalPriceUpdateInterval: null });
  }

  render() {
    const chartProperties = {
      highlightCoin: this.props.highlightCoin,
      coinBalances: this.props.coinBalances,
      coinPrices: this.props.coinPrices,
      toSymbol: this.props.toSymbol,
    };

    return (
      <Wrapper>
        <Controls>
          <ToggleButtons
            options={CryptoCompareService.timeRanges.map(range => range.toString())}
            currentSelection={this.props.timerange}
            onSelect={time => this.props.onSelectTime(this.props.visibleCoins, this.props.toSymbol, time as TimeRange)}
          />
        </Controls>
        {this.state.filteredAllCoinsData.length > 0 && (
          <ErrorBoundary>
            <PortfolioSection
              {...chartProperties}
              totalValueChartData={this.state.totalValueChartData}
              min={this.state.totalValueStats.min}
              max={this.state.totalValueStats.max}
              timerange={this.props.timerange}
              toSymbol={this.props.toSymbol}
              onHover={this.onTotalValueHover}
              onHighlightChange={this.props.onHighlightChange}
              portfolioHoverValue={this.state.portfolioHoverValue}
            />
          </ErrorBoundary>
        )}
        <Separator />
        {this.state.filteredAllCoinsData.length > 0 && (
          <IndividualCoinSection
            timerange={this.props.timerange}
            toSymbol={this.props.toSymbol}
            portfolioHoverValue={this.state.portfolioHoverValue}
            onHighlightChange={this.props.onHighlightChange}
            coinPrices={this.props.coinPrices}
            coinBalances={this.props.coinBalances}
            coinInfo={this.props.coinInfo}
            highlightCoin={this.props.highlightCoin}
            individualHoverData={this.state.individualHoverData}
            growthHoverData={
              this.state.growthHoverData || this.getGrowthHoverData(this.state.growthData.points.length - 1)
            }
            currentPriceData={this.state.currentPriceData}
            onIndividualHover={this.onIndividualHover}
            data={this.state.filteredAllCoinsData}
          />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    coinBalances: state.balance.balances,
    timerange: (state.cryptoCompare.timeRange || 'day') as TimeRange,
    allCoinsData: state.balance.coinHistorical,
    coinPrices: state.balance.coinPrices,
    coinInfo: state.api.coinInfo,
    coinHoldings: state.balance.coinHoldings,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSelectTime: (coins: Array<string>, toSymbol: PriceSymbol, time: TimeRange) => {
      analytics.track('ux.timeBar.click', { time, toSymbol });

      dispatch(setTimeRange(time));
      dispatch(resetTimePoints());
      dispatch(fetchAllCoinsHistorical(coins, toSymbol, time));
    },
    loadHistoricalData: (visibleCoins: Array<string>, toSymbol: PriceSymbol) => {
      dispatch(fetchAllCoinsHistorical(visibleCoins, toSymbol));
    },
  };
};

const ChartPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ChartPanel);

export { ChartPanelContainer, ChartPanel, Controls };
