import * as React from 'react';
import { GradientDefs, XYPlot, VerticalBarSeries } from 'react-vis';

import colors from 'app/styles/colors';

const strokeGradientId = 'bar-gradient-1';
const gradientDefs = (
  <GradientDefs>
    <linearGradient id={strokeGradientId} x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={colors.gradient.primary} stopOpacity={1.0} />
      <stop offset="100%" stopColor={colors.gradient.secondary} stopOpacity={1.0} />
    </linearGradient>
  </GradientDefs>
);

export type Point = {
  name: string;
  x: number;
  y: number;
};

type Props = {
  name: string;
  series: Array<Point>;
  highlightCoin: string | undefined;
  onHover?: (coin?: string) => void;
};

class GradientStackedBarChart extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onSeriesMouseOver = this.onSeriesMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onSeriesMouseOver(name: string) {
    return () => {
      if (this.props.onHover) this.props.onHover(name);
    };
  }

  onMouseLeave() {
    if (this.props.onHover) this.props.onHover();
  }

  render() {
    return this.props.series.length ? (
      <XYPlot
        height={200}
        width={200}
        onMouseLeave={this.onMouseLeave}
        className="gradient-stacked-bar-chart"
        stackBy="y"
      >
        {gradientDefs}
        {this.props.series.map((data, i) => {
          let color;
          if (data.name === this.props.highlightCoin) color = `url(#${strokeGradientId})`;
          else {
            const length = this.props.series.length;
            const value = length ? Math.round((i / length) * 128 + 100) : 0;
            color = `rgb(${value}, ${value}, ${value}`;
          }

          return (
            <VerticalBarSeries
              key={data.name}
              data={[data]}
              color={color}
              onSeriesMouseOver={this.onSeriesMouseOver(data.name)}
            />
          );
        })}
      </XYPlot>
    ) : (
      <div>loading</div>
    );
  }
}

export { GradientStackedBarChart };
