import * as React from 'react';
import styled from 'styled-components';

import { GradientBorderButton } from 'app/components/gradient_border_button';

const Wrapper = styled.div`
  text-align: center;

  button {
    outline: 0;
  }
`;

const Box = styled.div``;

type Props = {
  onSelect: (option: string) => void;
  currentSelection: string;
  options: Array<string>;
};

const ToggleButtons = (props: Props) => {
  const renderedButtons = props.options.map(option => (
    <GradientBorderButton
      content={option}
      key={option}
      onClick={() => props.onSelect(option)}
      selected={props.currentSelection === option}
    />
  ));

  return (
    <Wrapper>
      <Box>{renderedButtons}</Box>
    </Wrapper>
  );
};

export { ToggleButtons };
