import * as React from 'react';
import styled from 'styled-components';
import { some } from 'lodash';

import { Input, InputState, StateUpdater, Errors } from 'app/types/typeform';
import { InputSwitch } from 'app/components/typeform/inputs/input_switch';
import { BackButton, SkipButton, ConfirmButton } from 'app/components/typeform/buttons';
import { media } from 'app/utils/responsive';
import colors from 'app/styles/colors';

const Wrapper = styled.div<{ center?: boolean; error?: boolean }>`
  margin: 0px auto;
  text-align: ${props => (props.center ? 'center' : 'left')};

  ${media.desktop} {
    width: 75%;
  }

  ${media.nonDesktop} {
    width: 95%;
  }

  input {
    display: block;
    width: 100%;
    padding: 20px;
    font-size: 20px;
    box-sizing: border-box;
    color: ${colors.text.black};
    border: 0px;
    border-bottom: 1px solid ${colors.ui.grey3};
    border-color: ${props => (props.error ? colors.red : colors.ui.grey3)};

    ${media.nonDesktop} {
      padding: 10px;
      font-size: 16px;
    }

    &::placeholder {
      color: ${colors.ui.grey3};
    }

    &:focus {
      border-color: ${colors.ui.grey7};
    }

    &.error {
      border-color: ${colors.red};
    }
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
  color: ${colors.text.black};

  ${media.nonDesktop} {
    font-size: 25px;
    font-weight: bold;
  }
`;

const Description = styled.p`
  font-size: 16px;
  margin-left: 20px;
  font-weight: lighter;
  color: ${colors.ui.grey6};

  ${media.nonDesktop} {
    font-size: 12px;
    margin: 0px auto;
  }
`;

const DataEntry = styled.div`
  margin-top: 30px;
  padding: 20px 10px;
  text-align: center;

  ${media.nonDesktop} {
    margin-top: 10px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightControls = styled.div`
  text-align: right;
`;

type Props<ParentState extends InputState> = {
  input: Input;
  onNext: () => void;
  onBack: () => void;
  onSkip: () => void;
  updateInputState: StateUpdater;
  parentState: ParentState;
  showBackButton: boolean;
  showSkipButton: boolean;
};

type State = {
  errors: Errors;
};

class InputSection<T extends InputState> extends React.Component<Props<T>, State> {
  isComponentMounted = false;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      errors: {},
    };

    this.updateErrors = this.updateErrors.bind(this);
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  updateErrors(errors: Errors) {
    // since validators are wrapped as a Promise chain, this method can be called
    // after the component has unmounted
    if (this.isComponentMounted) this.setState({ errors });
  }

  render() {
    const props = this.props;
    const errors = this.state.errors;

    const inputEmpty = Array.isArray(this.props.parentState) && this.props.parentState.length === 0;
    const inputInvalid = some(errors, value => value !== null);

    const disabled = (props.input.required && inputEmpty) || inputInvalid;

    return (
      <Wrapper center={props.input.kind === 'step'}>
        <Title>{props.input.title}</Title>
        <Description>{props.input.description}</Description>
        <DataEntry>
          <InputSwitch
            updateErrors={this.updateErrors}
            errors={errors}
            input={props.input}
            updateInputState={props.updateInputState}
            parentState={props.parentState}
            onSubmit={props.onNext}
          />
        </DataEntry>
        <Controls>
          {props.showBackButton ? (
            <BackButton onClick={props.onBack}>{props.input.cancelText || 'Go back'}</BackButton>
          ) : (
            <div />
          )}
          <RightControls>
            {props.showSkipButton && !props.input.required && (
              <SkipButton onClick={props.onSkip}>{props.input.skipText || 'Skip'}</SkipButton>
            )}
            <ConfirmButton disabled={disabled} onClick={props.onNext}>
              {props.input.confirmText || 'Next'}
            </ConfirmButton>
          </RightControls>
        </Controls>
      </Wrapper>
    );
  }
}

export { InputSection };
