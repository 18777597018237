import * as React from 'react';

import { GradientLineChart, Point } from 'app/components/charts/gradient_charts/gradient_line_chart';
import { TimeRange } from 'app/types/interface';
import { PriceSymbol } from 'app/types/interface';

type Props = {
  timerange: TimeRange;
  toSymbol: PriceSymbol;
  onHover?: (x: number | undefined | null) => void;
  data: Array<Point>;
};

class TotalValueChart extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onHover = this.onHover.bind(this);
  }

  onHover(index: number | undefined) {
    if (this.props.onHover) this.props.onHover(index != null ? this.props.data[index].y : null);
  }

  render() {
    return (
      <GradientLineChart
        timerange={this.props.timerange}
        name="total-value"
        series={this.props.data}
        onHover={this.onHover}
        toSymbol={this.props.toSymbol}
      />
    );
  }
}

export { TotalValueChart };
