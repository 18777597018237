import * as React from 'react';
import styled from 'styled-components';

import dimensions from 'app/styles/dimensions';
import colors from 'app/styles/colors';
import CoinholioLogo from 'app/assets/images/logo.white.svg';

const Wrapper = styled.div`
  height: ${dimensions.logoIcon.height}px;
  width: ${dimensions.logoIcon.width}px;
  background-image: linear-gradient(to bottom, ${colors.gradient.primary}, ${colors.gradient.secondary});
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    margin: 0px auto;
  }

  &.loading svg {
    animation: spin-reverse 0.3s linear infinite;
  }
`;

type Props = {
  isLoading?: boolean;
  onClick?: () => void;
};

const CoinholioLogoIcon = ({ isLoading, onClick }: Props) => (
  <Wrapper className={isLoading ? 'loading' : ''} onClick={onClick}>
    <CoinholioLogo />
  </Wrapper>
);

export { CoinholioLogoIcon };
