import * as React from 'react';
import styled from 'styled-components';
import { head, find, findLast } from 'lodash';

import { CoinInfo, Order, CoinData } from 'app/types/api';
import { CoinAmount } from 'app/components/order/coin_amount';
import { CoinHistory, CoinPrices } from 'app/types/interface';
import { DisplayPercent, DisplayDollars } from 'app/components/display_value';

const Strong = styled.strong<{ isGood?: boolean }>`
  color: ${props => (props.isGood ? 'green' : 'red')};
  font-weight: bolder;
  padding: 5px;
  display: inline-block;
`;

const TransferArrow = (props: { isGood?: boolean }) => <Strong {...props}>&rArr;</Strong>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TradeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px;
`;

const ExtraInfoWrapper = styled.div`
  margin-top: 20px;
  width: 80%;
`;

type Props = {
  order: Order;
  coinInfo: CoinInfo;
  isLoading: boolean;
  allCoinsData: Array<CoinHistory>;
  coinPrices: CoinPrices;
};

const lookupCoinInfo = (coin: string, coinInfo: CoinInfo): CoinData | undefined => find(coinInfo, ['symbol', coin]);

const getPriceAtTime = (coin: string, tradeTimestamp: number, data: Array<CoinHistory>): number => {
  const searchData = find(data, ['coin', coin]);
  if (searchData) {
    const point =
      findLast(searchData.data, ({ time }) => time * 1000 <= new Date(tradeTimestamp).getTime()) ||
      head(searchData.data);
    if (point) return point.price.value;
  }

  return 0;
};

const FromTo = (props: Props) => {
  const currentToPrice = props.coinPrices[props.order.to].USD.current.price * props.order.toAmount;
  const currentFromPrice = props.coinPrices[props.order.from].USD.current.price * props.order.fromAmount;
  const goodTrade = currentToPrice >= currentFromPrice;

  let valueAtTradeTime: number | null = null;
  const bitcoinPriceAtTradeTime = getPriceAtTime('BTC', props.order.timestamp, props.allCoinsData);
  const ethereumPriceAtTradeTime = getPriceAtTime('ETH', props.order.timestamp, props.allCoinsData);
  if (props.order.to === 'BTC' || props.order.from === 'BTC') {
    valueAtTradeTime =
      props.order.to === 'BTC'
        ? props.order.toAmount * bitcoinPriceAtTradeTime
        : props.order.fromAmount * bitcoinPriceAtTradeTime;
  } else if (props.order.to === 'ETH' || props.order.from === 'ETH') {
    valueAtTradeTime =
      props.order.to === 'BTC'
        ? props.order.toAmount * ethereumPriceAtTradeTime
        : props.order.fromAmount * ethereumPriceAtTradeTime;
  } else if (props.order.to === 'USDT' || props.order.from === 'USDT') {
    valueAtTradeTime = props.order.to === 'USDT' ? props.order.toAmount : props.order.fromAmount;
  }

  const tradeChange = valueAtTradeTime ? currentToPrice - valueAtTradeTime : 0;

  return (
    <Wrapper>
      <TradeWrapper>
        <CoinAmount
          coin={props.order.from}
          amount={props.order.fromAmount}
          icon={lookupCoinInfo(props.order.from, props.coinInfo)}
          cashValue={currentFromPrice}
          goodTrade={currentFromPrice > currentToPrice}
        />
        <TransferArrow isGood={goodTrade} />
        <CoinAmount
          coin={props.order.to}
          amount={props.order.toAmount}
          icon={lookupCoinInfo(props.order.to, props.coinInfo)}
          cashValue={currentToPrice}
          goodTrade={currentToPrice > currentFromPrice}
        />
      </TradeWrapper>
      {valueAtTradeTime && (
        <ExtraInfoWrapper>
          <Line>
            <strong>Value at Trade Time</strong>
            <DisplayDollars value={valueAtTradeTime} change={tradeChange} />
          </Line>
          <Line>
            <strong>Total {tradeChange > 0 ? 'Gains' : 'Losses'}</strong>
            <DisplayDollars value={currentToPrice - valueAtTradeTime} change={tradeChange} />
          </Line>
          <Line>
            <strong>{tradeChange > 0 ? 'Gain' : 'Loss'} Percentage</strong>
            <DisplayPercent value={(currentToPrice - valueAtTradeTime) / valueAtTradeTime} change={tradeChange} />
          </Line>
        </ExtraInfoWrapper>
      )}
    </Wrapper>
  );
};

export { FromTo };
