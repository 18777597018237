import { applyMiddleware, createStore, combineReducers, compose, Middleware, Reducer, Store, Action } from 'redux';
import reduxThunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware, RouterAction } from 'connected-react-router';

import { isDevelop } from 'app/config';
import { tracker } from 'app/store/tracker';
import { ApiActions } from 'app/store/modules/api/actions';
import { reducer as apiReducer } from 'app/store/modules/api/reducer';
import { AuthActions } from 'app/store/modules/auth/actions';
import { reducer as authReducer } from 'app/store/modules/auth/reducer';
import { UserActions } from 'app/store/modules/user/actions';
import { reducer as userReducer } from 'app/store/modules/user/reducer';
import { BalanceActions } from 'app/store/modules/balance/actions';
import { reducer as balanceReducer } from 'app/store/modules/balance/reducer';
import { CryptoCompareActions, reducer as cryptoCompareReducer } from 'app/store/modules/crypto_compare';
import { State } from 'app/store/types';

export const history = createBrowserHistory();

export type AllActions = ApiActions | AuthActions | BalanceActions | CryptoCompareActions | UserActions | RouterAction;

const middleware: Array<Middleware> = [routerMiddleware(history), reduxThunkMiddleware, tracker];
if (isDevelop) middleware.push(createLogger({ collapsed: true, timestamp: false }));

history.listen(location => {
  window.analytics.track('router', {
    pathname: location.pathname,
    initialAppLoad: false,
  });
});

/**
 * Set up redux dev tools, if available.
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const reducer: Reducer<State, AllActions> = combineReducers({
  api: apiReducer,
  auth: authReducer,
  balance: balanceReducer,
  user: userReducer,
  cryptoCompare: cryptoCompareReducer,
  router: connectRouter(history),
});

export const configureStore = (): Store<State, Action<AllActions>> =>
  createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));
