import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const DataSection = styled.div`
  padding: 0px 10px;
  padding-bottom: 0px;
  min-width: 100px;
  & + & {
    border-left: 1px solid ${colors.ui.grey2};
  }
`;

const SectionTitle = styled.h3`
  text-transform: uppercase;
  color: ${colors.ui.grey6};
  font-size: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.8px;
`;

const SectionContent = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.text.darkBlue};
`;

export type Section = {
  title: string;
  content: React.ReactNode;
};

type Props = {
  data: Array<Section>;
};

const DataSections = (props: Props) => {
  const sections = props.data.map(section => (
    <DataSection key={section.title}>
      <SectionTitle>{section.title}</SectionTitle>
      <SectionContent>{section.content}</SectionContent>
    </DataSection>
  ));
  return <Wrapper>{sections}</Wrapper>;
};

export { DataSections, DataSection };
