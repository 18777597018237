import { EventTypes, createTracker, Mappers } from 'redux-segment';

const trackProperties =
  <T>(event: string) =>
  (_: unknown, action: { payload: T }) => {
    return {
      eventType: EventTypes.track,
      eventPayload: { event, properties: action.payload },
    };
  };

const customMapper: Mappers = {
  skipDefaultMapping: true,
  mapper: {
    '@@router/LOCATION_CHANGE': (_, action) => {
      return {
        eventType: EventTypes.page,
        eventPayload: { name: action.payload.pathname, properties: { reactRouted: true } },
      };
    },
  },
};

customMapper.mapper.logout = EventTypes.track;
customMapper.mapper.authFailed = trackProperties('login.authFailed');
customMapper.mapper.completeUpdateSettings = trackProperties('settings.update');

const tracker = createTracker(customMapper);

export { tracker };
