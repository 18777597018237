import { chunk } from 'lodash';

import { CoinHistory, HistoricalPoint } from 'app/types/interface';
import { CoinBalances } from 'app/types/api';

type TimePointData = Array<number>;

export type PondTransformable = {
  columns: Array<string>;
  points: Array<TimePointData>;
};

type CoinExtreme = {
  [key: string]: number;
};

type CoinBounds = {
  coinMaxInGraph: CoinExtreme;
  coinMinInGraph: CoinExtreme;
};

const buildPondData = (
  data: Array<CoinHistory>,
  outputColumns: Array<string>,
  singlePointFunction: (data: Array<CoinHistory>, length: number, index: number) => TimePointData
): PondTransformable => {
  const columns = ['time', ...outputColumns];
  const numPoints = data[0].data.length;
  const output: Array<TimePointData> = [];

  for (let i = 0; i < numPoints; i += 1) {
    const currentTime = data[0].data[i].time * 1000;
    const point = singlePointFunction(data, outputColumns.length, i);
    output.push([currentTime, ...point]);
  }

  return { columns, points: output };
};

export const getBounds = (historicalData: Array<CoinHistory>): CoinBounds => {
  const coinMinInGraph: { [coin: string]: number } = {};
  const coinMaxInGraph: { [coin: string]: number } = {};
  const numPoints = historicalData[0].data.length;

  for (let i = 0; i < numPoints; i += 1) {
    historicalData.forEach(({ coin, data }) => {
      const value = (data[i] && data[i].price.value) || 0;

      if (!(coin in coinMinInGraph)) coinMinInGraph[coin] = value;
      else if (coinMinInGraph[coin] > value) coinMinInGraph[coin] = value;

      if (!(coin in coinMaxInGraph)) coinMaxInGraph[coin] = value;
      else if (coinMaxInGraph[coin] < value) coinMaxInGraph[coin] = value;
    });
  }

  return { coinMinInGraph, coinMaxInGraph };
};

export const generateTotalValueData = (
  historicalData: Array<CoinHistory>,
  coinBalances: CoinBalances
): PondTransformable => {
  const columnNames = ['value'];
  return buildPondData(historicalData, columnNames, (data, _length, i) => {
    const value = data.reduce(
      (sum, obj) => sum + ((obj.data[i] && obj.data[i].price.value) || 0) * (coinBalances[obj.coin] || 0),
      0
    );
    return [value];
  });
};

export const generateStreamData = (historicalData: Array<CoinHistory>): PondTransformable => {
  const { coinMinInGraph, coinMaxInGraph } = getBounds(historicalData);
  const columnNames = historicalData.map(obj => obj.coin);

  return buildPondData(historicalData, columnNames, (data, _length, i) =>
    data.map(obj => {
      const denominator = coinMaxInGraph[obj.coin] - coinMinInGraph[obj.coin];
      return denominator ? (obj.data[i].price.value - coinMinInGraph[obj.coin]) / denominator : 0;
    })
  );
};

export const generateGrowthData = (historicalData: Array<CoinHistory>): PondTransformable => {
  const { coinMinInGraph, coinMaxInGraph } = getBounds(historicalData);
  const columnNames = historicalData.map(obj => obj.coin);

  return buildPondData(historicalData, columnNames, (data, length, i) => {
    // value zeroed out to the lowest point and scaled by the highest point
    const percentagePoint = data.map(obj => {
      const min = coinMinInGraph[obj.coin];
      return obj.data[i] && obj.data[i].price.value && coinMaxInGraph[obj.coin] !== 0 && min
        ? (obj.data[i].price.value - coinMinInGraph[obj.coin]) / min
        : 0;
    });

    // sum of changes per time point, used to scale below
    const pointSum = percentagePoint.reduce((sum, value) => sum + value, 0);

    // scale points by sum to make them sum up to 1
    return percentagePoint.map(value => {
      // protect against a crash (all points go to zero at the same time)
      if (pointSum === 0) return 1.0 / length;
      else return value / pointSum;
    });
  });
};

type SmoothedPoint = {
  time: number;
  close: number;
};

type SmoothedData = {
  coin: string;
  data: Array<SmoothedPoint>;
};

export const smoothHistoricalData = (
  historicalData: Array<CoinHistory>,
  targetPointCount: number
): Array<SmoothedData> => {
  const pointCount = historicalData[0].data.length;
  const windowCount = Math.max(Math.floor(pointCount / targetPointCount), 1);

  return historicalData.map(({ coin, data }) => {
    const smoothedData = chunk(data, windowCount).reduce(
      (output: Array<SmoothedPoint>, rows: Array<HistoricalPoint>) => {
        const chunkTime: number = rows[0].time;
        const averaged = rows.reduce((sum, { price: { value } }) => sum + value, 0) / rows.length;
        const newBlock = { time: chunkTime, close: averaged };

        return [...output, newBlock];
      },
      []
    );
    return { coin, data: smoothedData };
  });
};

// takes a list of time series data and reduces it by averaging windows
export const smoothGraphData = (pondData: PondTransformable, targetPointCount: number): PondTransformable => {
  const pointCount = pondData.points.length;
  const windowCount = Math.floor(pointCount / targetPointCount);
  const columnCount = pondData.columns.length - 1;

  const points: Array<TimePointData> = chunk(pondData.points, windowCount).reduce(
    (outputPondData: Array<TimePointData>, rows: Array<TimePointData>) => {
      const chunkTime: number = rows[0][0];
      const averagedBlock: TimePointData = [chunkTime];

      for (let columnIndex = 1; columnIndex <= columnCount; columnIndex += 1) {
        const averageValue = rows.reduce((sum, row) => sum + row[columnIndex], 0) / rows.length;
        averagedBlock.push(averageValue);
      }

      return [...outputPondData, averagedBlock];
    },
    []
  );

  return { columns: pondData.columns, points };
};
