import * as React from 'react';
import { Link } from 'react-router-dom';

export type Props = {
  to: string;
  children?: React.ReactNode;
};

const NavigationLink = (props: Props) => <Link {...props} />;

export default NavigationLink;
