import * as coinholio from './interface';

export interface RateLimit {
  calls_made: {
    second: number;
    minute: number;
    hour: number;
    day: number;
    month: number;
    total_calls: number;
  };
  max_calls: {
    second: number;
    minute: number;
    hour: number;
    day: number;
    month: number;
  };
}

export type ResponsePackage<Json> = {
  Aggregated?: boolean;
  ConversionType?: {
    conversionSymbol: string;
    type: string;
  };
  FirstValueInArray?: boolean;
  HasWarning?: boolean;
  RateLimit?: RateLimit | {};
  TimeFrom?: number;
  TimeTo?: number;
  Type?: number;
  Message?: string;
  Response: 'Error' | 'Success';
  Data: Array<Json>;
  data?: Array<Json>;
};

export type CurrentPriceData = {
  CHANGE24HOUR: number;
  CHANGEDAY: number;
  CHANGEHOUR?: number;
  CHANGEPCT24HOUR: number;
  CHANGEPCTDAY: number;
  CHANGEPCTHOUR?: number;
  CONVERSIONSYMBOL?: string;
  CONVERSIONTYPE?: string;
  FLAGS: string;
  FROMSYMBOL: string;
  HIGH24HOUR: number;
  HIGHDAY: number;
  HIGHHOUR?: number;
  IMAGEURL?: string;
  LASTMARKET: string;
  LASTTRADEID: string;
  LASTUPDATE: number;
  LASTVOLUME: number;
  LASTVOLUMETO: number;
  LOW24HOUR: number;
  LOWDAY: number;
  LOWHOUR?: number;
  MARKET: string;
  MEDIAN?: number;
  MKTCAP: number;
  OPEN24HOUR: number;
  OPENDAY: number;
  OPENHOUR?: number;
  PRICE: number;
  SUPPLY: number;
  TOPTIERVOLUME24HOUR?: number;
  TOPTIERVOLUME24HOURTO?: number;
  TOSYMBOL: string;
  TOTALTOPTIERVOLUME24H?: number;
  TOTALTOPTIERVOLUME24HTO?: number;
  TOTALVOLUME24H: number;
  TOTALVOLUME24HTO: number;
  TYPE: string;
  VOLUME24HOUR: number;
  VOLUME24HOURTO: number;
  VOLUMEDAY: number;
  VOLUMEDAYTO: number;
  VOLUMEHOUR?: number;
  VOLUMEHOURTO?: number;
};

export type CoinConversions = Record<coinholio.PriceSymbol, CurrentPriceData>;

type CoinName = string;

export type CoinPrices = Record<CoinName, CoinConversions>;

export type CoinPricesPayload = {
  RAW: CoinPrices;
};

export type CoinSnapshotData = {
  close: number;
  high: number;
  low: number;
  open: number;
  /* seconds */
  time: number;
  volumefrom: number;
  volumeto: number;
};

export type HistoricalData = Array<{
  coin: string;
  data: Array<CoinSnapshotData>;
}>;

export const convertToInterfaceSnapshotData = (data: Array<CoinSnapshotData>): Array<coinholio.HistoricalPoint> => {
  return data.map(({ close, high, low, open, time, volumefrom, volumeto }) => ({
    time,
    price: {
      value: close,
      close,
      high,
      low,
      open,
    },
    volume: {
      to: volumeto,
      from: volumefrom,
      total: volumeto + volumefrom,
    },
  }));
};

export const convertToInterfaceCoinPrices = (data: CoinPrices): coinholio.CoinPrices =>
  Object.fromEntries(
    Object.entries(data).map(([coin, conversions]) => [
      coin,
      {
        USD: convertToInterface(conversions.USD),
        BTC: convertToInterface(conversions.BTC),
      },
    ])
  );

export const convertToInterface = (data: CurrentPriceData): coinholio.CurrentCoinData => ({
  change: {
    currency: {
      hour24: data.CHANGE24HOUR,
      day: data.CHANGEDAY,
    },
    percent: {
      hour24: data.CHANGEPCT24HOUR,
      day: data.CHANGEPCTDAY,
    },
  },
  current: {
    price: data.PRICE,
    supply: data.SUPPLY,
    marketCap: data.MKTCAP,
    hour24: {
      high: data.HIGH24HOUR,
      low: data.LOW24HOUR,
      totalVolume: data.TOTALVOLUME24H,
    },
  },
});
