import styled from 'styled-components';

import colors from 'app/styles/colors';

export const Input = styled.input<{ error?: unknown }>`
  height: 46px;
  color: ${colors.text.black};
  border: 0px;
  font-size: 14px;
  display: block;
  width: 320px;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 15px;
  border-bottom: 1px solid ${colors.ui.grey3};
  border-color: ${props => (props.error ? colors.red : colors.ui.grey3)};

  &::placeholder {
    color: ${colors.ui.grey4};
  }

  &:focus {
    border-color: ${props => (props.error ? colors.red : colors.ui.grey7)};
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  &:disabled {
    color: ${colors.ui.grey3};
    border-color: ${colors.ui.grey2};
  }
`;
