import styled from 'styled-components';

import colors from 'app/styles/colors';

const FormError = styled.div`
  color: ${colors.red};
  margin-bottom: 15px;
  font-size: 12px;
`;

export { FormError };
