import hash from 'object-hash';
import * as React from 'react';
import styled from 'styled-components';

import { FormSection } from 'app/components/form/form_section';
import { GradientBorderButton } from 'app/components/gradient_border_button';
import { media } from 'app/utils/responsive';
import colors from 'app/styles/colors';

const Right = styled.div`
  text-align: right;
`;

const Ul = styled.ul`
  margin: 10px auto;

  .input-element {
    margin-top: 20px;
  }
`;

const DeleteButton = styled.button`
  color: red;
  padding: 5px;
  width: 30px;
  height: 30px;
  line-height: 15px;
  cursor: pointer;
  border-radius: 15px;

  &:after {
    content: '×';
  }

  ${media.desktop} {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 10;
  }

  ${media.nonDesktop} {
    position: relative;
    margin-left: 10px;
  }
`;

const Li = styled.li`
  position: relative;
  padding: 8px 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;

  ${media.desktop} {
    ${DeleteButton} {
      opacity: 0;
    }
  }

  ${media.nonDesktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  input {
    display: block;
  }

  input + input {
    margin-top: 3px;
  }

  & + & {
    border-top: 1px solid ${colors.ui.grey3};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);

    ${DeleteButton} {
      opacity: 1;
    }
  }
`;

const Grow = styled.span`
  flex-grow: 1;
`;

export type Item = { [key: string]: string | number };

// type Items = Array<Item> | Array<string>;

type EventHandler = (event: React.SyntheticEvent<HTMLInputElement>) => void;

type Props = {
  labelFor: string;
  items: Array<Item> | Array<string>;
  label: string;
  updateList: (items: Array<Item> | Array<string>) => void;
  renderItem?: (item: string | Item) => React.ReactNode;
  renderInput?: (
    f1: (name: string) => EventHandler,
    f2: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    item: Item | string
  ) => React.ReactNode;
};

type State = {
  addingNew: boolean;
  currentNewItem: Item;
};

class EditList extends React.Component<Props, State> {
  singleKey: string;

  constructor(props: Props) {
    super(props);

    this.singleKey = '__value';

    this.state = {
      addingNew: false,
      currentNewItem: {},
    };

    this.addNew = this.addNew.bind(this);
    this.submitNew = this.submitNew.bind(this);
    this.cancelNew = this.cancelNew.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.updateCurrentItem = this.updateCurrentItem.bind(this);
    this.onKeypress = this.onKeypress.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  onKeypress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') this.submitNew(event);
    else if (event.keyCode === 27) this.cancelNew(event);
  }

  deleteItem(index: number) {
    return (event: React.SyntheticEvent) => {
      const newItems = this.props.items.slice();
      const [deleted] = newItems.splice(index, 1);
      this.props.updateList(newItems);

      analytics.track(`ux.editList.deleteItem.${this.props.labelFor}`, { deleted });

      event.preventDefault();
    };
  }

  cancelNew(event: React.SyntheticEvent | undefined, source?: Object) {
    if (event) event.preventDefault();

    analytics.track(`ux.editList.cancelNew.${this.props.labelFor}`, { fromKeyboard: !!source });

    this.setState({ addingNew: false, currentNewItem: {} });
  }

  submitNew(event: React.SyntheticEvent | undefined, source?: Object) {
    if (event) event.preventDefault();

    let newItem: Item | string | number = this.state.currentNewItem;
    if (this.singleKey in newItem) newItem = newItem[this.singleKey];
    analytics.track(`ux.editList.submitNew.${this.props.labelFor}`, { fromKeyboard: !!source, newItem });

    // @ts-ignore FIX: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-3.html#improved-behavior-for-calling-union-types
    const update = () => this.props.updateList((this.props.items || []).concat(newItem));

    this.setState({ addingNew: false, currentNewItem: {} }, update);
  }

  updateCurrentItem(key: string) {
    return ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) =>
      this.setState({
        currentNewItem: { ...this.state.currentNewItem, [key]: currentTarget.value },
      });
  }

  addNew() {
    this.setState({ addingNew: true });
  }

  renderInput() {
    return this.props.renderInput ? (
      this.props.renderInput(this.updateCurrentItem, this.onKeypress, this.state.currentNewItem)
    ) : (
      <input
        id={this.props.labelFor}
        onChange={this.updateCurrentItem(this.singleKey)}
        onKeyPress={this.onKeypress}
        onKeyUp={this.onKeypress}
        value={this.state.currentNewItem?.[this.singleKey] || ''}
      />
    );
  }

  renderItem(item: string | Item): React.ReactNode {
    return this.props.renderItem ? this.props.renderItem(item) : item;
  }

  renderItems(): React.ReactNode {
    return (
      this.props.items &&
      (this.props.items as Array<string | Item>).map((item, i) => (
        <Li key={`item-${hash(item)}`}>
          <Grow>{this.renderItem(item)}</Grow>
          <DeleteButton onClick={this.deleteItem(i)} />
        </Li>
      ))
    );
  }

  render() {
    return (
      <FormSection>
        <label htmlFor={this.props.labelFor}>{this.props.label}:</label>
        <Ul>
          {this.renderItems()}
          {this.state.addingNew && (
            <Li key="input-element" className="input-element">
              {this.renderInput()}
              <br />
              <GradientBorderButton content="Add Item" onClick={this.submitNew} />
              <GradientBorderButton content="Cancel" onClick={this.cancelNew} selected={false} />
            </Li>
          )}
        </Ul>
        {!this.state.addingNew && (
          <Right>
            <GradientBorderButton content="Add" onClick={this.addNew} />
          </Right>
        )}
      </FormSection>
    );
  }
}

export { Li, EditList, DeleteButton };
