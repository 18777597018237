import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import t from 'app/utils/i18n';
import { GradientBorderButton } from 'app/components/gradient_border_button';
import { media } from 'app/utils/responsive';

const Wrapper = styled.div`
  padding: 0px 80px;
  margin-top: 100px;

  h1,
  h2 {
    pointer-events: none;
  }

  h1 {
    font-size: 55px;
    font-weight: 900;
    line-height: 1.16;
    color: ${colors.text.brightBlue};
    margin-bottom: 30px;
  }

  h2 {
    opacity: 0.6;
    font-size: 22px;
    font-weight: lighter;
    line-height: 1.86;
    color: ${colors.text.black};
    margin-bottom: 20px;
  }

  ${media.nonDesktop} {
    padding: 0px;
    margin: 0px;
    margin-top: 120px;
    text-align: center;

    h1 {
      font-size: 40px;
    }

    h2 {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`;

const Content = styled.div`
  width: 555px;

  ${media.nonDesktop} {
    width: 100%;
  }
`;

type Props = {
  onAction: () => void;
};

const Jumbotron = (props: Props) => (
  <Wrapper>
    <Content>
      <h1>{t('home.jumbotron.title')}</h1>
      <h2>{t('home.jumbotron.subtitle')}</h2>
      <p>
        <GradientBorderButton content={t('home.jumbotron.action')} onClick={props.onAction} large />
      </p>
    </Content>
  </Wrapper>
);

export { Jumbotron };
