import { head, filter } from 'lodash';
import { ParsedUrlQueryInput } from 'querystring';

import { CoinSnapshotData, ResponsePackage, CoinPrices, CoinPricesPayload } from 'app/types/crypto_compare';
import { TimeRange } from 'app/types/interface';
import WebRequestService from 'app/services/web_request_service';

const maximumRetriedCalls = 3;

const checkRateLimit = <T>(json: ResponsePackage<T>) =>
  json.Response === 'Error' && json.Message?.toLowerCase().includes('rate limit');

const timeToParams = {
  hour: { path: 'data/histominute', datapointCount: 60 },
  '6 hours': { path: 'data/histominute', datapointCount: 60 * 6 },
  day: { path: 'data/histominute', datapointCount: 60 * 24 },
  week: { path: 'data/histohour', datapointCount: 24 * 7 },
  '2 weeks': { path: 'data/histohour', datapointCount: 24 * 7 * 2 },
  month: { path: 'data/histohour', datapointCount: 24 * 31 },
  '3 months': { path: 'data/histoday', datapointCount: 31 * 3 },
  '6 months': { path: 'data/histoday', datapointCount: 31 * 6 },
  year: { path: 'data/histoday', datapointCount: 365 },
};

const configurePriceHistoryResolution = (time: TimeRange) => {
  const { path, datapointCount } = timeToParams[time] || { path: 'data/histohour', datapointCount: 144 };
  const targetResolution = 100;
  const ratio = datapointCount / targetResolution;
  const aggregate = ratio > 1 ? Math.ceil(ratio) : 1;
  const limit = Math.ceil(datapointCount / aggregate);

  return { aggregate, limit, path };
};

class CryptoCompareService extends WebRequestService<ResponsePackage<unknown>> {
  static timeRanges: Array<TimeRange> = ['hour', 'day', 'week', 'month', 'year'];
  baseUrl: string;

  constructor() {
    super();
    this.baseUrl = 'https://min-api.cryptocompare.com/';
  }

  /**
   * unused
   */
  getCoinInfo() {
    const endpoint = 'https://www.cryptocompare.com/api/data/coinlist/';

    return fetch(endpoint, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(this.handleResponse.bind(this))
      .catch(err => console.error(`Request to ${endpoint} failed: ${err}`));
  }

  getJsonWithRateLimit<T>(endpoint: string, jsonRequestData: ParsedUrlQueryInput = {}, callCount = 1): Promise<T> {
    return this.getJson(endpoint, jsonRequestData).then(json => {
      if (checkRateLimit(json)) {
        if (callCount > maximumRetriedCalls) {
          console.error('rate limited', json);
          throw new Error('hitting a rate limit from cryptocompare api');
        } else {
          console.warn(`retrying rate limited request: ${endpoint}`, jsonRequestData, callCount);
          return new Promise(resolve => {
            setTimeout(
              () => resolve(this.getJsonWithRateLimit(endpoint, jsonRequestData, callCount + 1)),
              callCount * 1000
            );
          });
        }
      } else return json;
    });
  }

  getPrice(coins: Array<string>, currencies: Array<string> = ['USD', 'BTC']): Promise<CoinPrices> {
    // cryptocompare gives bad data for this coin currently
    const filteredCoins = filter(coins, coin => coin !== 'Ohni');

    return this.getJsonWithRateLimit<CoinPricesPayload>('data/pricemultifull', {
      fsyms: filteredCoins.join(','),
      tsyms: currencies.join(','),
    }).then(json => json.RAW);
  }

  async getPriceHistory(coin = 'BTC', time: TimeRange = 'day', toSymbol = 'USD'): Promise<Array<CoinSnapshotData>> {
    const { path, aggregate, limit } = configurePriceHistoryResolution(time);

    try {
      const result = await this.getJsonWithRateLimit<Array<CoinSnapshotData>>(path, {
        aggregate,
        limit,
        fsym: coin,
        tsym: toSymbol,
        e: 'CCCAGG',
      });
      return result;
    } catch (err) {
      if (err.message.match('no data')) return [];
      else throw err;
    }
  }

  extractJsonData(json: ResponsePackage<unknown>) {
    // rate limited requests return 200 so we pass through the json
    if (json.Data === undefined || checkRateLimit(json)) return json;
    else if (json.Response === 'Error') throw new Error('no data');
    else return json.Data.length === 1 ? head(json.Data) : json.Data;
  }
}

export default CryptoCompareService;
