import styled from 'styled-components';

export const SideChartWrapper = styled.div`
  width: 350px;
  text-align: center;

  .gradient-stacked-bar-chart {
    width: 80px !important;
    height: 170px !important;
    position: relative;
    top: -15px;
    left: -35px;
  }
`;
