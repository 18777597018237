import * as React from 'react';
import styled from 'styled-components';

import { DisplayDollars, DisplayPercent, DisplayLargeValue } from 'app/components/display_value';
import { CoinHistory, CoinPrices } from 'app/types/interface';
import { CoinInfo } from 'app/types/api';
import colors from 'app/styles/colors';

type Props = {
  coin: string;
  data: Array<CoinHistory>;
  coinInfo: CoinInfo;
  coinPrices: CoinPrices;
};

const Wrapper = styled.div`
  width: 700px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px 70px;
`;

const Name = styled.div`
  color: ${colors.text.brightBlue};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.3px;
  margin-bottom: 3px;
`;

const DataBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const BigValue = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: ${colors.text.black};
  width: 230px;
  padding-top: 10px;
`;

const DataBlocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
`;

const DataBlock = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.text.black};
  width: 125px;
  padding: 5px 0px;
  padding-left: 10px;
  border-left: 1px solid transparent;
  height: 30px;

  & + & {
    border-left-color: ${colors.ui.grey3};
  }
`;

const Title = styled.div`
  font-size: 10px;
  font-weight: normal;
  opacity: 0.6;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 0.8px;
`;

const MiniPercent = styled.span`
  font-size: 10px;
  margin-left: 3px;
`;

const Floater = (props: Props) => {
  const coinData = props.coinPrices[props.coin].USD;

  return (
    <Wrapper>
      <Name>{props.coin}</Name>
      <DataBar>
        <BigValue>
          <DisplayDollars value={coinData.current.price} />
        </BigValue>
        <DataBlocks>
          <DataBlock>
            <Title>24hr change</Title>
            <DisplayDollars value={coinData.change.currency.day} />
            <MiniPercent>
              <DisplayPercent value={coinData.change.percent.day / 100} change={-1} />
            </MiniPercent>
          </DataBlock>
          <DataBlock>
            <Title>total supply</Title>
            <DisplayLargeValue value={coinData.current.supply} />
          </DataBlock>
          <DataBlock>
            <Title>market cap</Title>
            <DisplayDollars value={coinData.current.marketCap} />
          </DataBlock>
        </DataBlocks>
      </DataBar>
    </Wrapper>
  );
};

export { Floater };
