import * as React from 'react';
import styled from 'styled-components';

import t from 'app/utils/i18n';

const Header = styled.div`
  font-size: 32px;
  color: #666;
`;

const NotFoundComponent = () => <Header> {t('errors.notFound')} </Header>;

export default NotFoundComponent;
