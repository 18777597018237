import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PageVisibility from 'react-page-visibility';

import { LoginContainer } from 'app/pages/login';
import { logout, checkLoginState } from 'app/store/modules/auth/actions';
import Footer from 'app/components/footer';
import { StaticLoadingSpinner } from 'app/components/loading_spinner';
import { State as GlobalState } from 'app/store/types';

const Wrapper = styled.div`
  height: 100%;
`;

const Contents = styled.div`
  width: 100%;
  margin: 0px auto;
  flex: 1 1 auto;
  min-height: 500px;
  height: 100%;
`;

type Props = {
  children: React.ReactNode;
  loggedIn: boolean | undefined | null;
  currentPage: string;
  onFirstLoad: () => void;
  onLogout: () => void;
};

type State = {
  lastVisibilityChange: number;
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lastVisibilityChange: Date.now(),
    };

    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!this.props.loggedIn) this.props.onFirstLoad();
  }

  onVisibilityChange(visibilityState: string, documentHidden: boolean) {
    const duration = Date.now() - this.state.lastVisibilityChange;
    analytics.track(`visibility.page.${visibilityState}`, { duration, documentHidden });
    this.setState({ lastVisibilityChange: Date.now() });
  }

  renderDashboardOrLogin() {
    if (this.props.loggedIn) return this.props.children;
    else if (this.props.loggedIn == null) return <StaticLoadingSpinner large />;
    else return <LoginContainer />;
  }

  render() {
    return (
      <PageVisibility onChange={this.onVisibilityChange}>
        <Wrapper>
          <Contents>{this.renderDashboardOrLogin()}</Contents>
          <Footer />
        </Wrapper>
      </PageVisibility>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    loggedIn: !!state.auth.loggedIn,
    currentPage: state.router.location.pathname,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onLogout: () => {
      dispatch(logout());
    },
    onFirstLoad: () => {
      dispatch(checkLoginState());
    },
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export { App, AppContainer };
