import * as React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import colors from 'app/styles/colors';
import NavigationLink from 'app/components/navigationLink';
import { media } from 'app/utils/responsive';
import { Span, DisplayDollars, DisplayPercent, DisplayValue } from 'app/components/display_value';
import { CoinPrices, PriceSymbol } from 'app/types/interface';
import { CoinBalances } from 'app/types/api';
import ArrowUp from 'app/assets/images/mini.arrow.up.svg';
import ArrowDown from 'app/assets/images/mini.arrow.down.svg';

const VerticalCenter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 3px;
  }
`;

const Label = styled.h2`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.8px;
  color: ${colors.text.grey};
  margin-bottom: 5px;
`;

const BigDollarValues = styled.span`
  color: ${colors.text.darkBlue};
  font-size: 36px;
  font-weight: 900;
  line-height: 30px;
`;

const SmallPercentageValue = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-size: 24px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  padding-bottom: 20px;
  padding-left: 18px;
  margin-bottom: 10px;

  ${media.mobile} {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const LabelAndInfo = styled.div`
  font-size: 26px;

  ${Span} {
    font-weight: bold;
  }
`;

const TopSubInfo = styled.div`
  font-size: 18px;
  margin-top: 20px;

  ${LabelAndInfo} {
    display: inline-block;
    width: 50%;
  }

  ${media.mobile} {
    font-size: 16px;
    margin: 10px 0px;
  }
`;

const DataHolder = styled.div`
  display: flex;
  align-items: flex-end;
`;

type Props = {
  totalCashInvested: number | undefined;
  coinPrices: CoinPrices;
  coinBalances: CoinBalances;
  dollarValue: (valu: string) => number;
  coinPrice: (valu: string) => number;
  toSymbol: PriceSymbol;
};

const TopInfo = (props: Props) => {
  const coins = Object.keys(props.coinBalances);
  const loadedData = !isEmpty(coins) && !isEmpty(props.coinPrices);
  const dollarPerBitcoin = props.coinPrices.BTC ? props.coinPrices.BTC.USD.current.price : 0;
  const loadedInvestment = props.totalCashInvested !== undefined;
  const investment = props.totalCashInvested || 0;
  const totalInvested = props.toSymbol === 'BTC' ? investment / dollarPerBitcoin : investment;

  const total = coins.map(coin => props.dollarValue(coin)).reduce((sum, i) => sum + i, 0);
  let totalChange = total / totalInvested - 1.0;
  if (isNaN(totalChange)) totalChange = 0;
  const gains = total - totalInvested;
  const dollarGains = props.toSymbol === 'BTC' ? gains * dollarPerBitcoin : gains;

  return (
    <Wrapper>
      {loadedData &&
        (loadedInvestment ? (
          <LabelAndInfo>
            <Label>Portfolio Value</Label>
            <DataHolder style={{ height: 46 }}>
              <BigDollarValues>
                {investment === 0 ? (
                  <DisplayValue miniDecimal value={total} kind={props.toSymbol} change={1} />
                ) : (
                  <>
                    <DisplayValue changeFlash miniDecimal value={total} kind={props.toSymbol} />
                    <SmallPercentageValue>
                      <VerticalCenter>
                        <DisplayPercent miniDecimal value={totalChange} change={gains} />
                        {gains >= 0 ? <ArrowUp /> : <ArrowDown />}
                      </VerticalCenter>
                    </SmallPercentageValue>
                  </>
                )}
              </BigDollarValues>
            </DataHolder>
          </LabelAndInfo>
        ) : (
          <NavigationLink to="/settings">update investment to display portfolio total value</NavigationLink>
        ))}
      <TopSubInfo>
        {loadedInvestment && investment !== 0 && (
          <LabelAndInfo>
            <Label>investment</Label>
            <DataHolder style={{ height: 33 }}>
              <DisplayDollars miniDecimal value={props.totalCashInvested || 0} />
            </DataHolder>
          </LabelAndInfo>
        )}
        {loadedInvestment && total > 0 && investment !== 0 && (
          <LabelAndInfo>
            <Label>{gains >= 0 ? 'gains' : 'losses'}</Label>
            <DataHolder style={{ height: 33 }}>
              <DisplayDollars changeFlash miniDecimal value={dollarGains} />
            </DataHolder>
          </LabelAndInfo>
        )}
      </TopSubInfo>
    </Wrapper>
  );
};

export { TopInfo };
