import * as React from 'react';
import styled from 'styled-components';

import colors from 'app/styles/colors';
import { media } from 'app/utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 800px;

  & + & {
    margin-top: 100px;
  }

  ${media.nonDesktop} {
    flex-direction: column;
    margin: 0px 20px;
  }
`;

const LHS = styled.div`
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  video,
  img {
    max-width: 90%;
    max-height: 90%;
  }

  ${media.nonDesktop} {
    display: none;
  }
`;

const RHS = styled.div`
  min-width: 50%;
`;

const Title = styled.h3`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.3;
  color: ${colors.text.black};
  margin-bottom: 30px;
  padding: 0px 25px;
  min-height: 120px;

  ${media.nonDesktop} {
    text-align: center;
    padding: 0px;
    font-size: 30px;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Section = styled.div`
  font-size: 19px;
  line-height: 1.84;
  color: ${colors.text.black};
  padding: 15px 30px;
  margin-bottom: 25px;
  background-color: white;
  min-height: 60px;
  opacity: 0.4;

  strong {
    font-weight: bolder;
    margin-right: 5px;
  }

  &.selected {
    opacity: 1;
    box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.08);
    border-style: solid;
    border-bottom-width: 2px;
    border-image: linear-gradient(to left, ${colors.gradient.primary}, ${colors.gradient.secondary}) 1;

    strong {
      color: ${colors.text.brightBlue};
    }
  }
`;

const Request = styled.div`
  text-align: center;
`;

const BlueBottom = styled.div`
  display: inline-block;
  border-style: solid;
  border-bottom-width: 2px;
  border-image: linear-gradient(to left, ${colors.gradient.primary}, ${colors.gradient.secondary}) 1;
  margin-left: 5px;
  padding-bottom: 3px;
  cursor: pointer;
`;

export type DetailSection = {
  title: string;
  boldText: string;
  text: string;
  lastLine: string;
  link: string;
  image?: React.ReactNode;
};

type Props = {
  sections: Array<DetailSection>;
  onAction: () => void;
};

type State = {
  selected: number;
  rotatorId: number | undefined | null;
};

class FeatureDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { selected: 0, rotatorId: null };

    this.iterateOrSelect = this.iterateOrSelect.bind(this);
    this.cycleOn = this.cycleOn.bind(this);
    this.cycleOff = this.cycleOff.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.cycleOn();
  }

  cycleOn() {
    this.setState({
      rotatorId: setInterval(() => this.iterateOrSelect(), 8000),
    });
  }

  componentWillUnmount() {
    this.cycleOff();
  }

  cycleOff() {
    if (this.state.rotatorId) clearInterval(this.state.rotatorId);
    this.setState({ rotatorId: null });
  }

  iterateOrSelect(index?: number) {
    this.setState({
      selected: index != null ? index : (this.state.selected + 1) % this.props.sections.length,
    });
  }

  render() {
    const lastLine = this.props.sections[this.state.selected].lastLine;
    const link = this.props.sections[this.state.selected].link;
    const title = this.props.sections[this.state.selected].title;

    const sections = this.props.sections.map((section, i) => (
      <Section
        key={section.title}
        onMouseOver={() => {
          this.cycleOff();
          this.iterateOrSelect(i);
        }}
        onMouseOut={this.cycleOn}
        className={this.state.selected === i ? 'selected' : undefined}
      >
        <strong>{section.boldText}</strong>
        {section.text}
      </Section>
    ));

    const imageOrUrl = this.props.sections[this.state.selected].image;
    let image;
    if (imageOrUrl) {
      image =
        typeof imageOrUrl === 'string' ? (
          <video muted autoPlay loop playsInline>
            <source src={imageOrUrl} type="video/webm" />
          </video>
        ) : (
          imageOrUrl
        );
    }

    return (
      <Wrapper>
        <LHS>{image}</LHS>
        <RHS>
          <Title>{title}</Title>
          <Sections>{sections}</Sections>
          <Request>
            {lastLine}
            <BlueBottom onClick={this.props.onAction}>{link}</BlueBottom>
          </Request>
        </RHS>
      </Wrapper>
    );
  }
}

export { FeatureDetail };
