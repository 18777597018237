import * as React from 'react';
import styled from 'styled-components';

import { media } from 'app/utils/responsive';
import colors from 'app/styles/colors';

const FormSection = styled.div`
  padding: 20px;

  label {
    color: ${colors.ui.grey7};
    font-weight: bold;
    font-size: 14px;
    display: block;
    vertical-align: middle;
    padding-bottom: 5px;

    ${media.mobile} {
      display: block;
      padding-bottom: 10px;
    }
  }

  select {
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    padding: 10px 5px;
    border-radius: 3px;
    border: 1px solid grey;
    margin: 0px auto;
    box-sizing: border-box;

    &:disabled {
      background-color: #ddd;
      color: #bbb;
    }

    ${media.mobile} {
      display: block;
      width: 80%;
    }
  }

  & input.error {
    border-color: red;
  }

  & + & {
    border-top: 1px solid #ddd;
  }
`;

type Props = {
  id: number | string;
  label: string;
  error?: string | undefined;
  type?: string;
  disabled: boolean;
  value: string | number;
  onChange: (event?: React.FormEvent<HTMLInputElement>) => void;
};

const InputSection = (props: Props) => {
  const id = props.id.toString();

  return (
    <FormSection>
      <label htmlFor={id}>{props.label}:</label>
      <input
        className={props.error ? 'error' : undefined}
        name={id}
        type={props.type || 'text'}
        id={id}
        disabled={props.disabled}
        value={props.value || ''}
        onChange={props.onChange}
      />
    </FormSection>
  );
};

InputSection.defaultProps = {
  disabled: false,
};

export { InputSection, FormSection };
