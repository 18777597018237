import { Reducer } from 'redux';
import Cookies from 'js-cookie';
import { map } from 'lodash';

import { AuthData, JsonError } from 'app/types/auth';
import { AuthActions, LoadingKey } from 'app/store/modules/auth/actions';
import { reduceLoading } from 'app/store/modules/utils';

export type AuthState = {
  loading: {
    login: boolean;
    register: boolean;
    forgotPasswordRequest: boolean;
    forgotPassword: boolean;
  };

  loginFailed: boolean;
  errorMessage: string | null;
  errorPayload: Array<JsonError>;
  errorPayloadMessage: string | null;

  loggedIn: boolean | null;

  userId: number | null;
  authToken: string | undefined | null;
  loginCount: number;
};

const domain = window.location.hostname;

const defaultLoadingState = {
  login: false,
  register: false,
  forgotPasswordRequest: false,
  forgotPassword: false,
};

export const initialState: AuthState = {
  loading: defaultLoadingState,

  loginFailed: false,
  errorMessage: null,
  errorPayload: [],
  errorPayloadMessage: null,

  loggedIn: null,

  userId: null,
  authToken: null,
  loginCount: 0,
};

const segmentIdentifyUser = (authData: { [key: string]: string | number | undefined }, asyncKey: string) => {
  if (authData.userEmail && authData.userId != null && window.analytics) {
    analytics.identify(authData.userId.toString(), { email: authData.userEmail });
  } else if (authData.userId != null) {
    analytics.identify(authData.userId.toString());
  }

  analytics.track(`auth.${asyncKey}`);
};

const finishAuth = (asyncKey: LoadingKey, state: AuthState, action: { payload: AuthData }): AuthState => {
  const authData = action.payload;
  const newState = {
    ...state,
    ...authData,
    loggedIn: true,
    loginFailed: false,
    errorMessage: null,
    errorPayload: [],
    errorPayloadMessage: null,
    loading: { ...state.loading, [asyncKey]: false },
  };

  Cookies.set('userId', authData.userId.toString(), { expires: 30, domain });
  Cookies.set('authToken', authData.authToken, { expires: 30, domain });

  segmentIdentifyUser(authData, asyncKey);

  return newState;
};

const convertPayloadErrorToMessage = (json?: JsonError) => {
  if (json) {
    const errorMessages = map(json, (allErrors, longField) => {
      // fields always start with `obj.`
      const field = longField.substr(4);
      const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);

      return `${capitalizedField} ${allErrors.join(', ')}`;
    });
    return errorMessages.join('. ');
  } else return null;
};

export const reducer: Reducer<AuthState, AuthActions> = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case 'auth/CHECK_LOGIN_STATE':
      const authData = {
        userId: parseInt(Cookies.get('userId') || '-1', 10),
        authToken: Cookies.get('authToken'),
      };
      const loggedIn = !!authData.authToken && !!authData.userId;
      if (loggedIn) analytics.identify(authData.userId.toString());

      return { ...state, ...authData, loggedIn };
    case 'auth/LOGOUT':
      Cookies.remove('userId');
      Cookies.remove('authToken');
      Cookies.remove('userId', { domain });
      Cookies.remove('authToken', { domain });

      return { ...state, userId: null, authToken: null, loggedIn: false };
    case 'auth/LOGIN':
      return finishAuth('login', state, action);
    case 'auth/REGISTER':
      return finishAuth('register', state, action);
    case 'auth/SUBMIT_FORGOT_PASSWORD':
      return finishAuth('forgotPassword', state, action);
    case 'auth/FAILED':
      const jsonErrorMessage = convertPayloadErrorToMessage(action.payload.json.data[0]);

      return {
        ...state,
        loading: defaultLoadingState,
        loginFailed: true,
        errorMessage: action.payload.json.message,
        errorPayload: action.payload.json.data || [],
        errorPayloadMessage: jsonErrorMessage,
      };
    case 'auth/LOADING':
      return reduceLoading<LoadingKey, AuthState>(state, action.payload);
    default:
      return state;
  }
};
