import styled from 'styled-components';

import { media } from 'app/utils/responsive';
import { CoinIcon } from 'app/components/coin_icon';
import colors from 'app/styles/colors';
import dimensions from 'app/styles/dimensions';

const Table = styled.table<{ hideInfo?: boolean }>`
  width: 100%;
  color: ${colors.text.grey};
  margin-top: ${props => (props.hideInfo ? dimensions.sideBar.topBarWithoutInfo : dimensions.sideBar.topBarWithInfo)}px;
  border-collapse: separate;

  tr {
    height: 46px;
    border-top: 1px solid ${colors.table.border};
    background-color: transparent;
    position: relative;

    td {
      ${CoinIcon} {
        filter: grayscale(100%);
      }

      text-align: right;
    }

    &.highlighted {
      background-color: white;
      color: ${colors.text.black};
      box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.08);

      td ${CoinIcon} {
        filter: none;
      }
    }

    &.underline {
      display: none;
    }
  }

  ${media.nonDesktop} {
    margin-top: auto;

    tr.mobile-graph {
      border-top-width: 0;
      background-color: white;

      td {
        padding: 0;
      }
    }
  }

  tr.highlighted + tr.underline {
    display: table-row;
    height: 1px;
    border-width: 0px;
    background: linear-gradient(to left, ${colors.gradient.primary}, ${colors.gradient.secondary});
  }

  td,
  th {
    vertical-align: middle;
    img {
      vertical-align: text-bottom;
    }
  }

  th {
    font-size: 12px;

    position: sticky;
    top: ${props => (props.hideInfo ? dimensions.sideBar.topBarWithoutInfo : dimensions.sideBar.topBarWithInfo)}px;
    z-index: 20;
    border-top: 1px solid ${colors.table.border};
    border-bottom: 1px solid ${colors.table.border};
    background-color: #f2f2f2;
  }

  td:first-child,
  th:first-child {
    font-size: 12px;
    max-width: 45px;
    text-align: left;
    padding-left: 18px;

    ${media.nonDesktop} {
      padding-left: 5px;
    }
  }

  td:last-child,
  th:last-child {
    padding-right: 15px;
  }

  thead {
    text-align: right;
    font-size: 10px;
    letter-spacing: 0.83px;
    text-transform: uppercase;
    border-top: 1px solid #f2f2f2;

    tr {
      height: 35px;
    }

    ${media.nonDesktop} {
      font-size: 60%;
      text-align: right;

      th {
        height: 30px;
      }

      th:nth-child(2) {
        text-align: left;
      }
    }
  }

  tbody {
    font-size: 14px;
  }
`;

export { Table };
