import * as React from 'react';
import { find, last, first } from 'lodash';

import { CoinHistory, HistoricalPoint } from 'app/types/interface';
import { PriceSymbol } from 'app/types/interface';
import { CoinBalances } from 'app/types/api';
import { GradientLineChart } from 'app/components/charts/gradient_charts/gradient_line_chart';
import { TimeRange } from 'app/types/interface';

type Props = {
  coinBalances: CoinBalances;
  timerange: TimeRange;
  data: Array<CoinHistory>;
  highlightCoin?: string;
  toSymbol: PriceSymbol;
  crosshairIncludesValue?: boolean;
  onHover?: (coin?: string, data?: HistoricalPoint, index?: number) => void;
};

class IndividualCoinChart extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onHover = this.onHover.bind(this);
  }

  shouldComponentUpdate(nextProps: Props) {
    const differentCoin = nextProps.highlightCoin !== this.props.highlightCoin;
    const existing = find(this.props.data, ['coin', this.props.highlightCoin]);
    const newData = find(nextProps.data, ['coin', this.props.highlightCoin]);

    if (existing && newData) {
      const firstPoint = first(existing.data);
      const lastPoint = last(existing.data);
      const newFirstPoint = first(newData.data);
      const newLastPoint = last(newData.data);

      return (
        differentCoin ||
        firstPoint?.time !== newFirstPoint?.time ||
        lastPoint?.time !== newLastPoint?.time ||
        firstPoint?.price.value !== newFirstPoint?.price.value ||
        lastPoint?.price.value !== newLastPoint?.price.value
      );
    } else return differentCoin || newData !== null;
  }

  generateSeries() {
    if (this.props.data.length === 0 || !this.props.highlightCoin) return { priceData: [], volumeData: [] };
    else {
      const { data } = find(this.props.data, ['coin', this.props.highlightCoin]) || {
        data: [] as Array<HistoricalPoint>,
      };
      const priceData = data.map(({ time, price }) => {
        return { x: time * 1000, y: price.value };
      });
      const volumeData = data.map(({ time, volume }) => {
        return { x: time * 1000, y: volume.total };
      });

      return { priceData, volumeData };
    }
  }

  onHover(index?: number) {
    const data = find(this.props.data, ['coin', this.props.highlightCoin]);
    if (this.props.onHover) {
      if (data && index) this.props.onHover(this.props.highlightCoin, data.data[index], index);
      else this.props.onHover(this.props.highlightCoin);
    }
  }

  render() {
    const { priceData, volumeData } = this.generateSeries();
    return (
      <GradientLineChart
        timerange={this.props.timerange}
        name={`${this.props.highlightCoin || ''}-individual-chart`}
        series={priceData}
        barChartSeries={volumeData}
        onHover={this.onHover}
        crosshairIncludesValue={this.props.crosshairIncludesValue}
        toSymbol={this.props.toSymbol}
      />
    );
  }
}

export { IndividualCoinChart };
