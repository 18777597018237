import * as React from 'react';
import styled from 'styled-components';

import CoinName from 'app/components/coinName';
import { CoinIcon, DummyIcon } from 'app/components/coin_icon';
import { DisplayValue } from 'app/components/display_value';
import { CoinConversions } from 'app/types/interface';
import { PriceSymbol } from 'app/types/interface';
import ArrowUp from 'app/assets/images/mini.arrow.up.svg';
import ArrowDown from 'app/assets/images/mini.arrow.down.svg';
import colors from 'app/styles/colors';
import { MicroStaticLoadingSpinner as Spinner } from 'app/components/loading_spinner';
import { NonDesktop } from 'app/utils/responsive';

const NBSP = '\xa0';

const Wrapper = styled.tr<{ disabled: boolean }>`
  ${props =>
    props.disabled
      ? `
    opacity: 0.4;
    cursor: default;
  `
      : 'cursor: pointer;'};

  ${CoinName} {
    margin-left: 8px;
  }
`;

const VerticalCenter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 3px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  count: number;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent | React.TouchEvent) => void;
  disabled: boolean;
  highlighted: boolean;
  onClick?: (event: React.MouseEvent) => void;
  icon: string | undefined;
  amount: number;
  data: CoinConversions;
  currentPrice: number;
  coin: string;
  toSymbol: PriceSymbol;
  isLoading: boolean;
  isExpanded: boolean;
};

const circleStyle = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 10,
  height: 10,
  width: 10,
  padding: 2,
};
const greenCircleStyle = { ...circleStyle, borderColor: colors.green };
const redCircleStyle = { ...circleStyle, borderColor: colors.red };

class CoinRow extends React.Component<Props> {
  dragging: boolean = false;

  render() {
    const props = this.props;

    const changePlusMinus = props.data ? props.data.USD.change.percent.hour24 / 100.0 : 0;
    let coinIcon = <DummyIcon />;
    if (props.isLoading) {
      coinIcon = <Spinner />;
    } else if (props.icon) {
      coinIcon = <CoinIcon src={props.icon} />;
    }

    let disclosure = NBSP;
    if (props.highlighted && props.isExpanded) disclosure = '▼';
    else if (props.highlighted && !props.isExpanded) disclosure = '▶';

    return (
      <Wrapper
        disabled={props.disabled}
        onClick={props.onClick}
        onTouchMove={() => (this.dragging = true)}
        onTouchEnd={e => {
          if (props.onMouseEnter && !this.dragging) props.onMouseEnter(e);
          this.dragging = false;
          if (e.cancelable) e.preventDefault();
          return true;
        }}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        className={props.highlighted ? 'highlighted' : ''}
      >
        <NonDesktop>
          <td>{disclosure}</td>
        </NonDesktop>
        <td>
          <Flex>
            {coinIcon}
            <CoinName>{props.coin.slice(0, 10)}</CoinName>
          </Flex>
        </td>
        <td>{Math.round(props.count * 100) / 100}</td>
        <td>
          <div>
            <DisplayValue value={props.amount} kind={props.toSymbol} />
          </div>
        </td>
        <td>
          <VerticalCenter>
            <DisplayValue changeFlash value={props.currentPrice} change={changePlusMinus} kind={props.toSymbol} />
            {changePlusMinus >= 0 ? <ArrowUp style={greenCircleStyle} /> : <ArrowDown style={redCircleStyle} />}
          </VerticalCenter>
        </td>
      </Wrapper>
    );
  }
}

export { CoinRow };
