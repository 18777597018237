import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { difference } from 'lodash';

import * as balance from 'app/store/modules/balance/actions';
import * as user from 'app/store/modules/user/actions';
import * as auth from 'app/store/modules/auth/actions';
import * as api from 'app/store/modules/api/actions';
import colors from 'app/styles/colors';
import { SideBar } from 'app/components/sidebar';
import { ChartPanelContainer } from 'app/components/chart_panel';
import { Desktop, media } from 'app/utils/responsive';
import { CoinHistory, CoinPrices, PriceSymbol } from 'app/types/interface';
import { CoinBalances, CoinInfo } from 'app/types/api';
import dimensions from 'app/styles/dimensions';
import { State as GlobalState } from 'app/store/types';
import { CRYPTO_COMPARE_REFRESH_INTERVAL, BACKGROUNDED_APP_PRICE_CHECK_RATE } from 'app/constants';

const toSymbolLocalStorageKey = 'toSymbol';

const Wrapper = styled.div`
  height: 100%;
`;

const LeftPanel = styled.div`
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  width: ${dimensions.sideBar.width}px;
  background-color: ${colors.ui.grey2};

  ${media.desktop} {
    bottom: 0px;
    overflow-y: scroll;
  }

  ${media.nonDesktop} {
    position: static;
    margin: 0px auto;
    width: 100%;
  }
`;

const RightPanel = styled.div`
  height: 100%;
  position: relative;
  margin-left: ${dimensions.sideBar.width}px;
  min-width: 500px;
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.08), inset 1px 0 0 0 #ececec;
`;

export type CoinColors = { [coinName: string]: string };

type Props = {
  totalCashInvested: number | undefined;
  loggedIn: boolean;
  loading: boolean;
  loadingCoins: { [key: string]: boolean };
  coinPrices: CoinPrices;
  coinBalances: CoinBalances;
  coinInfo: CoinInfo;
  allCoinsData: Array<CoinHistory>;
  trackedCoins: Array<string>;
  disabledCoins: Array<string>;
  visibleCoins: Array<string>;
  onDisplay: () => void;
  fetchPriceData: () => void;
  onLogout: () => void;
  reloadData: () => void;
  fetchHistoricalData: (coin: string, toSymbol: PriceSymbol) => void;
};

type State = {
  highlightCoin: string | undefined;
  currentPriceUpdateInterval: number | undefined | null;
  toSymbol: PriceSymbol;
};

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let toSymbol: PriceSymbol = 'USD';
    if (window.localStorage && window.localStorage.getItem) {
      const found = window.localStorage.getItem(toSymbolLocalStorageKey);
      switch (found) {
        case 'USD': {
          toSymbol = 'USD';
          break;
        }
        case 'BTC': {
          toSymbol = 'BTC';
          break;
        }
      }
    }

    this.state = {
      highlightCoin: undefined,
      currentPriceUpdateInterval: null,
      toSymbol,
    };

    this.toggleBTC = this.toggleBTC.bind(this);
    this.onHighlightChange = this.onHighlightChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onDisplay();
    this.setState({
      currentPriceUpdateInterval: setInterval(() => {
        const shouldFetchPrice = document.hidden ? Math.random() < BACKGROUNDED_APP_PRICE_CHECK_RATE : true;

        if (shouldFetchPrice) this.props.fetchPriceData();
      }, CRYPTO_COMPARE_REFRESH_INTERVAL),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const coinsAdded = difference(nextProps.trackedCoins, this.props.trackedCoins);
    const coinsRemoved = difference(this.props.trackedCoins, nextProps.trackedCoins);
    const coinsChanged = coinsAdded.length > 0 || coinsRemoved.length > 0;

    let highlightCoin = this.state.highlightCoin;
    if (highlightCoin == null && this.props.visibleCoins.length > 0) highlightCoin = this.props.visibleCoins[0];

    // guard against trying to display curve data for BTC when it's our baseline
    if (highlightCoin === this.state.toSymbol) {
      highlightCoin = this.props.visibleCoins.length > 1 ? this.props.visibleCoins[1] : undefined;
    }

    this.setState({ highlightCoin }, () => {
      if (coinsChanged) this.props.fetchPriceData();
    });
  }

  componentWillUnmount() {
    if (this.state.currentPriceUpdateInterval) clearInterval(this.state.currentPriceUpdateInterval);
    this.setState({ currentPriceUpdateInterval: null });
  }

  onHighlightChange(coin: string | undefined | null) {
    if (coin != null && coin !== this.state.toSymbol) {
      analytics.track('ux.highlight.coin', { coin });

      if (this.props.disabledCoins.indexOf(coin) === -1) this.setState({ highlightCoin: coin });
    }
  }

  toggleBTC() {
    const toSymbol = this.state.toSymbol === 'USD' ? 'BTC' : 'USD';

    if (window.localStorage && window.localStorage.setItem) {
      window.localStorage.setItem(toSymbolLocalStorageKey, toSymbol);
    }

    analytics.track('ux.toggle.click.showBTC', { toSymbol });

    const highlightCoin =
      this.state.highlightCoin === 'BTC'
        ? this.props.trackedCoins.find(coin => coin !== 'BTC')
        : this.state.highlightCoin;

    this.setState({ toSymbol, highlightCoin });
  }

  render() {
    const highlightCoin =
      this.state.highlightCoin && this.props.visibleCoins.indexOf(this.state.highlightCoin) === -1
        ? undefined
        : this.state.highlightCoin;
    const sharedProps = {
      highlightCoin,
      toSymbol: this.state.toSymbol,
      toggleBTC: this.toggleBTC,
      coinInfo: this.props.coinInfo,
      loading: this.props.loading,
    };
    const rightPanel = Object.keys(this.props.coinPrices).length ? (
      <RightPanel>
        <ChartPanelContainer
          {...sharedProps}
          visibleCoins={this.props.visibleCoins}
          onHighlightChange={this.onHighlightChange}
        />
      </RightPanel>
    ) : (
      <RightPanel />
    );

    return (
      <Wrapper>
        <LeftPanel>
          <SideBar
            {...sharedProps}
            fetchHistoricalData={this.props.fetchHistoricalData}
            historicalData={this.props.allCoinsData}
            reloadData={this.props.reloadData}
            loggedIn={this.props.loggedIn}
            onLogout={this.props.onLogout}
            totalCashInvested={this.props.totalCashInvested}
            disabledCoins={this.props.disabledCoins}
            coinBalances={this.props.coinBalances}
            coinPrices={this.props.coinPrices}
            onMouseEnter={this.onHighlightChange}
            onMouseLeave={() => this.onHighlightChange(null)}
            loadingCoins={this.props.loadingCoins}
          />
        </LeftPanel>
        <Desktop>{rightPanel}</Desktop>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    trackedCoins: state.balance.trackedCoins,
    disabledCoins: state.balance.disabledCoins,
    visibleCoins: state.balance.visibleCoins,
    coinBalances: state.balance.balances,
    coinPrices: state.balance.coinPrices,
    allCoinsData: state.balance.coinHistorical,
    loadingCoins: state.balance.loadingCoins,
    loading:
      state.api.loading.overall ||
      Object.keys(state.balance.loadingCoins).length > 0 ||
      state.api.loading.coinInfo ||
      state.balance.loading.coinPrices ||
      state.user.fetchingSettings ||
      state.user.updatingSettings,
    totalCashInvested: state.user.settings.totalCashInvested,
    coinInfo: state.api.coinInfo,
    loggedIn: !!state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchHistoricalData: (coin: string, toSymbol: PriceSymbol) => {
      dispatch(balance.fetchCoinHistorical(coin, toSymbol));
    },
    onLogout: () => {
      dispatch(auth.logout());
    },
    fetchPriceData: () => {
      dispatch(balance.fetchCoinPrices());
    },
    reloadData: async () => {
      try {
        await Promise.all([
          dispatch(api.setLoadingOverall({ isLoading: true })),
          dispatch(user.loadSettings()),
          dispatch(balance.fetchBalances()),
        ]);
        await dispatch(balance.fetchCoinPrices());
      } finally {
        await dispatch(api.setLoadingOverall({ isLoading: false }));
      }
    },
    onDisplay: async () => {
      try {
        await Promise.all([
          dispatch(api.setLoadingOverall({ isLoading: true })),
          dispatch(api.fetchCoinInfo()),
          dispatch(user.loadSettings()),
        ]);
        await dispatch(balance.fetchBalances());
        await dispatch(balance.fetchCoinPrices());
      } finally {
        await dispatch(api.setLoadingOverall({ isLoading: false }));
      }
    },
  };
};

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export { DashboardContainer, Dashboard, LeftPanel };
