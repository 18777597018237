import { head } from 'lodash';

import WebRequestService from 'app/services/web_request_service';
import { CoinData, Order, WalletMapping } from 'app/types/api';
import { CoinPrices } from 'app/types/crypto_compare';
import { CurrencyTicker } from 'app/types/nomics';
import { Currencies, DEFAULT_INTERVAL, convertNomicsToCryptoCompare } from 'app/services/nomics_service';
import { AuthData } from 'app/types/auth';

const makeHeaders = (userId: number | string, authToken: string): Record<string, string> => {
  return {
    'User-Id': userId.toString(),
    'Auth-Token': authToken,
  };
};

class ApiService extends WebRequestService {
  baseUrl: string;

  constructor() {
    super();
    this.baseUrl = 'api/v1/';
  }

  checkCredentials(site: string, key: string, secret: string, userId: number, authToken: string) {
    return this.postJson('credentials/check', { site, key, secret }, makeHeaders(userId, authToken));
  }

  forgotPassword(
    userId: number,
    token: string,
    goodUntil: number,
    password: string,
    passwordConfirmation: string
  ): Promise<AuthData> {
    return this.postJson('forgot_password', { userId, token, goodUntil, password, passwordConfirmation });
  }

  forgotPasswordRequest(email: string): Promise<void> {
    return this.postJson('request_forgot_password', { email });
  }

  async proxyNomics(
    userId: number,
    authToken: string,
    coins: Array<string>,
    currencies: Currencies = ['USD', 'BTC']
  ): Promise<CoinPrices> {
    const result: Array<Array<CurrencyTicker>> = await Promise.all(
      currencies.map(currency =>
        this.getJson(
          'proxy_nomics',
          {
            ids: coins.join(','),
            interval: DEFAULT_INTERVAL,
            convert: currency,
          },
          makeHeaders(userId, authToken)
        )
      )
    );

    return convertNomicsToCryptoCompare(currencies, result);
  }

  loadSettings(userId: number, authToken: string) {
    return this.getJson('profile', {}, makeHeaders(userId, authToken));
  }

  updateSettings(data: {}, userId: number, authToken: string) {
    return this.postJson('profile', data, makeHeaders(userId, authToken));
  }

  login(email: string, password: string): Promise<AuthData> {
    return this.postJson('login', { email, password });
  }

  register(email: string, password: string, passwordConfirmation: string): Promise<AuthData> {
    return this.postJson('register', { email, password, passwordConfirmation });
  }

  getCoinInfo(): Promise<Array<CoinData>> {
    return this.getJson('coin_info');
  }

  getOrders(userId: number, authToken: string): Promise<Array<Order>> {
    return this.getJson('orders', {}, makeHeaders(userId, authToken));
  }

  getWalletOverTime(): Promise<Array<WalletMapping>> {
    return this.getJson('wallet_over_time');
  }

  getBalances(userId: number | string, authToken: string) {
    return this.getJson('balances', {}, makeHeaders(userId, authToken));
  }

  extractJsonData(json: { data: Array<{}> }) {
    return json.data.length === 1 ? head(json.data) : json.data;
  }
}

export default ApiService;
