import styled from 'styled-components';

const large = '64px';
const small = '32px';
const micro = '16px';

const largeBorder = '24px';
const smallBorder = '8px';
const microBorder = '2px';

const LoadingSpinner = styled.div<{
  large?: boolean;
  position?: 'static' | 'relative' | 'absolute';
  isLoading?: boolean;
}>`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-width: ${props => (props.large ? largeBorder : smallBorder)};
  border-radius: 50%;
  width: ${props => (props.large ? large : small)};
  height: ${props => (props.large ? large : small)};
  animation: spin 2s linear infinite;

  position: ${props => (props.position ? props.position : 'absolute')};
  top: 0px;
  right: 0px;
  z-index: 300;

  display: ${props => (props.isLoading ? 'block' : 'none')};
`;

const StaticLoadingSpinner = styled(LoadingSpinner)`
  position: static;
  display: block;
`;

const MicroStaticLoadingSpinner = styled(StaticLoadingSpinner)`
  width: ${micro};
  height: ${micro};
  border-width: ${microBorder};
  margin: 4px 0px;
`;

export { LoadingSpinner, StaticLoadingSpinner, MicroStaticLoadingSpinner };
