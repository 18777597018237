import ApiService from 'app/services/api_service';
import CryptoCompareService from 'app/services/crypto_compare_service';
import EthplorerService from 'app/services/ethplorer_service';
import { CoinGeckoService } from 'app/services/coingecko_service';

export type Services = {
  apiService: ApiService;
  cryptoCompareService: CryptoCompareService;
  ethplorerService: EthplorerService;
  coinGeckoService: CoinGeckoService;
  localStorage?: Storage;
};

const combinedServices: Services = {
  apiService: new ApiService(),
  cryptoCompareService: new CryptoCompareService(),
  ethplorerService: new EthplorerService(),
  coinGeckoService: new CoinGeckoService(),
};

export default combinedServices;
