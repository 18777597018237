import * as React from 'react';
import { last, first } from 'lodash';

import { TotalValueChart } from 'app/components/charts/total_value_chart';
import { HoldingsDonutChart } from 'app/components/charts/holdings_donut_chart';
import { PriceSymbol, CoinPrices } from 'app/types/interface';
import { CoinBalances } from 'app/types/api';
import { TimeRange } from 'app/types/interface';
import { DashboardSection } from 'app/components/dashboard_section';
import { DisplayDollars, DisplayValue, DisplayPercent } from 'app/components/display_value';
import { Section } from 'app/components/dashboard_section/data_sections';
import { Point } from 'app/components/charts/gradient_charts/gradient_line_chart';
import { SideChartWrapper } from 'app/components/chart_panel/side_chart_wrapper';
import { ChartSection } from 'app/components/chart_panel/chart_section';

type Props = {
  max: number;
  min: number;
  timerange: TimeRange;
  totalValueChartData: Array<Point>;
  toSymbol: PriceSymbol;
  portfolioHoverValue: number | undefined | null;
  onHover?: (x?: number | null) => void;
  onHighlightChange: (coin?: string) => void;
  coinPrices: CoinPrices;
  coinBalances: CoinBalances;
  highlightCoin: string | undefined;
};

const panelData = (
  min: number,
  max: number,
  portfolioHoverValue: number | undefined | null,
  lastPoint: Point,
  firstPoint: Point
): Array<Section> => {
  const totalMax = max;
  const totalMin = min;
  const spread = totalMax - totalMin;
  const profitLoss = (portfolioHoverValue || lastPoint.y) - firstPoint.y;
  const timeRangeTitle = profitLoss >= 0 ? 'Gains' : 'Losses';

  return [
    {
      title: timeRangeTitle,
      content: <DisplayDollars miniDecimal value={profitLoss} />,
    },
    { title: 'High', content: <DisplayDollars miniDecimal changeFlash value={totalMax} /> },
    { title: 'Low', content: <DisplayDollars miniDecimal changeFlash value={totalMin} /> },
    { title: 'High/Low Spread', content: <DisplayDollars miniDecimal changeFlash value={spread} /> },
  ];
};

const PortfolioSection = (props: Props) => {
  const firstPoint = first(props.totalValueChartData) || { x: 0, y: 0 };
  const lastPoint = last(props.totalValueChartData) || { x: 0, y: 0 };
  let totalValue: React.ReactNode = null;
  let totalPercent: React.ReactNode = null;
  if (lastPoint) {
    totalValue = <DisplayValue miniDecimal kind={props.toSymbol} value={props.portfolioHoverValue || lastPoint.y} />;
    const percent = firstPoint?.y ? ((props.portfolioHoverValue || lastPoint.y) - firstPoint.y) / firstPoint.y : 0;
    totalPercent = <DisplayPercent miniDecimal value={percent} change={percent} />;
  }

  return (
    <DashboardSection
      title="Portfolio"
      mainValue={totalValue}
      mainPercentValue={totalPercent}
      data={panelData(props.min, props.max, props.portfolioHoverValue, lastPoint, firstPoint)}
    >
      <ChartSection>
        <TotalValueChart
          toSymbol={props.toSymbol}
          timerange={props.timerange}
          data={props.totalValueChartData}
          onHover={props.onHover}
        />
        <SideChartWrapper>
          <HoldingsDonutChart
            showBTC={props.toSymbol === 'BTC'}
            onHighlightChange={props.onHighlightChange}
            coinPrices={props.coinPrices}
            coinBalances={props.coinBalances}
            highlightCoin={props.highlightCoin}
          />
        </SideChartWrapper>
      </ChartSection>
    </DashboardSection>
  );
};

export { PortfolioSection };
